import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MicroLoader from "../../parts/MicroLoader";
import PageLoader from "../../parts/PageLoader";
import UserBridge from "../../../bridges/UserBridge";
import ServiceBridge from "../../../bridges/ServiceBridge";
import TransactionBridge from "../../../bridges/TransactionBridge";
import { Link } from "react-router-dom";

const PosScheduling = () => {
  const [loaded, setLoaded] = useState(false);
  const [usersLoader, setUsersLoader] = useState(false);
  const [ticketsLoader, setTicketsLoader] = useState(false);
  const [createClientLoader, setCreateClientLoader] = useState(false);
  const [redTicketLoader, setRedTicketLoader] = useState(false);
  const [hyperTicketLoader, setHyperTicketLoader] = useState(false);
  const [purchasePackageLoader, setPurchasePackageLoader] = useState(false);
  const [purchaseBronzeLoader, setPurchaseBronzeLoader] = useState(false);
  const [purchaseBronzeHyperLoader, setPurchaseBronzeHyperLoader] =
    useState(false);
  const [purchaseBronzeRedLoader, setPurchaseBronzeRedLoader] = useState(false);
  const [purchaseUnlimitedLoader, setPurchaseUnlimitedLoader] = useState(false);
  const [purchaseUnlimitedHyperLoader, setPurchaseUnlimitedHyperLoader] =
    useState(false);
  const [purchaseUnlimitedRedLoader, setPurchaseUnlimitedRedLoader] =
    useState(false);
  const [purchaseGoldLoader, setPurchaseGoldLoader] = useState(false);
  const [purchasePlatinumLoader, setPurchasePlatinumLoader] = useState(false);
  const [alviraTicketLoader, setAlviraTicketLoader] = useState(false);
  const [alvira2TicketLoader, setAlvira2TicketLoader] = useState(false);
  const [hydrateMeLoader, setHydrateMeLoader] = useState(false);
  const [performanceTicketLoader, setPerformanceTicketLoader] = useState(false);
  const [quinchTicketLoader, setQuinchTicketLoader] = useState(false);
  const [myersTicketLoader, setMyersTicketLoader] = useState(false);
  const [hangoverTicketLoader, setHangoverTicketLoader] = useState(false);
  const [purchaseTestLoader, setPurchaseTestLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectUserLoader, setSelectUserLoader] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceTickets, setServiceTickets] = useState([]);
  const [selectedUserSubscriptions, setSelectedUserSubscriptions] = useState(
    []
  );
  const [purchaseElviraBronzeLoader, setPurchaseElviraBronzeLoader] =
    useState(false);
  const [purchaseElviraGoldLoader, setPurchaseElviraGoldLoader] =
    useState(false);
  const [purchaseElviraPlatinumLoader, setPurchaseElviraPlatinumLoader] =
    useState(false);

  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getUsers();
    await getServices();
    // await initializeTerminal();
    setLoaded(true);
  };

  const getUsers = async () => {
    setUsersLoader(true);
    const response = await UserBridge.readAllUsers();
    const result = await response.json();
    if (result.error === true) {
      nav("/login");
    } else {
      const { data } = result;
      setUserList(data);
    }
    setUsersLoader(false);
  };

  const getServiceTickets = async (_id) => {
    setTicketsLoader(true);
    const response = await UserBridge.readUserServices(_id);
    const result = await response.json();
    console.log(result.data);
    setServiceTickets(result.data);
    setTicketsLoader(false);
  };

  const getSubscriptions = async (_id) => {
    const response = await TransactionBridge.getCurrentSubscriptions(_id);
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    } else {
      setSelectedUserSubscriptions(result.data);
    }
  };

  const getServices = async () => {
    const response = await ServiceBridge.getServices();
    const result = await response.json();
    setServices(result.data);
  };

  const createClient = async () => {
    setCreateClientLoader(true);
    const response = await UserBridge.createUser(
      0,
      null,
      document.getElementById(`fname`).value,
      document.getElementById(`lname`).value,
      document.getElementById(`email`).value,
      document.getElementById(`phone`).value
    );
    const result = await response.json();
    if (result.error) {
      alert(`There was an error, please try again.`);
      setCreateClientLoader(false);
      setUsersLoader(false);
    } else {
      getUsers();
      alert(`Success!`);
      setCreateClientLoader(false);
    }
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  const populateClientSelectBox = () => {
    let payload = [];
    userList.forEach((client) => {
      payload.push(genTag(client.fname, client.lname, client._id));
    });
    return payload;
  };

  const renderServiceTickets = () => {
    let payload = [];
    serviceTickets.forEach((ticket) => {
      payload.push(
        <tr>
          <td>{ticket.serviceName}</td>
          <td>{ticket.quantity}</td>
        </tr>
      );
    });
    return payload;
  };

  const renderMemberships = () => {
    let payload = [];
    selectedUserSubscriptions.forEach((subscription) => {
      payload.push(
        <tr>
          <td>{subscription.name}</td>
          <td>{subscription.status}</td>
        </tr>
      );
    });
    return payload;
  };

  const sendToBook = async (_id) => {
    let match;
    for (let i = 0; i < services.length; i++) {
      if (services[i]._id == _id) {
        match = i;
      }
    }
    const response = await ServiceBridge.getBookingLink(_id);
    const result = await response.json();
    const url = await result.data;
    const response2 = await UserBridge.removeServiceTickets(null, _id, 1);
    const result2 = await response2.json();
    window.open(url, "_blank");
  };

  const purchaseBronze = async (_id) => {
    setPurchaseBronzeLoader(true);
    const response = await TransactionBridge.purchaseBronze(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseBronzeLoader(true);
  };

  const purchaseBronzeHyper = async (_id) => {
    setPurchaseBronzeHyperLoader(true);
    const response = await TransactionBridge.purchaseBronzeHyper(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseBronzeHyperLoader(true);
  };

  const purchaseBronzeRed = async (_id) => {
    setPurchaseBronzeRedLoader(true);
    const response = await TransactionBridge.purchaseBronzeRed(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseBronzeRedLoader(true);
  };

  const purchaseUnlimited = async (_id) => {
    setPurchaseUnlimitedLoader(true);
    const response = await TransactionBridge.purchaseUnlimited(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseUnlimitedLoader(true);
  };

  const purchaseUnlimitedHyper = async (_id) => {
    setPurchaseUnlimitedLoader(true);
    const response = await TransactionBridge.purchaseUnlimitedHyper(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseUnlimitedHyperLoader(true);
  };

  const purchaseUnlimitedRed = async (_id) => {
    setPurchaseUnlimitedRedLoader(true);
    const response = await TransactionBridge.purchaseUnlimitedRed(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseUnlimitedRedLoader(true);
  };

  const purchaseGold = async (_id) => {
    setPurchaseGoldLoader(true);
    const response = await TransactionBridge.purchaseGold(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseGoldLoader(true);
  };

  const purchasePlatinum = async (_id) => {
    setPurchasePlatinumLoader(true);
    const response = await TransactionBridge.purchasePlatinum(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchasePlatinumLoader(true);
  };

  const purchaseElviraBronze = async (_id) => {
    setPurchaseElviraBronzeLoader(true);
    const response = await TransactionBridge.purchaseElviraBronze(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseElviraBronzeLoader(true);
  };

  const purchaseElviraGold = async (_id) => {
    setPurchaseElviraGoldLoader(true);
    const response = await TransactionBridge.purchaseElviraGold(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseElviraGoldLoader(true);
  };

  const purchaseElviraPlatinum = async (_id) => {
    setPurchaseElviraPlatinumLoader(true);
    const response = await TransactionBridge.purchaseElviraPlatinum(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseElviraPlatinumLoader(true);
  };

  const purchasePackage = async (_id) => {
    setPurchasePackageLoader(true);
    const response = await TransactionBridge.purchasePackage(
      document.getElementById("packageQuantity").value,
      _id
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchasePackageLoader(true);
  };

  const purchaseRedTickets = async (_id) => {
    setRedTicketLoader(true);
    const response = await TransactionBridge.purchaseRedTickets(
      document.getElementById("quantityRed").value,
      _id
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setRedTicketLoader(false);
  };

  const purchaseHyperTickets = async (_id) => {
    setHyperTicketLoader(true);
    const response = await TransactionBridge.purchaseHyperTickets(
      document.getElementById("quantityHyper").value,
      _id
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setHyperTicketLoader(false);
  };

  const purchaseAlviraTickets = async () => {
    setAlviraTicketLoader(true);
    const response = await TransactionBridge.purchaseAlvira(
      document.getElementById("quantityAlvira").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setAlviraTicketLoader(false);
  };

  const purchaseAlvira2Tickets = async () => {
    setAlvira2TicketLoader(true);
    const response = await TransactionBridge.purchaseAlvira2(
      document.getElementById("quantityAlvira2").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setAlvira2TicketLoader(false);
  };

  const purchaseHydrateMe = async () => {
    setHydrateMeLoader(true);
    const response = await TransactionBridge.purchaseHydrateMe(
      document.getElementById("quantityHydrateMe").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setHydrateMeLoader(false);
  };

  const purchasePerformance = async () => {
    setPerformanceTicketLoader(true);
    const response = await TransactionBridge.purchasePerformance(
      document.getElementById("quantityPerformance").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPerformanceTicketLoader(false);
  };

  const purchaseQuinch = async () => {
    setQuinchTicketLoader(true);
    const response = await TransactionBridge.purchaseQuinch(
      document.getElementById("quantityQuinch").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setQuinchTicketLoader(false);
  };

  const purchaseMyers = async () => {
    setMyersTicketLoader(true);
    const response = await TransactionBridge.purchaseMyers(
      document.getElementById("quantityMyers").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setMyersTicketLoader(false);
  };

  const purchaseHangoverTickets = async () => {
    setHangoverTicketLoader(true);
    const response = await TransactionBridge.purchaseHangover(
      document.getElementById("quantityHangover").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setHangoverTicketLoader(false);
  };

  const purchaseTest = async () => {
    setPurchaseTestLoader(true);
    const response = await TransactionBridge.purchaseTest(
      document.getElementById("quantityTest").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseTestLoader(false);
  };

  const sendToBooking = () => {
    const consent = document.getElementById("consent");
    if (consent.checked) {
      window.location.href = "https://booking.ffnaw.com/providers/pos";
    } else {
      alert("Please agree to the terms and conditions to proceed.");
    }
  };

  if (loaded) {
    return (
      <div className="posScheduling">
        <h1>Service Scheduling</h1>
        <strong
          className="breadcrumb"
          onClick={() => {
            nav("/v1/crm/pos");
          }}
        >
          &larr; Return to POS
        </strong>

        <h3>Step 1: Create a new Client if they dont have an account</h3>
        <p>The default username is their email, and password is getfit</p>
        <label htmlFor="fname">First Name</label>
        <input
          name="fname"
          id="fname"
          type="fname"
          placeholder="enter client first name"
        />
        <label htmlFor="lname">Last Name</label>
        <input
          name="lname"
          id="lname"
          type="lname"
          placeholder="enter client last name"
        />
        <label htmlFor="email">Email</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="enter client email"
        />
        <label htmlFor="phone">Phone</label>
        <input
          name="phone"
          id="phone"
          type="phone"
          placeholder="enter client phone"
        />
        <button onClick={createClient}>
          {createClientLoader ? <MicroLoader /> : `Create Client`}
        </button>

        <h3>Step 2: Select the Client</h3>
        {usersLoader ? (
          <MicroLoader />
        ) : (
          <select
            id="selectedClient"
            onChange={(e) => {
              setSelectedUser(e.target.value);
              getServiceTickets(e.target.value);
              getSubscriptions(e.target.value);
            }}
          >
            <option value="">SELECT A CLIENT TO EDIT</option>
            {populateClientSelectBox()}
          </select>
        )}
        {selectedUser ? (
          <>
            {selectUserLoader ? (
              <MicroLoader />
            ) : (
              <>
                {selectedUserSubscriptions ? (
                  <table>
                    <tr>
                      <th>Active Memberships</th>
                      <th>Status</th>
                    </tr>
                    {renderMemberships()}
                  </table>
                ) : null}
                <table>
                  <tr>
                    <th>Service</th>
                    <th>Quantity</th>
                  </tr>
                  {renderServiceTickets()}
                </table>
              </>
            )}
          </>
        ) : null}

        <h3>Step 3: Book Service for Client</h3>
        <div className="bookingConsent">
          <label htmlFor="consent">
            You must agree to our{" "}
            <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ade3be07-1f93-41a7-b925-e0fa37f7ff97">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ecb0617b-e57a-452f-812d-7a73abb763dd">
              Terms of Service
            </Link>{" "}
            to book an appointment. You also must be older then 14 years of age.
            By checking the box below, you agree to these policies.
          </label>
          <input type="checkbox" id="consent" name="consent" required />
          <button onClick={sendToBooking}>Booking</button>
        </div>

        <h3>Step 4: Purchase Tickets for Client if needed</h3>
        <strong> *** CARD TRANSACTION ONLY *** </strong>
        <strong>Available Service Subscriptions</strong>

        <div className="subscriptions">
          <div className="subscription">
            <h3>Bronze</h3>
            <p>3 Month Commitment</p>
            <p>One Hyperbaric Session Monthly</p>
            <p>One Red Light Vibro Therapy Table Session Monthly</p>
            <strong>
              <span className="price">$99.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseBronze(selectedUser);
              }}
            >
              {purchaseBronzeLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Bronze Hyperbaric</h3>
            <p>3 Month Commitment</p>
            <p>Two Hyperbaric Chamber Sessions Monthly</p>
            <strong>
              <span className="price">$99.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseBronzeHyper(selectedUser);
              }}
            >
              {purchaseBronzeHyperLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Bronze Red Light</h3>
            <p>3 Month Commitment</p>
            <p>Two Red Light Chamber Sessions Monthly</p>
            <strong>
              <span className="price">$99.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseBronzeRed(selectedUser);
              }}
            >
              {purchaseBronzeRedLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Platinum</h3>
            <p>3 Month Commitment</p>
            <p>Four Hyperbaric Sessions Monthly</p>
            <p>Four Red Light Vibro Therapy Table Sessions Monthly</p>
            <p>10% off Supplements</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$379.99</span>
            </strong>
            <button
              onClick={() => {
                purchasePlatinum(selectedUser);
              }}
            >
              {purchasePlatinumLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Gold</h3>
            <p>3 Month Commitment</p>
            <p>Two Hyperbaric Sessions Monthly</p>
            <p>Two Red Light Vibro Therapy Table Sessions Monthly</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$199.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseGold(selectedUser);
              }}
            >
              {purchaseGoldLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Unlimited</h3>
            <p>3 Month Commitment</p>
            <p>Unlimited Hyperbaric Chamber and Red Light Therapy</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$999.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseUnlimited(selectedUser);
              }}
            >
              {purchaseUnlimitedLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Unlimited Red Light Therapy</h3>
            <p>3 Month Commitment</p>
            <p>Unlimited Red Light Therapy</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$499.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseUnlimitedRed(selectedUser);
              }}
            >
              {purchaseUnlimitedRedLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Unlimited Hyperbaric Chamber</h3>
            <p>3 Month Commitment</p>
            <p>Unlimited Hyperbaric Chamber</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$499.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseUnlimitedHyper(selectedUser);
              }}
            >
              {purchaseUnlimitedHyperLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Elvira Bronze Membership</h3>
            <p>One 60 Minute Massages Monthly</p>
            <p>3 Month Minimum Commitment</p>
            <strong>
              <span className="price">$125.00</span>
            </strong>
            <button
              onClick={() => {
                purchaseElviraBronze(selectedUser);
              }}
            >
              {purchaseElviraBronzeLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Elvira Gold Membership</h3>
            <p>Two 60 Minute Massages Monthly</p>
            <p>3 Month Minimum Commitment</p>
            <strong>
              <span className="price">$250.00</span>
            </strong>
            <button
              onClick={() => {
                purchaseElviraGold(selectedUser);
              }}
            >
              {purchaseElviraGoldLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Elvira Platinum Membership</h3>
            <p>Four 60 Minute Massages Monthly</p>
            <p>3 Month Minimum Commitment</p>
            <strong>
              <span className="price">$450.00</span>
            </strong>
            <button
              onClick={() => {
                purchaseElviraPlatinum(selectedUser);
              }}
            >
              {purchaseElviraPlatinumLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>
        </div>

        <strong>Available Service Packages</strong>
        <div className="availablePackages">
          <div className="package">
            <h3>Hyperbaric Chamber and Red Light Vibro Therapy Table</h3>
            <p>
              Save money by purchasing our package deals. The more you buy, the
              more you save! This package includes both the red light vibro
              therapy table and the hyperbaric chamber.
            </p>
            <strong>
              1 ticket each
              <span className="price">$104.99</span>
            </strong>
            <strong>
              3 tickets each
              <span className="price">$299.99</span>
            </strong>
            <strong>
              5 tickets each
              <span className="price">$479.99</span>
            </strong>
            <strong>
              12 tickets each
              <span className="price">$999.99</span>
            </strong>
            <label htmlFor="packageQuantity">Quantity</label>
            <select
              id="packageQuantity"
              name="packageQuantity"
              placeHolder="How many tickets?"
            >
              <option value={1}>1</option>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={12}>12</option>
            </select>
            <button
              onClick={() => {
                purchasePackage(selectedUser);
              }}
            >
              {purchasePackageLoader ? <MicroLoader /> : "Purchase Package"}
            </button>
          </div>
        </div>

        <strong>Services A La Carte</strong>
        <div className="availableServices">
          <div className="service">
            <strong>Red Light Vibro Therapy Table</strong>
            <p>Session Length: 30 minutes</p>
            <p>
              Red light therapy is a service utilized in active recovery and
              sports medicine that involves the use of low-level red wavelengths
              of light to treat various health conditions. This therapy is
              designed to promote healing, reduce inflammation, and alleviate
              pain by penetrating the skin and stimulating cellular function. It
              is commonly used by athletes to accelerate muscle recovery and
              enhance overall performance.
            </p>

            <span className="price">$59.99</span>
            <label htmlFor="quantityRed">Quantity</label>
            <input
              type="number"
              id="quantityRed"
              name="quantityRed"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseRedTickets(selectedUser);
              }}
            >
              {redTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Hyperbaric Chamber</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              Hyperbaric chambers are used in fitness and active recovery to
              enhance the body's natural healing processes. By providing 100%
              oxygen at higher-than-atmospheric pressures, these chambers
              increase oxygen delivery to tissues, promoting faster muscle
              recovery, reducing inflammation, and accelerating the healing of
              injuries. Athletes often use hyperbaric therapy to improve
              performance and expedite recovery times.
            </p>
            <span className="price">$59.99</span>
            <label htmlFor="quantityHyper">Quantity</label>
            <input
              type="number"
              id="quantityHyper"
              name="quantityHyper"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseHyperTickets(selectedUser);
              }}
            >
              {hyperTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Hydrate Me! IV Injection</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              One liter of Lactated Ringers. Offers sodium, potassium, chloride,
              and lactate often lost in high intensity activity. Or, Normal
              Saline- One liter of normal saline. Offers plain and simple
              hydration.
            </p>
            <span className="price">$185</span>
            <label htmlFor="quantityHydrateMe">Quantity</label>
            <input
              type="number"
              id="quantityHydrateMe"
              name="quantityHydrateMe"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseHydrateMe(selectedUser);
              }}
            >
              {hydrateMeLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Recovery and Performance IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              Most popular IV! Helps decrease recovery time, enhances your
              athletic performance, replenishes essential nutrients, and reduces
              inflammation. Offers Vitamin C, Vita Complex with B-complex, Amino
              Blend (decreases muscle loss, burns fat more quickly, and
              increases metabolism), and mineral blend (removes toxins,
              replenishes vitamins, and help reverse effects of dehydration).
              All in a liter of Lactated Ringers.
            </p>
            <span className="price">$230</span>
            <label htmlFor="quantityPerformance">Quantity</label>
            <input
              type="number"
              id="quantityPerformance"
              name="quantityPerformance"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchasePerformance(selectedUser);
              }}
            >
              {performanceTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Quinch Me IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              Rehydrates your body and combats fatigue caused by dehydration.
              Detoxifies, reduces inflammation, rehydrates, improves
              circulation, and fights fatigue. Offers Vitamin C (an antioxidant
              to protect cells, boost immunity, and create collagen), Vita
              Complex with B-complex (helps keep cells and skin healthy and
              convert nutrients to energy), Mineral blend (helps reverse effects
              of dehydration, remove toxins, and replenish vitamins). All in a
              liter of Lactated Ringer.
            </p>
            <span className="price">$215</span>
            <label htmlFor="quantityQuinch">Quantity</label>
            <input
              type="number"
              id="quantityQuinch"
              name="quantityQuinch"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseQuinch(selectedUser);
              }}
            >
              {quinchTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Myers Cocktail IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              The famous Myer’s Cocktail designed by Dr. John Myer who was at
              the forefront of IV vitamin infusions. This combination of
              vitamins and minerals helps alleviate stress, improves immunity,
              restores balance, provides hydration, helps reduce migraines, and
              reduces chronic pain. Offers Magnesium Chloride (reduces fatigue,
              improves immunity, decreases risk of developing migraines,
              improves relaxation, and more), B-complex ( may help keep skin and
              blood cells healthy, converts nutrients to energy), Hydroxo B12
              (Promotes healthy brain function, blood cells and nerves, Calcium
              Gluconate (promotes healthy bones, and normal function of muscles,
              nerves, and cells), Vitamin C (may protect cells from damage,
              absorb iron, create collagen, boost immunity). All in one liter of
              Lactated Ringer
            </p>
            <span className="price">$265</span>
            <label htmlFor="quantityMyers">Quantity</label>
            <input
              type="number"
              id="quantityMyers"
              name="quantityMyers"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseMyers(selectedUser);
              }}
            >
              {myersTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Elvira - 60 Minute Custom Massage</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              A 60 minute customized massage provided by our massage specialist
              Elvira
            </p>
            <span className="price">$135</span>
            <label htmlFor="quantityAlvira">Quantity</label>
            <input
              type="number"
              id="quantityAlvira"
              name="quantityAlvira"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseAlviraTickets();
              }}
            >
              {alviraTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Elvira - 90 Minute Custom Massage</strong>
            <p>Session Length: 90 minutes</p>
            <p>
              A 90 minute customized massage provided by our massage specialist
              Elvira
            </p>
            <span className="price">$195</span>
            <label htmlFor="quantityAlvira2">Quantity</label>
            <input
              type="number"
              id="quantityAlvira2"
              name="quantityAlvira2"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseAlvira2Tickets();
              }}
            >
              {alvira2TicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Hangover Fix IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              How was your weekend? Barely remember it, nauseated, headache,
              tired, and feel miserable? We are here to your rescue!!! With a
              hangover IV. We will hydrate you with a liter of Lactated Ringer
              filled with Vitamin C, Vita Complex with B-complex, and Mineral
              blend, and Ondansetron (antiemetic) as needed for nausea, and
              Ketorolac (NSAID pain reliever) as needed for headaches and body
              aches.
            </p>
            <span className="price">$230</span>
            <label htmlFor="quantityHangover">Quantity</label>
            <input
              type="number"
              id="quantityHangover"
              name="quantityHangover"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseHangoverTickets(selectedUser);
              }}
            >
              {hangoverTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Test Booking</strong>
            <p>Session Length: 60 minutes</p>
            <p>Test booking for 1$</p>
            <span className="price">$230</span>
            <label htmlFor="quantityTest">Quantity</label>
            <input
              type="number"
              id="quantityTest"
              name="quantityTest"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseTest(selectedUser);
              }}
            >
              {purchaseTestLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
        </div>
      </div>
    );
  } else return <PageLoader />;
};

export default PosScheduling;
