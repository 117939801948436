import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import UserBridge from "../bridges/UserBridge";
import PageLoader from "../components/parts/PageLoader";
import { Link } from "react-router-dom";

const CrmView = () => {
  const [loaded, setLoaded] = useState(false);
  const [signOutLoader, setSignOutLoader] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await queryUser();
    setLoaded(true);
  };

  const queryUser = async () => {
    const response = await UserBridge.readCurrentUser();
    const result = await response.json();
    if (result.error || response.status != 200) {
      nav("/login");
    } else {
      setCurrentUser(result.data);
    }
    setLoaded(true);
  };

  const signOut = async () => {
    setSignOutLoader(true);
    const response = await UserBridge.logout();
    const result = await response.json();
    if (result.error) {
      setSignOutLoader(false);
      alert(`There was an error, please try again.`);
    } else {
      setSignOutLoader(false);
      window.location.href = "https://ffnaw.com";
    }
  };

  const renderMenu = () => {
    switch (currentUser.authLevel) {
      case "0":
        nav("/login");
        break;
      case "1":
        return serviceProviderMenu();
      case "2":
        return employeeMenu();
      case "3":
        return managerMenu();
      case "4":
        return masterMenu();
    }
  };

  const serviceProviderMenu = () => {
    return (
      <>
        <h3>Dashboard</h3>
        <Link to="/v1/crm/">Home</Link>
        <h3>Services</h3>
        <Link to="/v1/crm/services/scheduling">Scheduling</Link>
        <h3>Pos</h3>
        <Link to="/v1/crm/pos/checkin">Service Checkins</Link>
        <h3>Help</h3>
        <Link to="/v1/crm/help">Help Docs</Link>
      </>
    );
  };

  const employeeMenu = () => {
    return (
      <>
        <h3>Dashboard</h3>
        <Link to="/v1/crm/">Home</Link>
        <h3>Accounts</h3>
        <Link to="/v1/crm/accounts/clients">Clients</Link>
        <h3>Orders</h3>
        <Link to="/v1/crm/orders/fulfillment">Fulfillment</Link>
        <Link to="/v1/crm/orders/history">History</Link>
        <h3>Services</h3>
        <Link to="/v1/crm/services/scheduling">Scheduling</Link>
        <Link to="/v1/crm/services/history">History</Link>
        <h3>Products</h3>
        <Link to="/v1/crm/products/inventory">Inventory</Link>
        <Link to="/v1/crm/products/stocking">Stocking</Link>
        <h3>Pos</h3>
        <Link to="/v1/crm/pos">Point of Sale</Link>
        <h3>Help</h3>
        <Link to="/v1/crm/help">Help Docs</Link>
      </>
    );
  };

  const managerMenu = () => {
    return (
      <>
        {" "}
        <h3>Dashboard</h3>
        <Link to="/v1/crm/">Home</Link>
        <h3>Accounts</h3>
        <Link to="/v1/crm/accounts/clients">Clients</Link>
        <Link to="/v1/crm/accounts/employees">Employees</Link>
        <Link to="/v1/crm/accounts/timesheet">Timesheet</Link>
        <h3>Orders</h3>
        <Link to="/v1/crm/orders/fulfillment">Fulfillment</Link>
        <Link to="/v1/crm/orders/history">History</Link>
        <h3>Services</h3>
        <Link to="/v1/crm/services/scheduling">Scheduling</Link>
        <Link to="/v1/crm/services/ticketing">Ticketing</Link>
        <Link to="/v1/crm/services/history">History</Link>
        <h3>Products</h3>
        <Link to="/v1/crm/products/manage">Manage Products</Link>
        <Link to="/v1/crm/products/inventory">Inventory</Link>
        <Link to="/v1/crm/products/stocking">Stocking</Link>
        <h3>Pos</h3>
        <Link to="/v1/crm/pos">Point of Sale</Link>
        <h3>Help</h3>
        <Link to="/v1/crm/help">Help Docs</Link>
      </>
    );
  };

  const masterMenu = () => {
    return (
      <>
        <h3>Dashboard</h3>
        <Link to="/v1/crm/">Home</Link>
        <h3>Accounts</h3>
        <Link to="/v1/crm/accounts/clients">Clients</Link>
        <Link to="/v1/crm/accounts/employees">Employees</Link>
        <Link to="/v1/crm/accounts/managers">Managers</Link>
        <Link to="/v1/crm/accounts/serviceproviders">Service Providers</Link>
        <Link to="/v1/crm/accounts/timesheet">Timesheet</Link>
        <h3>Orders</h3>
        <Link to="/v1/crm/orders/fulfillment">Fulfillment</Link>
        <Link to="/v1/crm/orders/history">History</Link>
        <h3>Services</h3>
        <Link to="/v1/crm/services/manage">Manage Services</Link>
        <Link to="/v1/crm/services/packages">Manage Packages</Link>
        <Link to="/v1/crm/services/scheduling">Scheduling</Link>
        <Link to="/v1/crm/services/ticketing">Ticketing</Link>
        <Link to="/v1/crm/services/history">History</Link>
        <h3>Products</h3>
        <Link to="/v1/crm/products/manage">Manage Products</Link>
        <Link to="/v1/crm/products/inventory">Inventory</Link>
        <Link to="/v1/crm/products/stocking">Stocking</Link>
        <Link to="/v1/crm/products/promotions">Promotions</Link>
        <h3>Pos</h3>
        <Link to="/v1/crm/pos">Point of Sale</Link>
        <h3>Help</h3>
        <Link to="/v1/crm/help">Help Docs</Link>
      </>
    );
  };

  if (loaded && currentUser) {
    return (
      <section className="crmView">
        <div className="header">
          <div className="left">
            <img
              src="/assets/watermark.png"
              alt="Flex Factory Nutrition and Wellness Logo"
            />
          </div>
          <div className="right">
            <Link to="/v1/account">Account Portal</Link>
            <button onClick={signOut}>
              {signOutLoader ? <Loading /> : `Log Out`}
            </button>
          </div>
        </div>
        <div className="contentArea">
          <aside className="masterMenu">{renderMenu()}</aside>
          <div className="content">
            <Outlet />
          </div>
        </div>
        <div className="footer">
          <div className="logos">
            <img
              src="/assets/watermark.png"
              alt="Sierra Precision Home Inspection Logo"
            />
            <p>|</p>
            <img
              src="/assets/dcwm.png"
              alt="Denicode LLC Software House watermark"
            />
          </div>
          <Link to="/legal/termsofuse">Terms of Use</Link>
        </div>
      </section>
    );
  } else return <PageLoader />;
};

export default CrmView;
