import React from "react";
import { Outlet } from "react-router";

const CrmProducts = () => {

  return (
    <section className="crmProducts">
     <h1>CrmProducts</h1>
     <Outlet />
    </section>
  );
};

export default CrmProducts;
