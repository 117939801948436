import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CartContext } from "../../views/StoreView";

const StoreHeader = () => {
  const nav = useNavigate();
  const [signOutLoader, setSignOutLoader] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { cartCount, setCartCount } = useContext(CartContext);

  useEffect(() => {
    let cart = JSON.parse(localStorage.getItem("ffnawcart")) || [];
    let cartCount = cart.reduce(
      (total, item) => total + Number(item.quantity),
      0
    );
    setCartCount(cartCount);
  }, [location, setCartCount]);

  const signOut = async () => {
    setSignOutLoader(true);
    const response = await UserBridge.logout();
    const result = await response.json();
    if (result.error) {
      setSignOutLoader(false);
      alert(`There was an error, please try again.`);
    } else {
      setSignOutLoader(false);
      window.location.href = "https://ffnaw.com";
    }
  };

  const signIn = () => {
    nav("/login")
  }

  const toggleMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  return (
    <>
      <nav className="accountHeader">
        <div className="desktop">
          <div className="left">
            <a href="https://ffnaw.com">
              <img src="../../assets/watermark-alpha.png" />
            </a>
          </div>

          <div className="right">
            <a href="https://ffnaw.com">Home</a>
            <Link to='/v1/account/services'>
              Schedule Services
            </Link>
            <Link to='/v1/account'>
              Account
            </Link>
            <div
              className="cart"
              onClick={() => {
                nav("/store/checkout");
              }}
            >
              <img src="../../assets/cart.png" />
              <strong>{cartCount}</strong>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="left">
            <a href="https://ffnaw.com">
              <img src="../../assets/watermark-alpha.png" />
            </a>
          </div>
          <div className="right">
            <button onClick={toggleMenu}>Menu</button>
            <div
              className="cart"
              onClick={() => {
                nav("/store/checkout");
              }}
            >
              <img src="../../assets/cart.png" />
              <strong>{cartCount}</strong>
            </div>
          </div>
        </div>
      </nav>
      <div
        id="menu"
        className={
          menuOpen ? `mobileSlideOut menuOpen` : `mobileSlideOut menuClosed`
        }
        onClick={toggleMenu}
      >
        <label>Account</label>
        <Link to="/v1/account/">Home</Link>
        <Link to="/v1/account/orders">Orders</Link>
        <Link to="/v1/account/services">Services</Link>
        <Link to="/v1/account/info">Account Info</Link>
        <Link to="/store">Shop Now</Link>
        <button onClick={signIn}>Log In</button>
        <button onClick={signOut}>Log Out</button>
      </div>
    </>
  );
};

export default StoreHeader;
