import React from "react";
import { Outlet } from "react-router";

const CrmServices = () => {
  return (
    <section className="crmServices">
     <h1>CrmServices</h1>
     <Outlet />
    </section>
  );
};

export default CrmServices;
