import React, { useEffect, useState } from "react";
import testProducts from "../../../server/testData/testProducts";
import Loading from "../parts/Loading";
import PageLoader from "../parts/PageLoader";
import { useNavigate } from "react-router";
import ProductBridge from "../../bridges/ProductBridge";
import CartController from "../../controllers/CartController";

const StoreShop = () => {
  const nav = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [productsLoader, setProductsLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [displayQuantity, setDisplayQuantity] = useState(50);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (loaded === true) {
      setPagination();
      updateDisplayResults(1);
    }
  }, [loaded]);

  useEffect(() => {
    setProductsLoader(true);
    setPagination();
    updateDisplayResults(1);
  }, [displayQuantity]);

  useEffect(() => {
    if (loaded) {
      scrollToControls();
    }
  }, [currentPage]);

  useEffect(() => {
    if (productsLoader) {
      setTimeout(() => {
        setProductsLoader(false);
      }, 250);
    } else {
      setProductsLoader(true);
      setTimeout(() => {
        setProductsLoader(false);
      }, 250);
    }
  }, [displayResults]);

  const getProducts = async () => {
    const response = await ProductBridge.getStoreProducts();
    const result = await response.json();
    setProducts(result.data);
    setFilteredResults(result.data);
    setLoaded(true);
  };

  const setPagination = () => {
    console.log("settingPagination");
    setCurrentPage(1);
    let pages = Math.floor(filteredResults.length / displayQuantity) + 1;
    setTotalPages(pages);
  };

  const updateDisplayResults = (page) => {
    setProductsLoader(true);
    let firstToDisplay = (page - 1) * displayQuantity;
    if (firstToDisplay <= 0) {
      firstToDisplay = 0;
    }
    let lastToDisplay = firstToDisplay + displayQuantity;
    if (lastToDisplay > filteredResults.length) {
      lastToDisplay = undefined;
    }
    const results = filteredResults.slice(firstToDisplay, lastToDisplay);
    console.log(
      `updatingDisplayResults, displayQuantity ${displayQuantity}, firstToDisplay ${firstToDisplay}, lastToDisplay ${lastToDisplay}`
    );
    setDisplayResults(results);
  };

  const populateCategories = () => {
    let payload = [];
    let count = 0;
    const category = (value, name) => {
      return <option value={value}>{name}</option>;
    };
    payload.push(category("na", "n/a"));
    while (count <= 5) {
      const valueString = `category${count}`;
      const nameString = `name${count}`;
      payload.push(category(valueString, nameString));
      count++;
    }
    return payload;
  };

  const productElement = (
    productId,
    productPrice,
    productName,
    productCategory,
    photoName
  ) => {
    const selectId = `quantity${productId}`;
    const buttonId = `addToCart${productId}`;
    const navString = `/store/product/${productId}`;
    const photoUrl = `/photos/${photoName}`;
    return (
      <div key={productId} className="product">
        <img
          src={photoUrl}
          onClick={() => {
            nav(navString);
          }}
        />
        <strong className="name">{productName}</strong>
        <p>{productCategory}</p>
        <strong className="price">{productPrice}</strong>
        <label htmlFor={selectId}>Quantity</label>
        <select name={selectId} id={selectId}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
        <button
          onClick={() => {
            CartController.addToCart(
              productId,
              Number(document.getElementById(selectId).value)
            );
            nav("/store/checkout");
          }}
          id={buttonId}
        >
          Add to Cart
        </button>
      </div>
    );
  };

  const generateProducts = () => {
    let payload = [];
    displayResults.forEach((product) => {
      let priceString = `$${product.price / 100}`;
      payload.push(
        productElement(
          product._id,
          priceString,
          product.name,
          product.category,
          product.photos[0]
        )
      );
    });
    return payload;
  };

  const nextPage = () => {
    let page = 1;
    if (currentPage === totalPages) {
      page = 1;
    } else {
      page = currentPage + 1;
    }
    setCurrentPage(page);
    updateDisplayResults(page);
  };

  const backPage = () => {
    let page = 1;
    if (currentPage === 1) {
      page = totalPages;
    } else {
      page = currentPage - 1;
    }
    setCurrentPage(page);
    updateDisplayResults(page);
  };

  const scrollToControls = () => {
    const el = document.getElementById("controls");
    window.scrollTo({ top: 600, behavior: "smooth" });
  };

  const handleDisplayQuantityChange = (e) => {
    setDisplayQuantity(e.target.value);
  };

  if (loaded) {
    return (
      <section className="storeShop">
        <img className="banner mobileHide" src="../../assets/hero.png" />
        <img className="banner mobileShow" src="../../assets/heroMobile.png" />
        <div className="content">
          {/* <div id="controls" className="controls">
            <div className="bar">
              <input
                name="searchBar"
                id="searchBar"
                type="text"
                placeholder="search now"
              />
              <button>Search</button>
            </div>
            <div className="category">
              <label htmlFor="category">Filter by Category:</label>
              <select name="category" id="category">
                {populateCategories()}
              </select>
            </div>
            <div className="category">
              <label htmlFor="sort">Sort:</label>
              <select name="sort" id="sort">
                <option value="none">n/a</option>
                <option value="az">A to Z</option>
                <option value="za">Z to A</option>
                <option value="highlow">Price, high to low</option>
                <option value="lowhigh">Price, low to high</option>
                <option value="popnot">Popularity, high to low</option>
                <option value="notpop">popularity, low to high</option>
              </select>
            </div>
            <div className="category">
              <label htmlFor="displayQuantity">Results Per Page:</label>
              <select
                name="displayQuantity"
                id="displayQuantity"
                onChange={handleDisplayQuantityChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div> */}
          <div className="products">
            {productsLoader ? <Loading /> : generateProducts()}
            {/* <div className="placeholder">
              <h1>Products coming soon!</h1>
            </div> */}
          </div>
          <div className="paginator">
            <button onClick={backPage}>{"<"}</button>
            <strong>{`${currentPage} / ${totalPages}`}</strong>
            <button onClick={nextPage}>{">"}</button>
          </div>
        </div>
      </section>
    );
  } else {
    return <PageLoader />;
  }
};

export default StoreShop;
