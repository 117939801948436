import React from "react";
import { Outlet, useNavigate } from "react-router";

const CrmHelp = () => {
  const nav = useNavigate();
  return (
    <section className="crmHelp">
      <h1>Need Help? We got you covered</h1>
      <p>
        Here you will find tutorials on how to perform just about every function
        that your Flex Factory Nutrition and Wellness CRM Software can do.
      </p>
      <p>
        If you do not find what you are looking for, do not hesitate to reach
        out to your manager, and if they cannot assist, they are more then
        welcome to reach out to Denicode LLC to provide tech support and
        continuing education.
      </p>
    </section>
  );
};

export default CrmHelp;
