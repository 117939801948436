import React, { useEffect, useState } from "react";
import PageLoader from "../../parts/PageLoader";
import ProductBridge from "../../../bridges/ProductBridge";
import MicroLoader from "../../parts/MicroLoader";
import CouponBridge from "../../../bridges/CouponBridge";

const ProductPromotions = () => {
  const [loaded, setLoaded] = useState(false);
  const [viewLoader, setViewLoader] = useState(true);
  const [createPromotionLoader, setCreatePromotionLoader] = useState(false);
  const [promotionList, setPromotionList] = useState([]);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getPromotionsList();
    setLoaded(true);
  };

  const getPromotionsList = async () => {
    setViewLoader(true);
    const response = await CouponBridge.getCoupons();
    const result = await response.json();
    console.log(result.data)
    setPromotionList(result.data);
    setViewLoader(false);
  };

  const createPromotion = async () => {
    setCreatePromotionLoader(true);
    setViewLoader(true);
    const response = await CouponBridge.createCoupon(
      document.getElementById("name").value,
      document.getElementById("duration").value,
      document.getElementById("durationInMonths").value,
      document.getElementById("percentOff").value,
      document.getElementById("promoCode").value
    );
    const result = await response.json();
    alert(result.msg);
    setCreatePromotionLoader(false);
    getPromotionsList();
  };

  const renderPromotions = () => {
    console.log(promotionList)
    let payload = [];
    promotionList.forEach((promotion) => {
      payload.push(
        <tr>
          <td>{promotion.name}</td>
          <td>{promotion.promoCode}</td>
          <td>{promotion.percentOff}%</td>
          <td>{promotion.duration}</td>
          <td>{promotion.durationInMonths}</td>
          <button
            id={promotion._id}
            onClick={(e) => {
              deletePromotion(promotion.stripeCouponId, e.target.id);
            }}
          >
            Delete
          </button>
        </tr>
      );
    });
    return payload
  };

  const deletePromotion = async (stripeCouponId, buttonId) => {
    const button = document.getElementById(buttonId);
    button.innerHTML = <MicroLoader />;
    const response = await CouponBridge.deletePromotion(stripeCouponId);
    const result = await response.json();
    alert(result.msg);
    button.innerHTML = "Delete";
    getPromotionsList();
  };

  if (loaded) {
    return (
      <section className="productPromotions">
        <h1>Product Promotions</h1>
        <p>
          Use this module to create promotions. Promo codes here also apply to
          services.
        </p>
        <h3>Active Promotions</h3>
        <table>
          <tr>
            <th>Promotion Name</th>
            <th>Promo Code</th>
            <th>Percent Off</th>
            <th>Duration*</th>
            <th>Duration In Months**</th>
          </tr>
          {viewLoader ? <MicroLoader /> : renderPromotions()}
        </table>
        <h3>Create Promotion</h3>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" placeholder="Promotion Name" />
        <label htmlFor="duration">Duration *</label>
        <select name="duration" id="duration">
          <option value="once">Once</option>
          <option value="forever">Forever</option>
          <option value="repeating">Repeating</option>
        </select>
        <label htmlFor="durationInMonths">Duration in Months **</label>
        <input
          type="number"
          name="durationInMonths"
          id="durationInMonths"
          placeholder="Duration in months"
        />
        <label htmlFor="promoCode">Promo Code</label>
        <input
          type="string"
          name="promoCode"
          id="promoCode"
          placeholder="Promo Code"
        />
        <label htmlFor="percentOff">Percent Off</label>
        <input
          type="number"
          name="percentOff"
          id="percentOff"
          placeholder="Percent Off"
        />
        <button onClick={createPromotion}>
          {createPromotionLoader ? <MicroLoader /> : "Create Promotion"}
        </button>
      </section>
    );
  } else return <PageLoader />;
};

export default ProductPromotions;
