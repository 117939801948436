import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import MicroLoader from "../../parts/MicroLoader";
import PageLoader from "../../parts/PageLoader";
import ThermalBridge from "../../../bridges/ThermalBridge";
import ProductBridge from "../../../bridges/ProductBridge";
import TerminalBridge from "../../../bridges/TerminalBridge";
import CashDrawerBridge from "../../../bridges/CashDrawerBridge";
import TransactionBridge from "../../../bridges/TransactionBridge";
import ServiceBridge from "../../../bridges/ServiceBridge";

const PosCheckout = () => {
  const [loaded, setLoaded] = useState(false);
  const [scannedItems, setScannedItems] = useState([]);
  const [lineItemsLoader, setLineItemsLoader] = useState(false);
  const [testReceiptLoader, setTestReceiptLoader] = useState(false);
  const [reprintReceiptLoader, setReprintReceiptLoader] = useState(false);
  const [pricingLoader, setPricingLoader] = useState(false);
  const [scanningLoader, setScanningLoader] = useState(false);
  const [cancelTransactionLoader, setCancelTransactionLoader] = useState(false);
  const [cashDrawerLoader, setCashDrawerLoader] = useState(false);
  const [cashDrawerStatus, setCashDrawerStatus] = useState(0);
  const [startCashDrawerLoader, setStartCashDrawerLoader] = useState(false);
  const [endCashDrawerLoader, setEndCashDrawerLoader] = useState(false);
  const [discountCodeLoader, setDiscountCodeLoader] = useState(false);
  const [splitLoader, setSplitLoader] = useState(false);
  const [cardLoader, setCardLoader] = useState(false);
  const [cashLoader, setCashLoader] = useState(false);
  const [discountCode, setDiscountCode] = useState();
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [discounts, setDiscounts] = useState(0);
  const [tax, setTax] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [services, setServices] = useState([]);
  const scannerRef = useRef(null);
  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
    document.addEventListener("keydown", handleBarcodeScan);
  }, []);

  const pageLoad = async () => {
    await getProducts();
    await getServices();
    //await checkCashDrawer();
    setLoaded(true);
  };

  const checkCashDrawer = async () => {
    setCashDrawerLoader(true);
    const response = await CashDrawerBridge.query();
    const result = await response.json();
    setCashDrawerStatus(result.status);
    setCashDrawerLoader(false);
  };

  const getServices = async () => {
    const response = await ServiceBridge.getServices();
    const result = await response.json();
    setServices(result.data);
  };

  const getProducts = async () => {
    const response = await ProductBridge.getProducts();
    const result = await response.json();
    setProducts(result.data);
  };

  const updatePricing = async (code) => {
    let dc;
    if (!!code) {
      dc = code;
    } else dc = discountCode;
    setPricingLoader(true);
    const response = await ProductBridge.resolvePricing(scannedItems, dc);
    console.log(dc);
    const result = await response.json();
    setQuantity(result.data.quantity);
    setSubtotal(result.data.subtotal);
    setDiscounts(result.data.discount);
    setTax(result.data.tax);
    setNetTotal(result.data.total);
    setPricingLoader(false);
  };

  const renderLineItems = () => {
    let payload = [];
    scannedItems.forEach((scannedItem) => {
      console.log(scannedItem);
      let notFound = false;
      for (let i = 0; i < products.length; i++) {
        if (scannedItem === products[i]._id) {
          let prettyPrice = `$${products[i].price / 100}`;
          payload.push(
            <tr>
              <td>{products[i].name}</td>
              <td>{prettyPrice}</td>
              <button
                onClick={() => {
                  removeItemFromCart(scannedItem);
                }}
              >
                Remove
              </button>
            </tr>
          );
        } else {
          if (i === products.length - 1) {
            notFound = true;
          }
        }
      }
      if (notFound) {
        for (let i = 0; i < services.length; i++) {
          if (scannedItem === services[i]._id) {
            let prettyPrice = `$${services[i].price1 / 100}`;
            payload.push(
              <tr>
                <td>{services[i].name}</td>
                <td>{prettyPrice}</td>
                <button
                  onClick={() => {
                    removeItemFromCart(scannedItem);
                  }}
                >
                  Remove
                </button>
              </tr>
            );
          }
        }
      }
    });
    return payload;
  };

  const removeItemFromCart = (scannedItem) => {
    let array = scannedItems;
    let index = array.indexOf(scannedItem);
    console.log(scannedItem);
    if (index !== -1) {
      array.splice(index, 1);
    }
    console.log(array);
    setScannedItems(array);
    triggerUiRefresh();
  };

  const triggerUiRefresh = () => {
    setLineItemsLoader(true);
    updatePricing();
    setTimeout(() => {
      setLineItemsLoader(false);
    }, 200);
  };

  const printTestReceipt = async () => {
    setTestReceiptLoader(true);
    const response = await ThermalBridge.printTestReceipt();
    const result = await response.json();
    alert(result.msg);
    setTestReceiptLoader(false);
  };

  const reprintReceipt = async () => {
    setReprintReceiptLoader(true);
    const response = await ThermalBridge.reprintReceipt();
    const result = await response.json();
    alert(result.msg);
    setReprintReceiptLoader(false);
  };

  const startScanning = () => {
    setScanningLoader(true);
  };

  const stopScanning = () => {
    setScanningLoader(false);
  };

  let barcode = "";
  const handleBarcodeScan = (ev) => {
    if (ev.code == "Enter") {
      if (barcode) {
        const barcodes = scannedItems;
        barcodes.push(barcode);
        console.log(barcode);
        console.log(barcodes);
        setScannedItems(barcodes);
        triggerUiRefresh();
        barcode = "";
      }
    } else if (ev.keyCode !== 144 || ev.keyCode !== 16) {
      barcode += ev.key;
    }
  };

  const emptyCart = () => {
    location.reload();
  };

  const startCashDrawer = async () => {
    setStartCashDrawerLoader(true);
    alert(
      "You should take a pen and paper note of your starting and ending balance as a back up until the balance system is further tested through use."
    );
    const total = prompt("Please enter the starting balance in dollars");
    const totalNumber = Number(total);
    const response = await CashDrawerBridge.startDrawer(totalNumber);
    const result = await response.json();
    alert(result.msg);
    checkCashDrawer();
    setStartCashDrawerLoader(false);
  };

  const endCashDrawer = async () => {
    setEndCashDrawerLoader(true);
    const response1 = await ThermalBridge.reprintReceipt();
    alert(
      "You should take a pen and paper note of your starting and ending balance as a back up until the balance system is further tested through use."
    );
    const total = prompt("Please enter the ending balance in dollars");
    const totalNumber = Number(total);
    const response = await CashDrawerBridge.endDrawer(totalNumber);
    const result = await response.json();
    alert(result.msg);
    checkCashDrawer();
    setEndCashDrawerLoader(false);
  };

  const cancelTransaction = async () => {
    setCancelTransactionLoader(true);
    const response = await TerminalBridge.resetReader();
    const result = await response.json();
    alert(result.msg);
    setCancelTransactionLoader(false);
  };

  const renderCashDrawerStatus = () => {
    switch (cashDrawerStatus) {
      case 0:
        return <span className="redText">Offline</span>;
      case 1:
        return <span className="greenText">Ready</span>;
      case 2:
        return <span className="yellowText">Closed</span>;
    }
  };

  const enterDiscount = async () => {
    setDiscountCodeLoader(true);
    const code = prompt("Please enter a discount code");
    console.log(code);
    setDiscountCode(code);
    await updatePricing(code);
    setDiscountCodeLoader(false);
  };

  const startSplit = async () => {
    setSplitLoader(true);
    const cardTotal = prompt("Please enter ammount to charge on card first:");
    const response = await TerminalBridge.createPayment(
      scannedItems,
      discountCode,
      cardTotal
    );
    const result = await response.json();
    alert(result.msg);
    const approved = confirm("Was the transaction approved?");
    if (approved) {
      //  start cash transaction
      const tendered = prompt(
        `Customer owes $${result.cashBalance} in cash. Enter tendered amount:`
      );
      const tenderedNumber = Number(tendered);
      const response2 = await CashDrawerBridge.startCash(
        scannedItems,
        tenderedNumber,
        discountCode,
        result.cashBalance,
        result.paymentId
      );
      const result2 = await response2.json();
      const { change } = result2.data;
      const receiptResponse = await ThermalBridge.printSplitReceipt(
        tendered,
        cardTotal,
        quantity,
        subtotal,
        discounts,
        tax,
        netTotal,
        change
      );
      const afterchargeResult = await TransactionBridge.afterCharge(
        scannedItems
      );
      const receiptResult = await receiptResponse.json();
      alert(result.msg);
      location.reload();
    }
    setSplitLoader(false);
  };

  const startCard = async () => {
    setCardLoader(true);
    const response = await TerminalBridge.createPayment(
      scannedItems,
      discountCode
    );
    const result = await response.json();
    alert(result.msg);
    const approved = confirm("Was the transaction approved?");
    if (approved) {
      const receiptResponse = await ThermalBridge.printCardReceipt(
        quantity,
        subtotal,
        discounts,
        tax,
        netTotal
      );
      const afterchargeResult = await TransactionBridge.afterCharge(
        scannedItems
      );
      location.reload();
      setCardLoader(false);
    } else {
      setCardLoader(false);
    }
  };

  const startCash = async () => {
    setCashLoader(true);
    const tendered = prompt("Enter tendered amount:");
    if (tendered !== undefined) {
      const tenderedNumber = Number(tendered);
      const response = await CashDrawerBridge.startCash(
        scannedItems,
        tenderedNumber,
        discountCode
      );
      const result = await response.json();
      const { change } = result.data;
      const receiptResponse = await ThermalBridge.printCashReceipt(
        tendered,
        quantity,
        subtotal,
        discounts,
        tax,
        netTotal,
        change
      );
      const afterchargeResult = await TransactionBridge.afterCharge(
        scannedItems
      );
      //const receiptResult = await receiptResponse.json();
      alert(result.msg);
    }

    location.reload();
  };

  const renderProducts = () => {
    let payload = [];
    products.forEach((product) => {
      payload.push(<option value={product._id}>{product.name}</option>);
    });
    return payload;
  };

  const renderServices = () => {
    let payload = [];
    services.forEach((service) => {
      payload.push(<option value={service._id}>{service.name}</option>);
    });
    return payload;
  };

  const manualAdd = () => {
    const product = document.getElementById("productSelect").value;
    let barcodes = scannedItems;
    barcodes.push(product);
    triggerUiRefresh();
  };

  const manualAddService = () => {
    const product = document.getElementById("serviceSelect").value;
    let barcodes = scannedItems;
    barcodes.push(product);
    triggerUiRefresh();
  };

  if (loaded) {
    return (
      <div className="posCheckout">
        <h1>Check Out</h1>
        {/* <h3>
          Cash Drawer Status:{" "}
          {cashDrawerLoader ? <MicroLoader /> : renderCashDrawerStatus()}
        </h3> */}
        <strong
          className="breadcrumb"
          onClick={() => {
            nav("/v1/crm/pos");
          }}
        >
          &larr; Return to POS
        </strong>
        <div className="checkoutWindow">
          <div className="left">
            <h3>Line Items</h3>
            <div className="items">
              <table>
                <tr>
                  <th>Product Name</th>
                  <th>Product Price</th>
                </tr>
                {lineItemsLoader ? <MicroLoader /> : renderLineItems()}
              </table>
            </div>
          </div>
          <div className="right">
            {pricingLoader ? (
              <MicroLoader />
            ) : (
              <>
                <strong>Total Quantity: {quantity}</strong>
                <strong>Subtotal: ${subtotal}</strong>
                <strong>Discounts: ${discounts}</strong>
                <strong>Tax: ${tax}</strong>
                <strong>Net Total: ${netTotal}</strong>
              </>
            )}
          </div>
        </div>
        <div className="controls">
          <div className="left">
            <div>
              <strong>Manual Products</strong>
              <select id="productSelect">
                <option>SELECT A PRODUCT</option>
                {renderProducts()}
              </select>
              <button onClick={manualAdd}>Add Product</button>
            </div>
            <div>
              <strong>Manual Services</strong>
              <select id="serviceSelect">
                <option>SELECT A SERVICE</option>
                {renderServices()}
              </select>
              <button onClick={manualAddService}>Add Service</button>
            </div>
          </div>
          <div className="right">
            <div className="chunk">
              <button onClick={startCashDrawer}>
                {startCashDrawerLoader ? <MicroLoader /> : "Start Cash Drawer"}
              </button>
              <button onClick={endCashDrawer}>
                {endCashDrawerLoader ? <MicroLoader /> : "End Cash Drawer"}
              </button>
            </div>
            <div className="chunk">
              <button onClick={startScanning}>
                {scanningLoader ? <MicroLoader /> : "Start Scanning"}
              </button>
              <button onClick={stopScanning}>Stop Scanning</button>
              <button onClick={emptyCart}>Empty Cart</button>
            </div>
            <div className="chunk">
              <button onClick={cancelTransaction}>
                {cancelTransactionLoader ? (
                  <MicroLoader />
                ) : (
                  "Cancel Transaction"
                )}
              </button>
              <button onClick={printTestReceipt}>
                {testReceiptLoader ? <MicroLoader /> : "Test Receipt"}
              </button>
              <button onClick={reprintReceipt}>
                {reprintReceiptLoader ? <MicroLoader /> : "Reprint Receipt"}
              </button>
            </div>
            <div className="chunk">
              <button onClick={enterDiscount}>
                {discountCodeLoader ? <MicroLoader /> : "Discount Code"}
              </button>
              <button onClick={startSplit}>
                {splitLoader ? <MicroLoader /> : "Split Payment"}
              </button>
              <button onClick={startCard}>
                {cardLoader ? <MicroLoader /> : "Card"}
              </button>
              <button onClick={startCash}>
                {cashLoader ? <MicroLoader /> : "Cash"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <PageLoader />;
};

export default PosCheckout;
