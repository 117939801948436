import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import PageLoader from "../parts/PageLoader";
import ProductBridge from "../../bridges/ProductBridge";

const StoreProduct = () => {
  const [loaded, setLoaded] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [photoUrl, setPhotoUrl] = useState();
  const [priceString, setPriceString] = useState();

  const params = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await ProductBridge.getProduct(params.id);
    const result = await response.json();
    setSelectedProduct(result.data);
    const url = `/photos/${result.data.photos[0]}`;
    const price = `$${result.data.price / 100}`;
    setPriceString(price);
    setPhotoUrl(url);
    setLoaded(true);
  };

  if (loaded) {
    return (
      <section className="storeProduct">
        <div className="imageSection">
          <img src={photoUrl} />
        </div>
        <div className="formControls">
          <h1>{selectedProduct.name}</h1>
          <strong className="price">{priceString}</strong>
          <label htmlFor="quantity">Quantity</label>
          <select name="quantity" id="quantity">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
          <button id="addToCart">Add to Cart</button>
          <p>{selectedProduct.description}</p>
        </div>
      </section>
    );
  } else return <PageLoader />;
};

export default StoreProduct;
