import React from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";

const CrmAccounts = () => {
  return (
    <section className="crmAccounts">
     <h1>Accounts</h1>
     <Link to="/v1/crm/accounts/clients">Clients</Link>
     <Link to="/v1/crm/accounts/employees">Employees</Link>
     <Link to="/v1/crm/accounts/managers">Managers</Link>
     <Link to="/v1/crm/accounts/serviceproviders">Service Providers</Link>
    </section>
  );
};
 
export default CrmAccounts;
