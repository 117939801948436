import React, { useEffect, useState } from "react";
import PageLoader from "../../parts/PageLoader";
import ProductBridge from "../../../bridges/ProductBridge";
import * as receiptline from "receiptline";
import MicroLoader from "../../parts/MicroLoader";
import ThermalBridge from "../../../bridges/ThermalBridge";

const ProductsStocking = () => {
  const [loaded, setLoaded] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [generateLoader, setGenerateLoader] = useState(false);
  const [barcodeLoader, setBarcodeLoader] = useState(false);
  const [finishLoader, setFinishLoader] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [barcodeSource, setBarcodeSource] = useState(null);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    const data = await getProductList();
    setProductList(data);
    console.log(data);
    setLoaded(true);
  };

  const getProductList = async () => {
    const response2 = await ProductBridge.getProducts();
    const result2 = await response2.json();
    return result2.data;
  };

  const getSelectedData = async (_id) => {
    const response = await ProductBridge.getProduct(_id);
    const result = await response.json();
    setSelectedProductData(result.data);
  };

  const populateProductSelect = () => {
    const payload = [];
    productList.forEach((product) => {
      payload.push(<option value={product._id}>{product.name}</option>);
    });
    return payload;
  };

  const generateBarcode = async () => {
    setGenerateLoader(true);
    const response = await ThermalBridge.generateBarcode(
      selectedProduct,
      selectedProductData.price
    );
    setBarcodeSource(response);
    setGenerateLoader(false);
  };

  const finalizeAddition = async () => {
    setFinishLoader(true);
    const quantity = Number(document.getElementById("quantity").value);
    const response = await ProductBridge.addInventory(
      selectedProduct,
      quantity
    );
    const result = await response.json();
    alert(result.msg);
    setFinishLoader(false);
  };

  if (loaded) {
    return (
      <section className="productsStocking">
        <h1>Product Stocking</h1>
        <p>
          Use this module to perform inventory. When products arrive, they must
          be added to the inventory system. This will update the web store's
          inventory as well as your retail location's inventory at the same
          time.
        </p>
        <p>
          When a shipment arrives, you must enter it into the system and print a
          barcode to be placed on the product. This barcode will display the
          price, and contain the productId. When a customer makes a purchase,
          your cashier will scan this barcode and their cart will be updated in
          the point of sale system.
        </p>
        <h3>Step One</h3>
        <p>
          Select your product from the list. If this is a new product not yet in
          the system, you must add it view the Manage Products module.
        </p>
        <label htmlFor="productSelect">Select a Product</label>
        <select
          name="productSelect"
          id="productSelect"
          onChange={(e) => {
            setSelectedProduct(e.target.value);
            getSelectedData(e.target.value);
          }}
        >
          <option value="">SELECT A PRODUCT</option>
          {populateProductSelect()}
        </select>
        <h3>Step Two</h3>
        <p>
          Count up all of the products, and enter how many you are adding to the
          inventory.
        </p>
        <label htmlFor="quantity">Quantity</label>
        <input
          type="number"
          id="quantity"
          name="quantity"
          placeholder="enter the quantity you wish to add"
        />
        <h3>Step Three</h3>
        <p>
          Create the barcodes, print them out, and place them on your products.
          Right click the barcode, download it, open it, right click and print.
          Verify your print settings, and make sure to print enough copies.
        </p>
        <button onClick={generateBarcode}>
          {generateLoader ? <MicroLoader /> : "Generate Barcode"}
        </button>
        <div id="barcode" className="barcode">
          {barcodeLoader ? <MicroLoader /> : <img src={barcodeSource}></img>}
        </div>
        <h3>Step Four</h3>
        <p>
          Click finalize to finish adding your product, and then move on to the
          next one. Place your products on display, or in the back.
        </p>
        <button onClick={finalizeAddition}>
          {finishLoader ? <MicroLoader /> : "Finalize"}
        </button>
      </section>
    );
  } else return <PageLoader />;
};

export default ProductsStocking;
