import React, { useEffect, useState } from "react";
import ProductBridge from "../../bridges/ProductBridge";
import MicroLoader from "../parts/MicroLoader";
import CartController from "../../controllers/CartController";
import PageLoader from "../parts/PageLoader";
import { useNavigate } from "react-router";
import OrderBridge from "../../bridges/OrderBridge";

const StoreCheckout = () => {
  const [loaded, setLoaded] = useState(false);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [itemsLoader, setItemsLoader] = useState(true);
  const [productData, setProductData] = useState([]);
  const [pricingData, setPricingData] = useState({});

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    pageLoad();
  }, []);

  useEffect(() => {
    if (loaded) {
      detectInstantCheckout();
    }
  }, [loaded]);

  const pageLoad = async () => {
    await parseCart();
    setLoaded(true);
  };

  const detectInstantCheckout = () => {
    const params = new URLSearchParams(window.location.search);
    const checkout = params.get("checkout");
    const subscription = params.get("subscription");
    if (subscription == "true") {
      const subSelect = document.getElementById("subscription");
      subSelect.value = "true";
    }
    if (checkout) {
      sendToCheckout();
    }
  };

  const parseCart = async () => {
    setItemsLoader(true);
    const cart = localStorage.getItem("ffnawcart");
    const response = await ProductBridge.resolveCart(cart);
    const result = await response.json();
    if ("data" in result) {
      const { data } = result;
      const { productData, pricingData } = data;
      setProductData(productData);
      setPricingData(pricingData);
    } else {
      const pricingData = {
        quantity: 0,
        subtotal: 0,
        discount: 0,
        tax: 0,
        total: 0,
      };
      setPricingData(pricingData);
    }
    setItemsLoader(false);
  };

  const sendToCheckout = async () => {
    setCheckoutLoader(true);
    const cart = localStorage.getItem("ffnawcart");
    const cartObj = JSON.parse(cart);
    if (cartObj.length < 1) {
      alert("You have no items in your cart.");
    } else {
      const response = await OrderBridge.initiateCheckout(
        localStorage.getItem("ffnawcart"),
        document.getElementById("subscription").value
      );
      const result = await response.json();
      if (result.conflict) {
        alert(result.msg);
      } else {
        CartController.emptyCart();
        window.location.href = result.checkoutUrl;
      }
    }
    setCheckoutLoader(false);
  };

  const renderLineItems = () => {
    let payload = [];
    productData.forEach((product) => {
      const formattedPrice = Math.round(product.productData.price) / 100;
      const totalPrice =
        Math.round(product.productData.price * product.quantity) / 100;
      payload.push(
        <tr>
          <td>{product.productData.name}</td>
          <td>{product.quantity}</td>
          <td>{`$${formattedPrice}`}</td>
          <td>{`$${totalPrice}`}</td>
          <td>
            <button
              onClick={() => {
                CartController.removeFromCart(product.productData._id);
                parseCart();
              }}
            >
              Delete
            </button>
          </td>
        </tr>
      );
    });
    return payload;
  };

  if (loaded) {
    return (
      <section className="storeCheckout">
        <div className="top">
          <h3>Checkout</h3>
          <p>
            An account is required for checkout. If you are not signed in,
            please sign in. If you do not have an account, you will be asked to
            create one after clicking continue to checkout. In your account you
            can find order information and view and modify your subscriptions,
            as well as schedule services at Flex Factory.
          </p>
        </div>
        <div className="rows">
          <div className="left">
            {itemsLoader ? (
              <MicroLoader />
            ) : (
              <table>
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total Price</th>
                </tr>
                {productData ? renderLineItems() : null}
              </table>
            )}
          </div>
          <div className="right">
            <p>
              <strong>Quantity: </strong>
              {itemsLoader ? <MicroLoader /> : pricingData.quantity}
            </p>
            <p>
              <strong>Subtotal: </strong>
              {itemsLoader ? <MicroLoader /> : pricingData.subtotal}
            </p>
            <p>
              <strong>Tax: </strong>
              {itemsLoader ? <MicroLoader /> : pricingData.tax}
            </p>
            <p>
              <strong>Total Cost: </strong>
              {itemsLoader ? <MicroLoader /> : pricingData.total}
            </p>
            <label htmlFor="subscription">
              Make this purchase a monthly subscription?
            </label>
            <select name="subscription" id="subscription">
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
            <p>
              By selecting yes, you agree to monthly billing. An order will be
              automatically created for you each month and shipped out until you
              cancel.
            </p>
            <p>
              You will be able to enter a promo code at the next step if you
              have one.
            </p>
            <button onClick={sendToCheckout}>
              {checkoutLoader ? <MicroLoader /> : "Continue to Checkout"}
            </button>
            <button
              onClick={() => {
                nav("/store");
              }}
            >
              Continue Shopping
            </button>
            <button
              onClick={() => {
                CartController.emptyCart();
                parseCart();
              }}
            >
              Clear Cart
            </button>
          </div>
        </div>
      </section>
    );
  } else return <PageLoader />;
};

export default StoreCheckout;
