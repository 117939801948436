import React, { useEffect, useState } from "react";
import PageLoader from "../../parts/PageLoader";
import OrderDropdown from "../../parts/OrderDropdown";
import MicroLoader from "../../parts/MicroLoader";
import OrderBridge from "../../../bridges/OrderBridge";
import SubscriptionBridge from "../../../bridges/SubscriptionBridge";
import SubscriptionDropdown from "../../parts/SubscriptionDropdown";

const OrdersFulfilment = () => {
  const [loaded, setLoaded] = useState(false);
  const [ordersLoader, setOrdersLoader] = useState(false);
  const [subscriptionRenewalsLoader, setSubscriptionRenewalsLoader] =
    useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [subscriptionRenewals, setSubscriptionRenewals] = useState([]);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    setLoaded(false);
    await getOpenOrders();
    await getSubscriptionRenewals();
    setLoaded(true);
  };

  const getOpenOrders = async () => {
    setOrdersLoader(true);
    const response = await OrderBridge.getOpenOrders();
    const result = await response.json();
    console.log(result.data);
    setOpenOrders(result.data);
    setOrdersLoader(false);
  };

  const getSubscriptionRenewals = async () => {
    setSubscriptionRenewalsLoader(true);
    const response = await SubscriptionBridge.getSubscriptionRenewals();
    const result = await response.json();
    console.log(result.data);
    setSubscriptionRenewals(result.data);
    setSubscriptionRenewalsLoader(false);
  };

  const renderSubscriptionRenewals = () => {
    let payload = [];
    subscriptionRenewals.forEach((sub) => {
      payload.push(
        <SubscriptionDropdown
          lineItems={sub.lineItems}
          clientName={sub.clientName}
          subscriptionId={sub._id}
          callback={pageLoad}
        />
      );
    });
    return payload;
  };

  const renderOpenOrders = () => {
    let payload = [];
    openOrders.forEach((order) => {
      payload.push(
        <OrderDropdown
          lineItems={order.lineItems}
          clientName={order.clientName}
          orderId={order._id}
          callback={pageLoad}
          inStore={order.inStorePickup}
        />
      );
    });
    return payload;
  };

  if (loaded) {
    return (
      <section className="ordersFulfillment">
        <h1>Order Fulfillment</h1>
        <p>
          Use this module to see all of your orders that you need to fulfill.
          These may be either one time orders, or orders automatically created
          by a subscription plan.
        </p>
        <p>
          In order to fulfill an order, you must gather the items, box them, and
          generate a shipping label. When your carrier arrives, give them the
          parcel. You do not need to update inventory as this is already done
          when someone places an order.
        </p>
        <p>
          Subscriptions are configured so that when they are about to renew, you
          will see them here. Fulfillment for a subscription is the same as for
          an order, except you must create the order. This is done by clicking
          'Create Order'. Once the order is created, you will see the order in
          'Outgoing Orders'. Process this order as typical. You do not need to
          charge the client, either. This is automatically handled by your
          payment processor. Do not forget to fulfill subscriptions as your
          customers are billed monthly.
        </p>
        <h3>Outgoing Orders {`( ${openOrders.length} )`}</h3>
        <div className="outgoingOrders">
          {ordersLoader ? <MicroLoader /> : renderOpenOrders()}
        </div>
        <h3>Subscription Renewals {`( ${subscriptionRenewals.length} )`}</h3>
        <div className="subscriptionRenewals">
          {subscriptionRenewalsLoader ? (
            <MicroLoader />
          ) : (
            renderSubscriptionRenewals()
          )}
        </div>
      </section>
    );
  } else return <PageLoader />;
};

export default OrdersFulfilment;
