import React, { useEffect, useState } from "react";
import UserBridge from "../../bridges/UserBridge";
import PageLoader from "../parts/PageLoader";
import { useNavigate } from "react-router";

const AccountInfo = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await queryUser();
    setLoaded(true);
  };


  const queryUser = async () => {
    const response = await UserBridge.readCurrentUser();
    const result = await response.json();
    if (result.error === false) {
      setCurrentUser(result.data);
    } else {
      nav("/login");
    }
    setLoaded(true);
  };

  const changeEmail = async () => {
    console.log(`changing email`);
  };

  const changePassword = async () => {
    console.log(`changing password`);
  };

  if (loaded && currentUser) {
    return (
      <section className="accountInfo">
        <h1>Account</h1>
        <p>
          Here you may view and update your account information and password.
        </p>
        <div className="rowWrapper">
          <strong>First Name:</strong>
          <p>{currentUser.fname}</p>
        </div>
        <div className="rowWrapper">
          <strong>Last Name:</strong>
          <p>{currentUser.lname}</p>
        </div>
        <div className="rowWrapper">
          <strong>Email:</strong>
          <p>{currentUser.email}</p>
        </div>
        <div className="rowWrapper">
          <strong>Phone:</strong>
          <p>{currentUser.phone}</p>
        </div>
        <div className="rowWrapper">
          <strong>Shipping Address:</strong>
          <p>{currentUser.address}</p>
        </div>
        <h3>Update Email</h3>
        <input
          id="oldEmail"
          type="email"
          placeholder="enter your current email"
        />
        <input id="newEmail1" type="email" placeholder="enter your new email" />
        <input
          id="newEmail2"
          type="email"
          placeholder="confirm your current email"
        />
        <button onClick={changeEmail}>Update Email</button>
        <h3>Update Password</h3>
        <input
          id="oldPass"
          type="password"
          placeholder="enter your current password"
        />
        <input
          id="newPass1"
          type="password"
          placeholder="enter your new password"
        />
        <input
          id="newPass2"
          type="password"
          placeholder="confirm your current password"
        />
        <button onClick={changePassword}>Update Password</button>
      </section>
    );
  } else return <PageLoader />;
};

export default AccountInfo;
