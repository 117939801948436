export default class OrderBridge {
  static async initiateCheckout(cartData, subscription) {
    try {
      let response = await fetch("/api/orders/initiatecheckout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartData: cartData,
          subscription: subscription,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async completeCheckout(orderId) {
    try {
      let response = await fetch("/api/orders/completecheckout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: orderId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getCurrentOrders() {
    try {
      let response = await fetch("/api/orders/getcurrent", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getOpenOrders() {
    try {
      let response = await fetch("/api/orders/getopen", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async shipOrder(height, length, width, weight, orderId) {
    try {
      let response = await fetch("/api/orders/ship", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          height: height,
          length: length,
          width: width,
          weight: weight,
          orderId: orderId
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async fulfillOrder(orderId) {
    try {
      let response = await fetch("/api/orders/fulfill", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: orderId
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async reproduceOrder(subscriptionId) {
    try {
      let response = await fetch("/api/orders/reproduce", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: subscriptionId
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
