import React, { useEffect, useState } from "react";
import MicroLoader from "../../parts/MicroLoader";
import PageLoader from "../../parts/PageLoader";
import UserBridge from "../../../bridges/UserBridge";
import ServiceBridge from "../../../bridges/ServiceBridge";

const ServicesManage = () => {
  const [loaded, setLoaded] = useState(false);
  const [createServiceLoader, setCreateServiceLoader] = useState(false);
  const [updateServiceLoader, setUpdateServiceLoader] = useState(false);
  const [removeServiceLoader, setRemoveServiceLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [services, setServices] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    Promise.all([fetchServices(), fetchServiceProviders()]).then(() => {
      setLoaded(true);
    });
  };

  const fetchService = async (_id) => {
    setEditLoader(true);
    const response = await ServiceBridge.getService(_id);
    const result = await response.json();
    console.log(result.data);
    setSelectedService(result.data);
    setEditLoader(false);
    return true;
  };

  const fetchServices = async () => {
    const response = await ServiceBridge.getServices();
    const result = await response.json();
    setServices(result.data);
    console.log(result.data);
    return true;
  };

  const fetchServiceProviders = async () => {
    const response = await UserBridge.readServiceProviders();
    const result = await response.json();
    setServiceProviders(result.data);
    console.log(result.data);
    return true;
  };

  const populateServiceProviderSelect = () => {
    const payload = [];
    serviceProviders.forEach((provider) => {
      payload.push(
        <option value={provider._id}>
          {provider.fname} {provider.lname}
        </option>
      );
    });
    return payload;
  };

  const populateServicesSelect = () => {
    const payload = [];
    services.forEach((service) => {
      payload.push(<option value={service._id}>{service.name}</option>);
    });
    return payload;
  };

  const createService = async () => {
    setCreateServiceLoader(true);
    const formData = new FormData();
    formData.append("name", document.getElementById("name").value);
    formData.append(
      "description",
      document.getElementById("description").value
    );
    formData.append("price1", document.getElementById("price1").value);
    formData.append("price3", document.getElementById("price3").value);
    formData.append("price5", document.getElementById("price5").value);
    formData.append("price12", document.getElementById("price12").value);
    formData.append(
      "serviceProviderId",
      document.getElementById("serviceProviderId").value
    );
    formData.append(
      "scheduleViewLink",
      document.getElementById("scheduleViewLink").value
    );
    formData.append(
      "bookingLink",
      document.getElementById("bookingLink").value
    );
    const photoSelect = document.getElementById("photos");
    for (let i = 0; i < photoSelect.files.length; i++) {
      formData.append("photos", photoSelect.files[i]);
    }
    const response = await ServiceBridge.createService(formData);
    const result = await response.json();
    alert(result.msg);
    await fetchServices();
    setSelectedService(null);
    setCreateServiceLoader(false);
  };

  const updateService = async () => {
    setUpdateServiceLoader(true);
    setEditLoader(true);
    const formData = new FormData();
    formData.append("_id", selectedService._id);
    formData.append("name", document.getElementById("newname").value);
    formData.append(
      "description",
      document.getElementById("newdescription").value
    );
    formData.append("price", document.getElementById("newprice").value);
    formData.append("price3", document.getElementById("newprice3").value);
    formData.append("price5", document.getElementById("newprice5").value);
    formData.append("price12", document.getElementById("newprice12").value);

    formData.append(
      "serviceProviderId",
      document.getElementById("newserviceProviderId").value
    );
    formData.append(
      "scheduleViewLink",
      document.getElementById("newscheduleViewLink").value
    );
    formData.append(
      "bookingLink",
      document.getElementById("newbookingLink").value
    );
    const photoSelect = document.getElementById("newphotos");
    for (let i = 0; i < photoSelect.files.length; i++) {
      formData.append("photos", photoSelect.files[i]);
    }
    const response = await ServiceBridge.updateService(formData);
    const result = await response.json();
    alert(result.msg);
    document.getElementById("selectedService").value = "";
    await fetchServices();
    setSelectedService(null);
    setUpdateServiceLoader(false);
    setEditLoader(false);
  };

  const removeService = async () => {
    setRemoveServiceLoader(true);
    setEditLoader(true);
    const response = await ServiceBridge.deleteService(selectedService._id);
    const result = await response.json();
    alert(result.msg);
    document.getElementById("selectedService").value = "";
    await fetchServices();
    setSelectedService(null);
    setEditLoader(false);
    setRemoveServiceLoader(false);
  };

  const displayPhotos = () => {
    let payload = [];
    const photoList = selectedService.photos;
    if (Array.isArray(photoList)) {
      photoList.forEach((photo) => {
        const photoUrl = `/photos/${photo}`;
        payload.push(<img src={photoUrl} />);
      });
    }
    return payload;
  };

  if (loaded) {
    return (
      <section className="servicesManage">
        <h1>Manage Services</h1>
        <p>
          Create, view, update, and delete available services with this module.
        </p>
        <h3>Create Service</h3>
        <label htmlFor="name">Name</label>
        <input
          name="name"
          id="name"
          type="text"
          placeholder="enter service name"
        />
        <label htmlFor="description">Description</label>
        <input
          name="description"
          id="description"
          type="text"
          placeholder="enter a description for the service"
        />
        <label htmlFor="price">Unit Price</label>
        <input
          name="price1"
          id="price1"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price3">3-4 Price</label>
        <input
          name="price3"
          id="price3"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price5">5-11 price</label>
        <input
          name="price5"
          id="price5"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price12">12+ Price</label>
        <input
          name="price12"
          id="price12"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="serviceProviderId">Service Provider</label>
        <select name="serviceProviderId" id="serviceProviderId">
          <option>
            Please select a service provider to assign this service
          </option>
          {populateServiceProviderSelect()}
        </select>
        <label htmlFor="scheduleViewLink">Schedule View Link</label>
        <input
          name="scheduleViewLink"
          id="scheduleViewLink"
          type="text"
          placeholder="enter the link to view the schedule, but not to book"
        />
        <label htmlFor="bookingLink">Booking Link</label>
        <input
          name="bookingLink"
          id="bookingLink"
          type="text"
          placeholder="enter the booking link for the service"
        />
        <label htmlFor="photos">
          Photos of the Service (required, minimum 1 maximum 5)
        </label>
        <input name="photos" type="file" id="photos" multiple />
        <button onClick={createService}>
          {createServiceLoader ? <MicroLoader /> : `Create Service`}
        </button>
        <h3>View & Edit Service</h3>
        <select
          id="selectedService"
          onChange={(e) => {
            fetchService(e.target.value);
          }}
        >
          <option value="">SELECT A SERVICE TO EDIT</option>
          {populateServicesSelect()}
        </select>
        {selectedService ? (
          <>
            {editLoader ? (
              <MicroLoader />
            ) : (
              <>
                <label htmlFor="newname">Name</label>
                <input
                  name="newname"
                  id="newname"
                  type="text"
                  placeholder="enter service name"
                  defaultValue={selectedService.name}
                />
                <label htmlFor="newdescription">Description</label>
                <input
                  name="newdescription"
                  id="newdescription"
                  type="text"
                  placeholder="enter a description for the service"
                  defaultValue={selectedService.description}
                />
                <label htmlFor="newprice">Unit Price</label>
                <input
                  name="newprice1"
                  id="newprice1"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                />
                <label htmlFor="newprice3">3-4 Price</label>
                <input
                  name="pnewrice3"
                  id="newprice3"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                />
                <label htmlFor="newprice5">5-11 price</label>
                <input
                  name="newprice5"
                  id="newprice5"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                />
                <label htmlFor="newprice12">12+ Price</label>
                <input
                  name="newprice12"
                  id="newprice12"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                />
                <label htmlFor="newserviceProviderId">Service Provider</label>
                <select
                  name="newserviceProviderId"
                  id="newserviceProviderId"
                  defaultValue={selectedService.serviceProviderId}
                >
                  <option>SELECT A SERVICE PROVIDER</option>
                  {populateServiceProviderSelect()}
                </select>
                <label htmlFor="newscheduleViewLink">Schedule View Link</label>
                <input
                  name="newscheduleViewLink"
                  id="newscheduleViewLink"
                  type="text"
                  placeholder="enter the link to view the schedule, but not to book"
                  defaultValue={selectedService.scheduleViewLink}
                />
                <label htmlFor="newbookingLink">Booking Link</label>
                <input
                  name="newbookingLink"
                  id="newbookingLink"
                  type="text"
                  placeholder="enter the booking link for the service"
                  defaultValue={selectedService.bookingLink}
                />
                <label htmlFor="newphotos">
                  Photos of the Service (required, minimum 1 maximum 5)
                </label>
                <input name="newphotos" type="file" id="newphotos" multiple />
                <div className="oldPhotos">{displayPhotos()}</div>
                <button onClick={updateService}>
                  {updateServiceLoader ? <MicroLoader /> : `Update Service`}
                </button>
                <button onClick={removeService}>
                  {removeServiceLoader ? <MicroLoader /> : `Remove Service`}
                </button>
              </>
            )}
          </>
        ) : null}
      </section>
    );
  } else {
    return <PageLoader />;
  }
};

export default ServicesManage;
