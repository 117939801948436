import React, { useEffect, useState } from "react";
import MicroLoader from "../../parts/MicroLoader";
import PageLoader from "../../parts/PageLoader";
import UserBridge from "../../../bridges/UserBridge";
import ServiceBridge from "../../../bridges/ServiceBridge";
import PackageBridge from "../../../bridges/PackageBridge";

const ServicesPackage = () => {
  const [loaded, setLoaded] = useState(false);
  const [createPackageLoader, setCreatePackageLoader] = useState(false);
  const [updatePackageLoader, setUpdatePackageLoader] = useState(false);
  const [removePackageLoader, setRemovePackageLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [services, setServices] = useState([]);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await fetchPackages();
    await fetchServices();
    setLoaded(true);
  };

  const fetchPackage = async (_id) => {
    setEditLoader(true);
    const response = await PackageBridge.getPackage(_id);
    const result = await response.json();
    console.log(result.data);
    setSelectedPackage(result.data);
    setEditLoader(false);
  };

  const fetchPackages = async () => {
    const response = await PackageBridge.getPackages();
    const result = await response.json();
    setPackages(result.data);
  };

  const fetchServices = async () => {
    const response = await ServiceBridge.getServices();
    const result = await response.json();
    setServices(result.data);
  };

  const populateServicesSelect = () => {
    const payload = [];
    services.forEach((service) => {
      payload.push(<option value={service._id}>{service.name}</option>);
    });
    return payload;
  };

  const populatePackagesSelect = () => {
    const payload = [];
    packages.forEach((localPackage) => {
      console.log(localPackage);
      payload.push(
        <option value={localPackage._id}>{localPackage.name}</option>
      );
    });
    return payload;
  };

  function getSelectedValues(selectElement) {
    const selectedValues = [];
    const options = selectElement && selectElement.options;

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    return selectedValues;
  }

  const createPackage = async () => {
    setCreatePackageLoader(true);
    const multiSelect = document.getElementById("servicesIncluded");
    const selectedServices = getSelectedValues(multiSelect);
    const formData = new FormData();
    formData.append("name", document.getElementById("name").value);
    formData.append(
      "description",
      document.getElementById("description").value
    );
    formData.append("price1", document.getElementById("price1").value);
    formData.append("price3", document.getElementById("price3").value);
    formData.append("price5", document.getElementById("price5").value);
    formData.append("price12", document.getElementById("price12").value);
    formData.append("price11", document.getElementById("price11").value);
    formData.append("price22", document.getElementById("price22").value);
    formData.append("price44", document.getElementById("price44").value);
    formData.append("price31", document.getElementById("price31").value);
    formData.append("price32", document.getElementById("price32").value);
    formData.append("unlimited", document.getElementById("unlimited").value);
    formData.append(
      "unlimitedHyper",
      document.getElementById("unlimitedHyper").value
    );
    formData.append(
      "unlimitedRed",
      document.getElementById("unlimitedRed").value
    );
    formData.append("services", selectedServices);
    const response = await PackageBridge.createPackage(formData);
    const result = await response.json();
    alert(result.msg);
    await fetchPackages();
    setSelectedPackage(null);
    setCreatePackageLoader(false);
  };

  const updatePackage = async () => {
    setUpdatePackageLoader(true);
    setEditLoader(true);
    const selectedServices = getSelectedValues(multiSelect);
    const formData = new FormData();
    formData.append("_id", selectedPackage._id);
    formData.append("name", document.getElementById("name").value);
    formData.append(
      "description",
      document.getElementById("description").value
    );
    formData.append("price1", document.getElementById("newprice1").value);
    formData.append("price3", document.getElementById("newprice3").value);
    formData.append("price5", document.getElementById("newprice5").value);
    formData.append("price12", document.getElementById("newprice12").value);
    formData.append("price11", document.getElementById("newprice11").value);
    formData.append("price22", document.getElementById("newprice22").value);
    formData.append("price44", document.getElementById("newprice44").value);
    formData.append("price31", document.getElementById("newprice31").value);
    formData.append("price32", document.getElementById("newprice32").value);
    formData.append("unlimited", document.getElementById("newunlimited").value);
    formData.append(
      "unlimitedHyper",
      document.getElementById("newunlimitedHyper").value
    );
    formData.append(
      "unlimitedRed",
      document.getElementById("newunlimitedRed").value
    );

    formData.append("services", selectedServices);
    const response = await PackageBridge.updatePackage(formData);
    const result = await response.json();
    alert(result.msg);
    document.getElementById("selectedPackage").value = "";
    await fetchPackages();
    setSelectedPackage(null);
    setUpdatePackageLoader(false);
    setEditLoader(false);
  };

  const removePackage = async () => {
    setRemovePackageLoader(true);
    setEditLoader(true);
    const response = await ServiceBridge.deleteService(selectedService._id);
    const result = await response.json();
    alert(result.msg);
    document.getElementById("selectedPackage").value = "";
    await fetchServices();
    setSelectedPackage(null);
    setEditLoader(false);
    setRemovePackageLoader(false);
  };

  if (loaded) {
    return (
      <section className="servicesManage">
        <h1>Manage Packages</h1>
        <p>
          Create, view, update, and delete available service packages with this
          module.
        </p>
        <h3>Create Package</h3>
        <label htmlFor="name">Name</label>
        <input
          name="name"
          id="name"
          type="text"
          placeholder="enter service name"
        />
        <label htmlFor="description">Description</label>
        <input
          name="description"
          id="description"
          type="text"
          placeholder="enter a description for the service"
        />
        <label htmlFor="price1">1 Price</label>
        <input
          name="price1"
          id="price1"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price3">3 Price</label>
        <input
          name="price3"
          id="price3"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price5">5 price</label>
        <input
          name="price5"
          id="price5"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price12">12 price</label>
        <input
          name="price12"
          id="price12"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price11">1+1 price</label>
        <input
          name="price11"
          id="price11"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price22">2+2 price</label>
        <input
          name="price22"
          id="price22"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price44">4+4 price</label>
        <input
          name="price44"
          id="price44"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price31">Price Enum 31</label>
        <input
          name="price31"
          id="price31"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="price32">Price Enum 32</label>
        <input
          name="price32"
          id="price32"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="unlimited">Unlimited Price</label>
        <input
          name="unlimited"
          id="unlimited"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="unlimitedHyper">Unlimited Hyper Price</label>
        <input
          name="unlimitedHyper"
          id="unlimitedHyper"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="unlimitedRed">Unlimited Red Price</label>
        <input
          name="unlimitedRed"
          id="unlimitedRed"
          type="number"
          placeholder="enter the service price in cents i.e. 20$ is 2000"
        />
        <label htmlFor="servicesIncluded">Services Included</label>
        <select id="servicesIncluded" name="servicesIncluded" multiple={true}>
          {populateServicesSelect()}
        </select>
        <button onClick={createPackage}>
          {createPackageLoader ? <MicroLoader /> : `Create Package`}
        </button>
        <h3>View & Edit Service</h3>
        <select
          id="selectedPackage"
          onChange={(e) => {
            fetchPackage(e.target.value);
          }}
        >
          <option value="">SELECT A PACKAGE TO EDIT</option>
          {populatePackagesSelect()}
        </select>
        {selectedPackage ? (
          <>
            {editLoader ? (
              <MicroLoader />
            ) : (
              <>
                <label htmlFor="newname">Name</label>
                <input
                  name="newname"
                  id="newname"
                  type="text"
                  placeholder="enter service name"
                  defaultValue={selectedPackage.name}
                />
                <label htmlFor="newdescription">Description</label>
                <input
                  name="newdescription"
                  id="newdescription"
                  type="text"
                  placeholder="enter a description for the service"
                  defaultValue={selectedPackage.description}
                />
                <label htmlFor="newprice1">1 Price</label>
                <input
                  name="newprice1"
                  id="newprice1"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.price1}
                />
                <label htmlFor="newprice3">3 Price</label>
                <input
                  name="newprice3"
                  id="newprice3"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.price3}
                />
                <label htmlFor="newprice5">5 price</label>
                <input
                  name="newprice5"
                  id="newprice5"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.price5}
                />
                <label htmlFor="newprice12">12 price</label>
                <input
                  name="newprice12"
                  id="newprice12"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.price12}
                />
                <label htmlFor="newprice11">1+1 price</label>
                <input
                  name="newprice11"
                  id="newprice11"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.subPrice1}
                />
                <label htmlFor="newprice22">2+2 price</label>
                <input
                  name="newprice22"
                  id="newprice22"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.subPrice2}
                />
                <label htmlFor="newprice44">4+4 price</label>
                <input
                  name="newprice44"
                  id="newprice44"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.subPrice4}
                />
                <label htmlFor="newprice31">Enum 31 Price</label>
                <input
                  name="newprice31"
                  id="newprice31"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.price31}
                />
                <label htmlFor="newprice32">Enum 32 Price</label>
                <input
                  name="newprice32"
                  id="newprice32"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={selectedPackage.price12}
                />
                <label htmlFor="newunlimited">Unlimited Price</label>
                <input
                  name="newunlimited"
                  id="newunlimited"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={
                    !!selectedPackage.unlimitedPrice
                      ? selectedPackage.unlimitedPrice
                      : 0
                  }
                />
                <label htmlFor="newunlimitedHyper">Unlimited Hyper Price</label>
                <input
                  name="newunlimitedHyper"
                  id="newunlimitedHyper"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={
                    !!selectedPackage.unlimitedHyperPrice
                      ? selectedPackage.unlimitedHyperPrice
                      : 0
                  }
                />
                <label htmlFor="newunlimitedRed">Unlimited Red Price</label>
                <input
                  name="newunlimitedRed"
                  id="newunlimitedRed"
                  type="number"
                  placeholder="enter the service price in cents i.e. 20$ is 2000"
                  defaultValue={
                    !!selectedPackage.unlimitedRedPrice
                      ? selectedPackage.unlimitedRedPrice
                      : 0
                  }
                />
                <label htmlFor="newservicesIncluded">Services Included</label>
                <select
                  id="newservicesIncluded"
                  name="newservicesIncluded"
                  multiple={true}
                  defaultValue={selectedPackage.services}
                >
                  {populateServicesSelect()}
                </select>
                <button onClick={updatePackage}>
                  {updatePackageLoader ? <MicroLoader /> : `Update Package`}
                </button>
                <button onClick={removePackage}>
                  {removePackageLoader ? <MicroLoader /> : `Remove Package`}
                </button>
              </>
            )}
          </>
        ) : null}
      </section>
    );
  } else {
    return <PageLoader />;
  }
};

export default ServicesPackage;
