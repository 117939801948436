import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MicroLoader from "../../parts/MicroLoader";
import PageLoader from "../../parts/PageLoader";
import UserBridge from "../../../bridges/UserBridge";
import ServiceBridge from "../../../bridges/ServiceBridge";
import CheckinBridge from "../../../bridges/CheckinBridge";

const PosCheckin = () => {
  const [loaded, setLoaded] = useState(false);
  const [usersLoader, setUsersLoader] = useState(false);
  const [createClientLoader, setCreateClientLoader] = useState(false);
  const [checkinLoader, setCheckinLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [userList, setUserList] = useState([]);
  const [services, setServices] = useState([]);

  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getUsers();
    await fetchServices();
    setLoaded(true);
  };

  const getUsers = async () => {
    setUsersLoader(true);
    const response = await UserBridge.readUserList();
    const result = await response.json();
    if (result.error === true) {
      nav("/login");
    } else {
      const { data } = result;
      setUserList(data);
    }
    setUsersLoader(false);
  };

  const fetchServices = async () => {
    const response = await ServiceBridge.getServices();
    const result = await response.json();
    setServices(result.data);
    console.log(result.data);
    return true;
  };

  const populateServicesSelect = () => {
    const payload = [];
    services.forEach((service) => {
      payload.push(<option value={service._id}>{service.name}</option>);
    });
    return payload;
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  const populateClientSelectBox = () => {
    let payload = [];
    userList.forEach((client) => {
      if (client.authLevel < 1) {
        payload.push(genTag(client.fname, client.lname, client._id));
      }
    });
    return payload;
  };

  const createClient = async () => {
    setCreateClientLoader(true);
    const response = await UserBridge.createUser(
      0,
      null,
      document.getElementById(`fname`).value,
      document.getElementById(`lname`).value,
      document.getElementById(`email`).value,
      document.getElementById(`phone`).value
    );
    const result = await response.json();
    if (result.error) {
      alert(`There was an error, please try again.`);
      setCreateClientLoader(false);
      setUsersLoader(false);
    } else {
      getUsers();
      alert(`Success!`);
      setCreateClientLoader(false);
    }
  };

  const checkin = async () => {
    setCheckinLoader(true);
    const response = await CheckinBridge.checkin(selectedUser, selectedService);
    const result = await response.json();
    alert(result.msg);
    setCheckinLoader(false);
  };

  if (loaded) {
    return (
      <div className="posCheckin">
        <h1>Service Check In</h1>
        <strong
          className="breadcrumb"
          onClick={() => {
            nav("/v1/crm/pos");
          }}
        >
          &larr; Return to POS
        </strong>
        <h3>Step 1: Create a new Client if they dont have an account</h3>
        <p>The default username is their email, and password is getfit</p>
        <label htmlFor="fname">First Name</label>
        <input
          name="fname"
          id="fname"
          type="fname"
          placeholder="enter client first name"
        />
        <label htmlFor="lname">Last Name</label>
        <input
          name="lname"
          id="lname"
          type="lname"
          placeholder="enter client last name"
        />
        <label htmlFor="email">Email</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="enter client email"
        />
        <label htmlFor="phone">Phone</label>
        <input
          name="phone"
          id="phone"
          type="phone"
          placeholder="enter client phone"
        />
        <button onClick={createClient}>
          {createClientLoader ? <MicroLoader /> : `Create Client`}
        </button>

        <h3>Step 2: Select the Client</h3>
        {usersLoader ? (
          <MicroLoader />
        ) : (
          <select
            id="selectedClient"
            onChange={(e) => {
              setSelectedUser(e.target.value);
            }}
          >
            <option value="">SELECT A CLIENT TO EDIT</option>
            {populateClientSelectBox()}
          </select>
        )}

        <h3>Step 3: Select the Service</h3>
        <select
          id="selectedService"
          onChange={(e) => {
            setSelectedService(e.target.value);
          }}
        >
          <option value="">SELECT A SERVICE TO EDIT</option>
          {populateServicesSelect()}
        </select>
        <button onClick={checkin}>
          {checkinLoader ? <MicroLoader /> : "Check In"}
        </button>
      </div>
    );
  } else return <PageLoader />;
};

export default PosCheckin;
