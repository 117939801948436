export default class ServiceBridge {
  static async createService(data) {
    try {
      let response = await fetch("/api/services/create", {
        method: "POST",
        credentials: "include",
        body: data,
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getService(_id) {
    try {
      let response = await fetch("/api/services/read", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: _id }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getBookingLink(_id) {
    try {
      let response = await fetch("/api/services/getbookinglink", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: _id }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }



  static async getServices() {
    try {
      let response = await fetch("/api/services/read", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async deleteService(_id) {
    try {
      let response = await fetch("/api/services/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateService(formData) {
    try {
      let response = await fetch("/api/services/update", {
        method: "POST",
        credentials: "include",
        body: formData,
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
