import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import UserBridge from "../../bridges/UserBridge";
import Loading from "../parts/Loading";
import { Link } from "react-router-dom";

const CrmIndex = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await queryUser();
    setLoaded(true);
  };

  const queryUser = async () => {
    const response = await UserBridge.readCurrentUser();
    const result = await response.json();
    if (result.error || response.status != 200) {
      nav("/login");
    } else {
      setCurrentUser(result.data);
    }
    setLoaded(true);
  };

  const renderDashboard = () => {
    switch (currentUser.authLevel) {
      case "0":
        nav("/login");
        break;
      case "1":
        return serviceProviderMenu();
      case "2":
        return employeeMenu();
      case "3":
        return managerMenu();
      case "4":
        return masterMenu();
    }
  };

  const serviceProviderMenu = () => {
    return (
      <>

        <h3>Services</h3>
        <div>
          <Link to="/v1/crm/services/scheduling">Scheduling</Link>
        </div>
        <h3>Pos</h3>
        <div>
          <Link to="/v1/crm/pos/checkin">Service Checkins</Link>
        </div>
        <h3>Help</h3>
        <div>
          <Link to="/v1/crm/help">Help Docs</Link>
        </div>
      </>
    );
  };

  const employeeMenu = () => {
    return (
      <>

        <h3>Accounts</h3>
        <div>
          <Link to="/v1/crm/accounts/clients">Clients</Link>
        </div>
        <h3>Orders</h3>
        <div>
          <Link to="/v1/crm/orders/fulfillment">Fulfillment</Link>
          <Link to="/v1/crm/orders/history">History</Link>
        </div>
        <h3>Services</h3>
        <div>
          <Link to="/v1/crm/services/scheduling">Scheduling</Link>
          <Link to="/v1/crm/services/history">History</Link>
        </div>
        <h3>Products</h3>
        <div>
          <Link to="/v1/crm/products/inventory">Inventory</Link>
          <Link to="/v1/crm/products/stocking">Stocking</Link>
        </div>
        <h3>Pos</h3>
        <div>
          <Link to="/v1/crm/pos">Point of Sale</Link>
        </div>
        <h3>Help</h3>
        <div>
          <Link to="/v1/crm/help">Help Docs</Link>
        </div>
      </>
    );
  };

  const managerMenu = () => {
    return (
      <>

        <h3>Accounts</h3>
        <div>
          <Link to="/v1/crm/accounts/clients">Clients</Link>
          <Link to="/v1/crm/accounts/employees">Employees</Link>
          <Link to="/v1/crm/accounts/timesheet">Timesheet</Link>
        </div>
        <h3>Orders</h3>
        <div>
          <Link to="/v1/crm/orders/fulfillment">Fulfillment</Link>
          <Link to="/v1/crm/orders/history">History</Link>
        </div>
        <h3>Services</h3>
        <div>
          <Link to="/v1/crm/services/scheduling">Scheduling</Link>
          <Link to="/v1/crm/services/ticketing">Ticketing</Link>
          <Link to="/v1/crm/services/history">History</Link>
        </div>
        <h3>Products</h3>
        <div>
          <Link to="/v1/crm/products/manage">Manage Products</Link>
          <Link to="/v1/crm/products/inventory">Inventory</Link>
          <Link to="/v1/crm/products/stocking">Stocking</Link>
        </div>
        <h3>Pos</h3>
        <div>
          <Link to="/v1/crm/pos">Point of Sale</Link>
        </div>
        <h3>Help</h3>
        <div>
          <Link to="/v1/crm/help">Help Docs</Link>
        </div>
      </>
    );
  };

  const masterMenu = () => {
    return (
      <>

        <h3>Accounts</h3>
        <div>
          <Link to="/v1/crm/accounts/clients">Clients</Link>
          <Link to="/v1/crm/accounts/employees">Employees</Link>
          <Link to="/v1/crm/accounts/managers">Managers</Link>
          <Link to="/v1/crm/accounts/serviceproviders">Service Providers</Link>
          <Link to="/v1/crm/accounts/timesheet">Timesheet</Link>
        </div>
        <h3>Orders</h3>
        <div>
          <Link to="/v1/crm/orders/fulfillment">Fulfillment</Link>
          <Link to="/v1/crm/orders/history">History</Link>
        </div>
        <h3>Services</h3>
        <div>
          <Link to="/v1/crm/services/manage">Manage Services</Link>
          <Link to="/v1/crm/services/packages">Manage Packages</Link>
          <Link to="/v1/crm/services/scheduling">Scheduling</Link>
          <Link to="/v1/crm/services/ticketing">Ticketing</Link>
          <Link to="/v1/crm/services/history">History</Link>
        </div>
        <h3>Products</h3>
        <div>
          <Link to="/v1/crm/products/manage">Manage Products</Link>
          <Link to="/v1/crm/products/inventory">Inventory</Link>
          <Link to="/v1/crm/products/stocking">Stocking</Link>
          <Link to="/v1/crm/products/promotions">Promotions</Link>
        </div>
        <h3>Pos</h3>
        <div>
          <Link to="/v1/crm/pos">Point of Sale</Link>
        </div>
        <h3>Help</h3>
        <div>
          <Link to="/v1/crm/help">Help Docs</Link>
        </div>
      </>
    );
  };

  if (loaded) {
    return (
      <section className="crmIndex">
        <h1>Welcome, {currentUser.fname}!</h1>
        <div className="masterMenu">{renderDashboard()}</div>
        <Outlet />
      </section>
    );
  } else return <Loading />;
};

export default CrmIndex;
