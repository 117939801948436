export default testProducts = [
  {
    _id: "c1d2e3f4g125h6i7j8k",
    name: "Resistance Bands",
    description:
      "Set of resistance bands for strength training and rehab exercises.",
    price: 2000,
    quantityInStock: 85,
    tags: ["resistance", "bands", "strength"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "l9m8n7o6p5q414444r3s2t",
    name: "Foam Roller",
    description: "High-density foam roller for massage and muscle recovery.",
    price: 3000,
    quantityInStock: 65,
    tags: ["foam", "roller", "recovery"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "k1l2m3n4o5p1246q7r8s",
    name: "Fitness Tracker",
    description:
      "Advanced fitness tracker for monitoring heart rate and calories.",
    price: 7000,
    quantityInStock: 60,
    tags: ["fitness", "tracker", "monitoring"],
    category: "electronics",
    type: 1,
    shippable: true,
  },
  {
    _id: "t9u8v7w1236x5y4z3a2b",
    name: "Yoga Mat",
    description: "Comfortable and durable yoga mat for all your yoga sessions.",
    price: 3500,
    quantityInStock: 75,
    tags: ["yoga", "mat", "comfortable"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "s1t2u3v4w5x6y7z8a",
    name: "Vegan Protein Powder",
    description: "Plant-based protein powder for muscle recovery and growth.",
    price: 6000,
    quantityInStock: 40,
    tags: ["vegan", "protein", "muscle"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "b9c8d7e6f5g4h3i2j",
    name: "Electrolyte Tablets",
    description: "Easy-to-use electrolyte tablets for quick hydration.",
    price: 3000,
    quantityInStock: 90,
    tags: ["electrolyte", "tablets", "hydration"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "a1b2c3d4123e5f6g7h8i",
    name: "Energy Bar",
    description: "Tasty energy bar packed with nutrients for sustained energy.",
    price: 2000,
    quantityInStock: 70,
    tags: ["energy", "bar", "nutrients"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "j9k8l7m6n5o4p3q2r",
    name: "Hydration Drink",
    description: "Refreshing hydration drink for replenishing electrolytes.",
    price: 1500,
    quantityInStock: 80,
    tags: ["hydration", "drink", "electrolytes"],
    category: "beverages",
    type: 1,
    shippable: true,
  },
  {
    _id: "5f4d263hjkl7890",
    name: "Protein Powder",
    description: "High-quality whey protein powder for muscle recovery.",
    price: 5000,
    quantityInStock: 50,
    tags: ["protein", "recovery", "muscle"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "6g5h4j3k2l1m987n",
    name: "Pre-workout Booster",
    description: "Powerful pre-workout booster for increased energy and focus.",
    price: 2500,
    quantityInStock: 30,
    tags: ["pre-workout", "energy", "focus"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "a1b2c3d4e5f6g7h812i",
    name: "Yoga Mat",
    description:
      "Non-slip yoga mat for balance and stability. Ideal for all types of yoga and floor exercises.",
    price: 2000,
    quantityInStock: 120,
    tags: ["yoga", "mat", "exercise", "non-slip", "balance", "stability"],
    category: "accessories",
    type: 1,
    shippable: true,
  },

  {
    _id: "j1k2l3m4n5o6p7q8r",
    name: "Resistance Bands",
    description:
      "Set of resistance bands for strength training. Comes in a variety of resistance levels for progressive strength development.",
    price: 1500,
    quantityInStock: 80,
    tags: [
      "resistance",
      "bands",
      "strength",
      "training",
      "progressive",
      "variety",
    ],
    category: "accessories",
    type: 1,
    shippable: true,
  },

  {
    _id: "s1t2u3v4w5x6y7z8a",
    name: "Jump Rope",
    description:
      "Speed jump rope for cardio workouts. Lightweight and adjustable for individual comfort and speed.",
    price: 1000,
    quantityInStock: 50,
    tags: ["jump", "rope", "cardio", "workouts", "lightweight", "adjustable"],
    category: "accessories",
    type: 1,
    shippable: true,
  },

  {
    _id: "b1c2d3e4f5g6h7i8j",
    name: "Kettlebell",
    description:
      "Quality cast iron kettlebell for full body workouts. Durable and comfortable grip for safe handling.",
    price: 3500,
    quantityInStock: 60,
    tags: [
      "kettlebell",
      "workouts",
      "cast iron",
      "durable",
      "comfortable grip",
    ],
    category: "equipment",
    type: 1,
    shippable: true,
  },
  {
    _id: "k1l2m3n4o5p6q7r8s",
    name: "Exercise Ball",
    description:
      "Versatile exercise ball for core stability exercises. Durable and burst-resistant design for safety.",
    price: 2500,
    quantityInStock: 75,
    tags: ["exercise ball", "core", "stability", "durable", "burst-resistant"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "c1d2e3f4g123123125h6i7j8k",
    name: "Resistance Bands",
    description:
      "Set of resistance bands for strength training and rehab exercises.",
    price: 2000,
    quantityInStock: 85,
    tags: ["resistance", "bands", "strength"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "l9m8n7o6p5q4edg3414444r3s2t",
    name: "Foam Roller",
    description: "High-density foam roller for massage and muscle recovery.",
    price: 3000,
    quantityInStock: 65,
    tags: ["foam", "roller", "recovery"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "k1l2m3n4o5fgdfgp1246q7r8s",
    name: "Fitness Tracker",
    description:
      "Advanced fitness tracker for monitoring heart rate and calories.",
    price: 7000,
    quantityInStock: 60,
    tags: ["fitness", "tracker", "monitoring"],
    category: "electronics",
    type: 1,
    shippable: true,
  },
  {
    _id: "t9u8v7w123dfg3rg6x5y4z3a2b",
    name: "Yoga Mat",
    description: "Comfortable and durable yoga mat for all your yoga sessions.",
    price: 3500,
    quantityInStock: 75,
    tags: ["yoga", "mat", "comfortable"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "s1t2u3v4w5ergegx6y7z8a",
    name: "Vegan Protein Powder",
    description: "Plant-based protein powder for muscle recovery and growth.",
    price: 6000,
    quantityInStock: 40,
    tags: ["vegan", "protein", "muscle"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "b9c8d7e6fergerr5g4h3i2j",
    name: "Electrolyte Tablets",
    description: "Easy-to-use electrolyte tablets for quick hydration.",
    price: 3000,
    quantityInStock: 90,
    tags: ["electrolyte", "tablets", "hydration"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "a1b2c3d41rrgfgd23e5f6g7h8i",
    name: "Energy Bar",
    description: "Tasty energy bar packed with nutrients for sustained energy.",
    price: 2000,
    quantityInStock: 70,
    tags: ["energy", "bar", "nutrients"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "j9k8l7m6nfgfdgrg5o4p3q2r",
    name: "Hydration Drink",
    description: "Refreshing hydration drink for replenishing electrolytes.",
    price: 1500,
    quantityInStock: 80,
    tags: ["hydration", "drink", "electrolytes"],
    category: "beverages",
    type: 1,
    shippable: true,
  },
  {
    _id: "5f4d263hjkrgfdfgdfgl7890",
    name: "Protein Powder",
    description: "High-quality whey protein powder for muscle recovery.",
    price: 5000,
    quantityInStock: 50,
    tags: ["protein", "recovery", "muscle"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "6g5h4j3k2rgdrfgergel1m987n",
    name: "Pre-workout Booster",
    description: "Powerful pre-workout booster for increased energy and focus.",
    price: 2500,
    quantityInStock: 30,
    tags: ["pre-workout", "energy", "focus"],
    category: "supplements",
    type: 1,
    shippable: true,
  },
  {
    _id: "a1b2c3d4e5f6dfgdfgdrrg7h812i",
    name: "Yoga Mat",
    description:
      "Non-slip yoga mat for balance and stability. Ideal for all types of yoga and floor exercises.",
    price: 2000,
    quantityInStock: 120,
    tags: ["yoga", "mat", "exercise", "non-slip", "balance", "stability"],
    category: "accessories",
    type: 1,
    shippable: true,
  },

  {
    _id: "j1k2l3m4ffggdfgrrgn5o6p7q8r",
    name: "Resistance Bands",
    description:
      "Set of resistance bands for strength training. Comes in a variety of resistance levels for progressive strength development.",
    price: 1500,
    quantityInStock: 80,
    tags: [
      "resistance",
      "bands",
      "strength",
      "training",
      "progressive",
      "variety",
    ],
    category: "accessories",
    type: 1,
    shippable: true,
  },

  {
    _id: "s1t2u3vfffgdgdgrr4w5x6y7z8a",
    name: "Jump Rope",
    description:
      "Speed jump rope for cardio workouts. Lightweight and adjustable for individual comfort and speed.",
    price: 1000,
    quantityInStock: 50,
    tags: ["jump", "rope", "cardio", "workouts", "lightweight", "adjustable"],
    category: "accessories",
    type: 1,
    shippable: true,
  },

  {
    _id: "b1c2d3e4fffdgr5gerfg5g6h7i8j",
    name: "Kettlebell",
    description:
      "Quality cast iron kettlebell for full body workouts. Durable and comfortable grip for safe handling.",
    price: 3500,
    quantityInStock: 60,
    tags: [
      "kettlebell",
      "workouts",
      "cast iron",
      "durable",
      "comfortable grip",
    ],
    category: "equipment",
    type: 1,
    shippable: true,
  },
  {
    _id: "k1l2m3n4fgehtyjtjo5p6q7r8s",
    name: "Exercise Ball",
    description:
      "Versatile exercise ball for core stability exercises. Durable and burst-resistant design for safety.",
    price: 2500,
    quantityInStock: 75,
    tags: ["exercise ball", "core", "stability", "durable", "burst-resistant"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
  {
    _id: "s1t2u3vfffgdgdddgrr4w5x6y7z8a",
    name: "Jump Rope",
    description:
      "Speed jump rope for cardio workouts. Lightweight and adjustable for individual comfort and speed.",
    price: 1000,
    quantityInStock: 50,
    tags: ["jump", "rope", "cardio", "workouts", "lightweight", "adjustable"],
    category: "accessories",
    type: 1,
    shippable: true,
  },

  {
    _id: "b1c2d3e4fffdgr5gerssfg5g6h7i8j",
    name: "Kettlebell",
    description:
      "Quality cast iron kettlebell for full body workouts. Durable and comfortable grip for safe handling.",
    price: 3500,
    quantityInStock: 60,
    tags: [
      "kettlebell",
      "workouts",
      "cast iron",
      "durable",
      "comfortable grip",
    ],
    category: "equipment",
    type: 1,
    shippable: true,
  },
  {
    _id: "k1l2m3n4fgehtyjtjaao5p6q7r8s",
    name: "Exercise Ball",
    description:
      "Versatile exercise ball for core stability exercises. Durable and burst-resistant design for safety.",
    price: 2500,
    quantityInStock: 75,
    tags: ["exercise ball", "core", "stability", "durable", "burst-resistant"],
    category: "accessories",
    type: 1,
    shippable: true,
  },
];
