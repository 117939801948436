export default class PackageBridge {
  static async createPackage(formData) {
    try {
      let response = await fetch("/api/packages/create", {
        method: "POST",
        credentials: "include",
        body: formData,
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getPackages() {
    try {
      let response = await fetch("/api/packages/read", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getPackage(_id) {
    try {
      let response = await fetch("/api/packages/readone", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({_id: _id}),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async deletePackage(_id) {
    try {
      let response = await fetch("/api/packages/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updatePackage(formData) {
    try {
      let response = await fetch("/api/packages/update", {
        method: "POST",
        credentials: "include",
        body: formData,
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
