import React, { useEffect, useState } from "react";
import PageLoader from "../../parts/PageLoader";
import UserBridge from "../../../bridges/UserBridge";
import ServiceBridge from "../../../bridges/ServiceBridge";
import MicroLoader from "../../parts/MicroLoader";

const ServicesTicketing = () => {
  const [loaded, setLoaded] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    const data = await Promise.all([getUserList(), getServicesList()]);
    setUserList(data[0]);
    setServiceList(data[1]);
    console.log(data);
    setLoaded(true);
  };

  const getUserList = async () => {
    const response1 = await UserBridge.readUserList();
    const result1 = await response1.json();
    return result1.data;
  };

  const getServicesList = async () => {
    const response2 = await ServiceBridge.getServices();
    const result2 = await response2.json();
    return result2.data;
  };

  const getUserServices = async (_id) => {
    setViewLoader(true);
    const response = await UserBridge.readUserServices(_id);
    const result = await response.json();
    console.log(result.data);
    setUserData(result.data);
    setViewLoader(false);
  };

  const selectUser = async (_id) => {
    setSelectedUser(_id);
    getUserServices(_id);
  };

  const addTickets = async () => {
    setAddLoader(true);
    const response = await UserBridge.addServiceTickets(
      selectedUser,
      selectedService,
      document.getElementById("quantity").value
    );
    const result = await response.json();
    alert(result.msg);
    await getUserServices(selectedUser);
    setAddLoader(false);
  };

  const removeTickets = async () => {
    setAddLoader(true);
    const response = await UserBridge.removeServiceTickets(
      selectedUser,
      selectedService,
      document.getElementById("quantity").value
    );
    const result = await response.json();
    alert(result.msg);
    await getUserServices(selectedUser);
    setAddLoader(false);
  };

  const populateServicesSelect = () => {
    const payload = [];
    serviceList.forEach((service) => {
      payload.push(<option value={service._id}>{service.name}</option>);
    });
    return payload;
  };

  const populateUserSelect = () => {
    const payload = [];
    userList.forEach((user) => {
      payload.push(
        <option value={user._id}>
          {user.fname} {user.lname}
        </option>
      );
    });
    return payload;
  };

  const populateTable = () => {
    const payload = [];
    userData.forEach((service) => {
      console.log(service);
      payload.push(
        <tr>
          <td>{service.serviceName}</td>
          <td>{service.quantity}</td>
        </tr>
      );
    });
    return payload;
  };

  if (loaded) {
    return (
      <section className="servicesTicketing">
        <h1>Service Ticketing</h1>
        <p>
          In order for customers to book a service they must have paid for a
          booking ticket for that service. This is an admin feature that allows
          you to manually adjust the number of tickets a user has for a given
          service.
        </p>
        <p>
          Employees should use the Schedule Services module in the Point of Sale
          window as this will force tickets to be debited from the user and
          payment to be collected if no ticekts are available.
        </p>
        <label htmlFor="userSelect">Please Select a User</label>
        <select
          id="userSelect"
          name="userSelect"
          onChange={(e) => {
            selectUser(e.target.value);
          }}
        >
          <option value="">SELECT A USER</option>
          {populateUserSelect()}
        </select>
        <label htmlFor="serviceSelect">Please Select a Service</label>
        <select
          id="serviceSelect"
          name="serviceSelect"
          onChange={(e) => {
            setSelectedService(e.target.value);
          }}
        >
          <option value="">SELECT A SERVICE</option>
          {populateServicesSelect()}
        </select>
        <label htmlFor="quantity">Quantity</label>
        <input
          type="number"
          id="quantity"
          name="quantity"
          placeholder="Quantity of tickets to adjust"
        />
        <button onClick={addTickets}>
          {addLoader ? <MicroLoader /> : "Add Tickets"}
        </button>
        <button onClick={removeTickets}>
          {removeLoader ? <MicroLoader /> : "Remove Tickets"}
        </button>
        {userData ? (
          <>
            {viewLoader ? (
              <MicroLoader />
            ) : (
              <>
                <h3>User Tickets</h3>
                <table>
                  <tr>
                    <th>Service Name</th>
                    <th>Tickets</th>
                  </tr>
                  {populateTable()}
                </table>
              </>
            )}
          </>
        ) : null}
      </section>
    );
  } else return <PageLoader />;
};

export default ServicesTicketing;
