import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const Redirector = () => {
  const nav = useNavigate();
  useEffect(() => {
    nav("/store");
  }, []);
  return <></>;
};

export default Redirector;
