import React from "react";

const LegalPrivacy = () => {
  return (
    <section className="legalPrivacy">
     <h1>LegalPrivacy</h1>
    </section>
  );
};

export default LegalPrivacy;
