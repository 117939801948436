import React from "react";

const LegalCookieConsent = () => {
  return (
    <section className="legalCookieConsent">
     <h1>LegalCookieConsent</h1>
    </section>
  );
};

export default LegalCookieConsent;
