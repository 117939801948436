import React, { useEffect, useState } from "react";
import MicroLoader from "../../parts/MicroLoader";
import PageLoader from "../../parts/PageLoader";
import ServiceBridge from "../../../bridges/ServiceBridge";
import UserBridge from "../../../bridges/UserBridge";

const ServicesScheduling = () => {
  const [loaded, setLoaded] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [activeUser, setActiveUser] = useState(null);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await queryUser();
    const response2 = await ServiceBridge.getServices();
    const result2 = await response2.json();
    setServices(result2.data);
    setLoaded(true);
  };

  const populateServiceSelect = () => {
    const payload = [];
    services.forEach((service) => {
      payload.push(<option value={service._id}>{service.name}</option>);
    });
    return payload;
  };

  const queryUser = async () => {
    const response = await UserBridge.readCurrentUser();
    const result = await response.json();
    if (result.error || response.status != 200) {
      nav("/login");
    } else {
      setActiveUser(result.data);
    }
  };

  const pickService = (_id) => {
    setButtonLoader(true);
    for (let i = 0; i < services.length; i++) {
      if (services[i]._id == _id) {
        setSelectedService(services[i]);
        console.log(services[i]);
      }
    }
    setButtonLoader(false);
  };

  if (loaded) {
    return (
      <section className="servicesScheduling">
        <h1>Service Scheduling</h1>

        {activeUser.authLevel > 1 ? (
          <>
            <p>Use this module to directly book services.</p>
            <p>
              Employees should use the schedule services module within the Point
              of Sale window, as that forces money to be collected or tickets to
              be docked from the clients account in order to book. This is an
              admin feature designed to allow direct booking with or without
              payment.
            </p>
          </>
        ) : (
          <p>
            Use this module to view your schedule. Find your service in the
            list, and then click the button to take you to your schedule. You
            must be signed into your email account in another tab in order for
            the calendar to let you view it, due to the permission requirements
            and the need for privacy.
          </p>
        )}

        <label htmlFor="serviceSelect">Select a Service</label>
        <select
          name="serviceSelect"
          id="serviceSelect"
          onChange={(e) => {
            pickService(e.target.value);
          }}
        >
          <option value="">SELECT A SERVICE</option>
          {populateServiceSelect()}
        </select>
        {selectedService ? (
          <>
            {buttonLoader ? (
              <MicroLoader />
            ) : (
              <>
                {activeUser.authLevel > 1 ? (
                  <a
                    href={selectedService.bookingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button>Booking Link</button>
                  </a>
                ) : null}
                <a
                  href={selectedService.scheduleViewLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>Schedule View link</button>
                </a>
              </>
            )}
          </>
        ) : null}
      </section>
    );
  } else return <PageLoader />;
};

export default ServicesScheduling;
