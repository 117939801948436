import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import LoginView from "./views/LoginView";
import ErrorView from "./views/ErrorView";
import StoreView from "./views/StoreView";
import StoreProduct from "./components/store/StoreProduct";
import StoreCheckout from "./components/store/StoreCheckout";
import LoginReset from "./components/login/LoginReset";
import LoginSignup from "./components/login/LoginSignup";
import LegalView from "./views/LegalView";
import LegalPrivacy from "./components/legal/LegalPrivacy";
import LegalTermsOfUse from "./components/legal/LegalTermsOfUse";
import LegalCookieConsent from "./components/legal/LegalCookieConsent";
import AccountView from "./views/AccountView";
import AccountOrders from "./components/account/AccountOrders";
import AccountInfo from "./components/account/AccountInfo";
import AccountServices from "./components/account/AccountServices";
import CrmView from "./views/CrmView";
import CrmAccounts from "./components/crm/CrmAccounts";
import AccountsClients from "./components/crm/accounts/AccountsClients";
import AccountsEmployees from "./components/crm/accounts/AccountsEmployees";
import CrmOrders from "./components/crm/CrmOrders";
import OrdersFulfilment from "./components/crm/orders/OrdersFulfilment";
import OrdersHistory from "./components/crm/orders/OrdersHistory";
import CrmServices from "./components/crm/CrmServices";
import ServicesManage from "./components/crm/services/ServicesManage";
import ServicesScheduling from "./components/crm/services/ServicesScheduling";
import ServicesTicketing from "./components/crm/services/ServicesTicketing";
import CrmProducts from "./components/crm/CrmProducts";
import ProductsManage from "./components/crm/products/ProductsManage";
import CrmPos from "./components/crm/CrmPos";
import StoreShop from "./components/store/StoreShop";
import LoginDefault from "./components/login/LoginDefault";
import AccountHome from "./components/account/AccountHome";
import CrmIndex from "./components/crm/CrmIndex";
import AccountsManagers from "./components/crm/accounts/AccountsManagers";
import AccountsServiceProviders from "./components/crm/accounts/AccountsServiceProviders";
import ProductsStocking from "./components/crm/products/ProductsStocking";
import Redirector from "./components/parts/Redirector";
import PosCheckout from "./components/crm/pos/PosCheckout";
import PosTimeClock from "./components/crm/pos/PosTimeClock";
import StoreSuccess from "./components/store/StoreSuccess";
import ProductPromotions from "./components/crm/products/ProductPromotions";
import ServiceHistory from "./components/crm/services/ServiceHistory";
import AccountsTimesheet from "./components/crm/accounts/AccountsTimesheet";
import ProductInventory from "./components/crm/products/ProductInventory";
import CrmHelp from "./components/crm/help/CrmHelp";
import ServicesPackage from "./components/crm/services/ServicesPackages";
import PosCheckin from "./components/crm/pos/PosCheckIn";
import PosScheduling from "./components/crm/pos/PosScheduling";

const App = () => {
  useEffect(() => {
    const url = new URL(window.location.href);
    console.log(`---->!<----`);
  }, []);

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Flex Factory Nutrition & Wellness - Web Store</title>
        <meta
          name="description"
          content="Welcome to FFNAW's web store! Fresno's best source for sports nutrition and supplements. Never miss a shipment again with monthly orders."
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="/assets/favicon-square-alpha.png"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="/main.css" />
      </head>
      <body>
        <main>
          <Routes>
            <Route path="/store" element={<StoreView />}>
              <Route index element={<StoreShop />} />
              <Route path="product/:id" element={<StoreProduct />} />
              <Route path="checkout" element={<StoreCheckout />} />
              <Route path="checkout/success" element={<StoreSuccess />} />
            </Route>
            <Route path="/login" element={<LoginView />}>
              <Route index element={<LoginDefault />} />
              <Route path="reset/:id" element={<LoginReset />} />
              <Route path="signup" element={<LoginSignup />} />
            </Route>
            <Route path="/legal" element={<LegalView />}>
              <Route path="privacy" element={<LegalPrivacy />} />
              <Route path="termsofuse" element={<LegalTermsOfUse />} />
              <Route path="cookieconsent" element={<LegalCookieConsent />} />
            </Route>
            <Route path="/v1">
              <Route
                index
                element={
                  <ErrorView
                    code={404}
                    message="The page you are looking for does not exist."
                  />
                }
              />
              <Route path="account" element={<AccountView />}>
                <Route index element={<AccountHome />} />
                <Route path="orders" element={<AccountOrders />} />
                <Route path="info" element={<AccountInfo />} />
                <Route path="services" element={<AccountServices />}>
                </Route>
              </Route>
              <Route path="crm" element={<CrmView />}>
                <Route index element={<CrmIndex />} />
                <Route path="accounts">
                  <Route index element={<CrmAccounts />} />
                  <Route path="clients" element={<AccountsClients />} />
                  <Route path="employees" element={<AccountsEmployees />} />
                  <Route path="managers" element={<AccountsManagers />} />
                  <Route path="timesheet" element={<AccountsTimesheet />} />
                  <Route
                    path="serviceproviders"
                    element={<AccountsServiceProviders />}
                  />
                </Route>
                <Route path="orders">
                  <Route index element={<CrmOrders />} />
                  <Route path="fulfillment" element={<OrdersFulfilment />} />
                  <Route path="history" element={<OrdersHistory />} />
                </Route>
                <Route path="services">
                  <Route index element={<CrmServices />} />
                  <Route path="manage" element={<ServicesManage />} />
                  <Route path="packages" element={<ServicesPackage />} />
                  <Route path="scheduling" element={<ServicesScheduling />} />
                  <Route path="ticketing" element={<ServicesTicketing />} />
                  <Route path="history" element={<ServiceHistory />} />
                </Route>
                <Route path="products">
                  <Route index element={<CrmProducts />} />
                  <Route path="manage" element={<ProductsManage />} />
                  <Route path="stocking" element={<ProductsStocking />} />
                  <Route path="inventory" element={<ProductInventory />} />
                  <Route path="promotions" element={<ProductPromotions />} />
                </Route>
                <Route path="pos">
                  <Route index element={<CrmPos />} />
                  <Route path="checkout" element={<PosCheckout />} />
                  <Route path="timeclock" element={<PosTimeClock />} />
                  <Route path="scheduling" element={<PosScheduling />} />
                  <Route path="checkin" element={<PosCheckin />} />
                </Route>
                <Route path="help">
                  <Route index element={<CrmHelp />} />
                </Route>
              </Route>
            </Route>
            <Route path="/" element={<Redirector to="/store" />} />
            <Route
              path="*"
              element={
                <ErrorView
                  code={404}
                  message="The page you are looking for does not exist."
                />
              }
            />
          </Routes>
        </main>
      </body>
    </html>
  );
};

export default App;
