import React, { useEffect, useState } from "react";
import MicroLoader from "./MicroLoader";
import OrderBridge from "../../bridges/OrderBridge";

const SubscriptionDropdown = ({ lineItems, subscriptionId, clientName, callback }) => {
  const [open, setOpen] = useState(false);
  const [lineItemArray, setLineItemArray] = useState([]);
  const [reproduceLoader, setReproduceLoader] = useState(false);

  useEffect(() => {
    setLineItemArray(lineItems);
  }, []);

  const toggleOpen = () => {
    if (open) setOpen(false);
    else if (!open) setOpen(true);
  };

  const reproduceOrder = async () => {
    setReproduceLoader(true);
    // const response = await OrderBridge.reproduceOrder(subscriptionId)
    // const result = await response.json()
    // alert(result.msg)
    // callback()
    alert("Feature coming soon")
    setReproduceLoader(false);
  };

  
  const populateLineItems = () => {
    let payload = [];
    console.log(lineItems);
    lineItemArray.forEach((item) => {
      payload.push(
        <div className="lineItems">
          <div className="row">
            <strong>Item:</strong>
            <p>{item.productName}</p>
            <strong>Quantity: </strong>
            <p>{item.quantity}</p>
          </div>
        </div>
      );
    });
    return payload;
  };

  return (
    <div className="orderDropdown">
      <div
        onClick={() => {
          toggleOpen();
        }}
        className="controls"
      >
        <div className="left">
          <strong>Subscription ID: </strong>
          <p>{subscriptionId}</p>
          <strong>Client Name:</strong>
          <p>{clientName}</p>
        </div>
        <div className="right">
          <img
            src={open ? "/assets/uparrow.png" : "/assets/downarrow.png"}
          ></img>
        </div>
      </div>
      <div className={"order " + (open ? "open" : "closed")}>
        <strong>Line Items</strong>
        {populateLineItems()}
        <button onClick={reproduceOrder}>
          {reproduceLoader ? <MicroLoader /> : "Create Order"}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionDropdown;
