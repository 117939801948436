import React from "react";

const OrdersHistory = () => {
  return (
    <section className="ordersHistory">
     <h1>OrdersHistory</h1>
    </section>
  );
};

export default OrdersHistory;
