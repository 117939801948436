import React, { useEffect, useState } from "react";
import MicroLoader from "./MicroLoader";
import OrderBridge from "../../bridges/OrderBridge";

const OrderDropdown = ({
  lineItems,
  orderId,
  clientName,
  callback,
  inStore,
}) => {
  const [open, setOpen] = useState(false);
  const [lineItemArray, setLineItemArray] = useState([]);
  const [fulfilledLoader, setFulfilledLoader] = useState(false);
  const [shippingLoader, setShippingLoader] = useState(false);

  useEffect(() => {
    setLineItemArray(lineItems);
  }, []);

  const toggleOpen = () => {
    if (open) setOpen(false);
    else if (!open) setOpen(true);
  };

  const markFulfilled = async () => {
    setFulfilledLoader(true);
    const response = await OrderBridge.fulfillOrder(orderId);
    const result = await response.json();
    alert(result.msg);
    callback();
    setFulfilledLoader(false);
  };

  const generateShippingLabel = async () => {
    setShippingLoader(false);
    const length = prompt("Please enter package length in inches");
    const width = prompt("Please enter package width in inches");
    const height = prompt("Please enter package height in inches");
    const weight = prompt("Please enter package weight in pounds");
    if (!length || !width || !height || !weight) {
      alert("Please try again and enter all fields");
    } else {
      const response = await OrderBridge.shipOrder(
        height,
        length,
        width,
        weight,
        orderId
      );
      console.log(response);
      const result = await response.json();
      const url = result.data.labelUrl;
      window.open(url);
    }
    setShippingLoader(false);
  };

  const populateLineItems = () => {
    let payload = [];
    console.log(lineItems);
    lineItemArray.forEach((item) => {
      payload.push(
        <div className="lineItems">
          <div className="row">
            <strong>Item:</strong>
            <p>{item.productName}</p>
            <strong>Quantity: </strong>
            <p>{item.quantity}</p>
          </div>
        </div>
      );
    });
    return payload;
  };

  return (
    <div className="orderDropdown">
      <div
        onClick={() => {
          toggleOpen();
        }}
        className="controls"
      >
        <div className="left">
          <strong>Order ID: </strong>
          <p>{orderId}</p>
          <strong>Client Name:</strong>
          <p>{clientName}</p>
          {inStore && <strong>IN STORE PICK UP</strong>}
        </div>
        <div className="Right">
          <img
            src={open ? "/assets/uparrow.png" : "/assets/downarrow.png"}
          ></img>
        </div>
      </div>
      <div className={"order " + (open ? "open" : "closed")}>
        <strong>Line Items</strong>
        {populateLineItems()}
        {!inStore && (
          <button onClick={generateShippingLabel}>
            {shippingLoader ? <MicroLoader /> : "Generate Shipping Label"}
          </button>
        )}

        <button onClick={markFulfilled}>
          
          {fulfilledLoader ? <MicroLoader /> : "Mark as Fulfilled"}
        </button>
      </div>
    </div>
  );
};

export default OrderDropdown;
