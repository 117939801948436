import React, { useEffect, useState } from "react";
import MicroLoader from "../../parts/MicroLoader";
import PageLoader from "../../parts/PageLoader";
import CheckinBridge from "../../../bridges/CheckinBridge";
import UserBridge from "../../../bridges/UserBridge";

const ServiceHistory = () => {
  const [loaded, setLoaded] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const [timesheetLoader, setTimesheetLoader] = useState(false);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getProviders();
    setLoaded(true);
  };

  const getProviders = async () => {
    const response = await UserBridge.readServiceProviders();
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    } else {
      setProviders(result.data);
    }
  };

  const getReport = async () => {
    setTimesheetLoader(true);
    setButtonLoader(true);
    const dateBegin = document.getElementById("dateBegin").value;
    const dateObjBegin = new Date(dateBegin);
    const dateEnd = document.getElementById("dateEnd").value;
    const dateObjEnd = new Date(dateEnd);
    const data = {
      begin: dateObjBegin.getTime(),
      end: dateObjEnd.getTime(),
      selectedProvider: selectedProvider
    };
    const response = await CheckinBridge.getHistory(data);
    const result = await response.json();
    alert(result.msg);
    setTimesheetData(result.data);
    setButtonLoader(false);
    setTimesheetLoader(false);
  };

  const populateTable = () => {
    let payload = [];
    timesheetData.forEach((entry) => {
      payload.push(
        <tr>
          <td>{entry.user}</td>
          <td>{entry.service}</td>
          <td>{entry.time}</td>
        </tr>
      );
    });
    return payload;
  };

  const populateProviderSelect = () => {
    let payload = [];
    providers.forEach((provider) => {
      payload.push(
        <option value={provider._id}>
          {provider.fname} {provider.lname}
        </option>
      );
    });
    return payload;
  };

  if (loaded) {
    return (
      <section className="serviceHistory">
        <h1>Service History</h1>
        <p>
          Use this module to see who has checked in for what service at the
          front desk for a specific time period.
        </p>
        <p>
          The ending date is the beginning of the day. To include the ending
          date, select the next day. To select one day, select the starting date
          and then set the ending date to the next day.
        </p>
        <div className="inputs">
          <label htmlFor="dateBegin">Starting Date</label>
          <input type="date" name="dateBegin" id="dateBegin" />
          <label htmlFor="dateEnd">Ending Date</label>
          <input type="date" name="dateEnd" id="dateEnd" />
          <label htmlFor="userFilter">Filter by Provider</label>
          <select
            id="userFilter"
            name="userFilter"
            onChange={(e) => {
              setSelectedProvider(e.target.value);
            }}
          >
            <option value="">All Providers</option>
            {populateProviderSelect()}
          </select>
          <button onClick={getReport}>
            {buttonLoader ? <MicroLoader /> : "Get Report"}
          </button>
        </div>
        <div className="historyTable">
          {timesheetData && timesheetData.length > 0 ? (
            <>
              {timesheetLoader ? (
                <MicroLoader />
              ) : (
                <table>
                  <tr>
                    <th>Name</th>
                    <th>Service</th>
                    <th>Date</th>
                  </tr>
                  {populateTable()}
                </table>
              )}
            </>
          ) : null}
        </div>
      </section>
    );
  } else return <PageLoader />;
};

export default ServiceHistory;
