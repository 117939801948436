import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router";
import StoreHeader from "../components/parts/StoreHeader";
import StoreFooter from "../components/parts/StoreFooter";

export const CartContext = createContext({
  cartCount: 0,
  setCartCount: () => {},
});

const StoreView = () => {
  const [cartCount, setCartCount] = useState(0);

  return (
    <section className="storeView">
      <CartContext.Provider value={{ cartCount, setCartCount }}>
        <StoreHeader />
        <Outlet />
        <StoreFooter />
      </CartContext.Provider>
    </section>
  );
};

export default StoreView;
