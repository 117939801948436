import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import StoreFooter from "../components/parts/StoreFooter";
import AccountHeader from "../components/parts/AccountHeader";
import { Link } from "react-router-dom";
import PageLoader from "../components/parts/PageLoader";
import UserBridge from "../bridges/UserBridge";

const AccountView = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await queryUser();
    setLoaded(true);
  };

  const queryUser = async () => {
    const response = await UserBridge.readCurrentUser();
    const result = await response.json();
    if (response.status != 200 || result.error) {
      nav("/login");
    } else {
      setCurrentUser(result.data);
    }
    setLoaded(true);
  };
  
  if (loaded) {
    return (
      <section className="accountView">
        <AccountHeader />
        <section className="subHeader">
          <nav>
            <Link to="/v1/account/">Home</Link>
            <Link to="/v1/account/orders">Orders</Link>
            <Link to="/v1/account/services">Services</Link>
            <Link to="/v1/account/info">Account Information</Link>
            <Link to="/store">Shop Now</Link>
            {currentUser.authLevel >= 1 ? (
              <Link to="/v1/crm">Employee Portal</Link>
            ) : null}
          </nav>
        </section>
        <Outlet />
        <StoreFooter />
      </section>
    );
  } else return <PageLoader />;
};

export default AccountView;
