export default class TransactionBridge {
  static async purchaseRedTickets(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "668b8168f5cf8a673ab62b20",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseHyperTickets(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "668b8404f5cf8a673ab62b24",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseAlvira(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "6691946619291cb6006f0e63",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseAlvira2(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "6695d1aff8d50e3e928aecb9",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseHangover(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "66918e9819291cb6006f0e5f",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseHydrateMe(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "66918bfb19291cb6006f0e4f",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchasePerformance(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "66918dbd19291cb6006f0e53",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseQuinch(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "66918dfa19291cb6006f0e57",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseMyers(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "66918e5f19291cb6006f0e5b",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseTest(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchaseservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "6691ff563372f8a62c4b4c64",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchasePackage(quantity, targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasepackage", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: "66a1b3e9249cbfa1ea65f34c",
          quantity: quantity,
          targetUserId: targetUserId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseBronze(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 11,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseBronzeHyper(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 32,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseBronzeRed(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 31,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseUnlimited(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 99,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseElviraBronze(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 11,
          targetUserId: targetUserId,
          packageId: "66d0c1a0c9d5db692722caac",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }


  static async purchaseElviraGold(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 22,
          targetUserId: targetUserId,
          packageId: "66d0c1a0c9d5db692722caac",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseElviraPlatinum(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 44,
          targetUserId: targetUserId,
          packageId: "66d0c1a0c9d5db692722caac",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseUnlimitedRed(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 91,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseUnlimitedHyper(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 92,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchaseGold(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 22,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async purchasePlatinum(targetUserId) {
    try {
      let response = await fetch("/api/transactions/purchasesubscription", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: 44,
          targetUserId: targetUserId,
          packageId: "66a1b3e9249cbfa1ea65f34c",
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (response.redirected) window.location.href = response.url;
      return response;
    } catch (error) {
      return error;
    }
  }

  static async completeTransaction(transactionId) {
    try {
      let response = await fetch("/api/transactions/completetransaction", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transactionId: transactionId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getCurrentSubscriptions(_id) {
    try {
      let response = await fetch("/api/transactions/getcurrentsubscriptions", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({_id: _id}),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getCurrentTransactions() {
    try {
      let response = await fetch("/api/transactions/getcurrent", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async afterCharge(lineItems) {
    try {
      let response = await fetch("/api/transactions/aftercharge", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ lineItems: lineItems }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
