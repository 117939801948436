export default class CouponBridge {
  static async getCoupons() {
    try {
      let response = await fetch("/api/coupons/read", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async createCoupon(
    name,
    duration,
    durationInMonths,
    percentOff,
    promoCode
  ) {
    try {
      let response = await fetch("/api/coupons/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          duration: duration,
          durationInMonths: durationInMonths,
          percentOff: percentOff,
          promoCode: promoCode,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async deleteCoupon(stripeCouponId) {
    try {
      let response = await fetch("/api/coupons/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stripeCouponId: stripeCouponId,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
