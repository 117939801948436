/* 
    NAME: main.js
    AUTHOR: Colby Deniston <cdeniston@denicode.com>
    DESCRIPTION: Client entry point for for Sierra
    Inspector home inspection software
*/

/*  IMPORTS  */
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./styles/client.sass";
import "./styles/general.sass";
import App from "./App";

/*  DECLARATIONS  */
const doc = document;

/*  HYDRATE DOCUMENT  */
hydrateRoot(
  doc,
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
