import React, { useEffect, useState } from "react";
import UserBridge from "../../bridges/UserBridge";
import PageLoader from "../parts/PageLoader";
import { useNavigate } from "react-router";

const AccountHome = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await queryUser();
    setLoaded(true);
  };

  const queryUser = async () => {
    const response = await UserBridge.readCurrentUser();
    if (response.status === 200) {
      const result = await response.json();
      setCurrentUser(result.data);
    } else {
      nav("/login");
    }
    setLoaded(true);
  };

  if (loaded && currentUser) {
    return (
      <section className="accountHome">
        <h1>Hello, {currentUser.fname}</h1>
        <p>
          Welcome to your account portal. Here you may update your account
          information, see your orders, and more.
        </p>
        <section className="serviceCards">
          <div
            onClick={() => {
              nav("/v1/account/orders");
            }}
          >
            <img src="../../assets/orders.png" />
            <h2>Order History</h2>
            <p>See your order history and manage upcoming orders here.</p>
            <button>See more!</button>
          </div>
          <div
            onClick={() => {
              nav("/v1/account/services");
            }}
          >
            <img src="../../assets/sched.png" />
            <h2>Services</h2>
            <p>See your service history and schedule services here.</p>
            <button>See more!</button>
          </div>
          <div
            onClick={() => {
              nav("/v1/account/info");
            }}
          >
            <img src="../../assets/act.png" />
            <h2>Account</h2>
            <p>Update your account information here.</p>
            <button>See more!</button>
          </div>
        </section>
      </section>
    );
  } else return <PageLoader />;
};

export default AccountHome;
