export default class TimesheetBridge {
  static async clockIn(posPin) {
    try {
      let response = await fetch("/api/timesheet/clockin", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ posPin: posPin }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async breakOut(posPin) {
    try {
      let response = await fetch("/api/timesheet/breakout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ posPin: posPin }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async breakIn(posPin) {
    try {
      let response = await fetch("/api/timesheet/breakin", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ posPin: posPin }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async clockOut(posPin) {
    try {
      let response = await fetch("/api/timesheet/clockout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ posPin: posPin }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async get(data) {
    try {
      let response = await fetch("/api/timesheet/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getday() {
    try {
      let response = await fetch("/api/timesheet/getday", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
