import React, { useEffect, useState } from "react";
import PageLoader from "../../parts/PageLoader";
import MicroLoader from "../../parts/MicroLoader";
import TimesheetBridge from "../../../bridges/TimesheetBridge";

const AccountsTimesheet = () => {
  const [loaded, setLoaded] = useState(false);
  const [timesheetLoader, setTimesheetLoader] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const [totalsData, setTotalsData] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    setLoaded(true);
  };

  const getSheets = async () => {
    setTimesheetLoader(true);
    setButtonLoader(true);
    const dateBegin = document.getElementById("dateBegin").value;
    const dateObjBegin = new Date(dateBegin);
    const dateEnd = document.getElementById("dateEnd").value;
    const dateObjEnd = new Date(dateEnd);
    const data = {
      begin: dateObjBegin.getTime(),
      end: dateObjEnd.getTime(),
    };
    const response = await TimesheetBridge.get(data);
    const result = await response.json();
    alert(result.msg);
    setTimesheetData(result.data.timesheets);
    setTotalsData(result.data.totalHours);
    setButtonLoader(false);
    setTimesheetLoader(false);
  };

  const populateTimesheetRows = () => {
    let payload = [];
    timesheetData.forEach((sheet) => {
      payload.push(
        <tr>
          <td>{`${sheet.user.fname} ${sheet.user.lname}`}</td>
          <td>{sheet.date}</td>
          <td>{sheet.timeIn}</td>
          <td>{sheet.timeOut}</td>
          <td>{sheet.breakIn}</td>
          <td>{sheet.breakOut}</td>
          <td>{sheet.hoursWorked}</td>
        </tr>
      );
    });
    return payload;
  };

  populateTotalRows = () => {
    let payload = [];
    totalsData.forEach((sheet) => {
      payload.push(
        <tr>
          <td>{`${sheet.user.fname} ${sheet.user.lname}`}</td>
          <td>{sheet.totalHours}</td>
        </tr>
      );
    });
    return payload;
  }

  if (loaded) {
    return (
      <section className="accountsTimesheet">
        <h1>Timesheet Reporting</h1>
        <p>
          Here you may find your time sheet data generated by the point of sale
          system.
        </p>
        <p>
          The ending date is the beginning of the day. To include the ending
          date, select the next day. To select one day, select the starting date
          and then set the ending date to the next day.
        </p>
        <div className="inputs">
          <label htmlFor="dateBegin">Starting Date</label>
          <input type="date" name="dateBegin" id="dateBegin" />
          <label htmlFor="dateEnd">Ending Date</label>
          <input type="date" name="dateEnd" id="dateEnd" />
          <button onClick={getSheets}>
            {buttonLoader ? <MicroLoader /> : "Get Timesheet"}
          </button>
        </div>
        {timesheetData.length > 0 ? (
          <>
            {timesheetLoader ? (
              <MicroLoader />
            ) : (
              <>
                <div className="timesheetTable">
                  <h2>Timesheet Totals</h2>
                  <table>
                    <tr>
                      <th>Employee Name</th>
                      <th>Total Hours</th>
                    </tr>
                    {populateTotalRows()}
                  </table>
                </div>
                <div className="timesheetTable">
                  <h2>Daily Timestamps</h2>
                  <table>
                    <tr>
                      <th>Employee Name</th>
                      <th>Date</th>
                      <th>Time In</th>
                      <th>Time Out</th>
                      <th>Break In</th>
                      <th>Break Out</th>
                      <th>Total Hours</th>
                    </tr>
                    {populateTimesheetRows()}
                  </table>
                </div>
              </>
            )}
          </>
        ) : null}
      </section>
    );
  } else return <PageLoader />;
};

export default AccountsTimesheet;
