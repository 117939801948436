import React, { useEffect, useState } from "react";
import PageLoader from "../../parts/PageLoader";
import ProductBridge from "../../../bridges/ProductBridge";
import MicroLoader from "../../parts/MicroLoader";

const ProductInventory = () => {
  const [loaded, setLoaded] = useState(false);
  const [adjustUpLoader, setAdjustUpLoader] = useState(false);
  const [adjustDownLoader, setAdjustDownLoader] = useState(false);
  const [inventoryLoader, setInventoryLoader] = useState(false);
  const [checkInventoryLoader, setCheckInventoryLoader] = useState(false);
  const [checkedInventory, setCheckedInventory] = useState(0);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getProductList();
    setLoaded(true);
  };

  const getProductList = async () => {
    const response2 = await ProductBridge.getProducts();
    const result2 = await response2.json();
    setProductList(result2.data);
  };

  const populateProductSelect = () => {
    const payload = [];
    productList.forEach((product) => {
      payload.push(<option value={product._id}>{product.name}</option>);
    });
    return payload;
  };

  const checkInventory = async (_id) => {
    setCheckInventoryLoader(true);
    const response = await ProductBridge.checkInventory(_id);
    const result = await response.json();
    setCheckedInventory(result.data);
    setSelectedProduct(_id);
    setCheckInventoryLoader(false);
  };

  const populateRunningLow = () => {
    let payload = [];
    productList.forEach((product) => {
      if (product.quantityInStock <= 5) {
        const priceDollars = product.price / 100;
        const formattedPrice = `$${priceDollars}`;
        payload.push(
          <tr>
            <td>{product.name}</td>
            <td>{product._id}</td>
            <td>{product.quantityInStock}</td>
            <td>{formattedPrice}</td>
          </tr>
        );
      }
    });
    return payload;
  };

  const populateProducts = () => {
    let payload = [];
    productList.forEach((product) => {
      const priceDollars = product.price / 100;
      const formattedPrice = `$${priceDollars}`;
      payload.push(
        <tr>
          <td>{product.name}</td>
          <td>{product._id}</td>
          <td>{product.quantityInStock}</td>
          <td>{formattedPrice}</td>
        </tr>
      );
    });
    return payload;
  };

  const adjustUp = async () => {
    setAdjustUpLoader(true);
    setInventoryLoader(true);
    const response = await ProductBridge.adjustUp(
      selectedProduct,
      document.getElementById("adjustment").value
    );
    const result = await response.json();
    alert(result.msg);
    await getProductList();
    setInventoryLoader(false);
    setAdjustUpLoader(false);
  };

  const adjustDown = async () => {
    setAdjustDownLoader(true);
    setInventoryLoader(true);
    const response = await ProductBridge.adjustDown(
      selectedProduct,
      document.getElementById("adjustment").value
    );
    const result = await response.json();
    alert(result.msg);
    await getProductList();
    setInventoryLoader(false);
    setAdjustDownLoader(false);
  };

  if (loaded) {
    return (
      <section className="productsInventory">
        <h1>Product Inventory</h1>
        <p>
          Use this module to get a birds eye view of your inventory, and to see
          what is running low.
        </p>
        <h3>Check a Product</h3>
        <label htmlFor="productSelect">Select a Product</label>
        <select
          name="productSelect"
          id="productSelect"
          onChange={(e) => {
            checkInventory(e.target.value);
          }}
        >
          <option value="">SELECT A PRODUCT</option>
          {populateProductSelect()}
        </select>
        <div className="rowWrapper">
          <strong>In Stock: </strong>
          {checkInventoryLoader ? <MicroLoader /> : <p>{checkedInventory}</p>}
        </div>
        <h3>Adjust Inventory</h3>
        <label htmlFor="adjustment">Adjustment Quantity</label>
        <input
          name="adjustment"
          id="adjustment"
          type="number"
          placeholder="adjustment quantity"
        />
        <button onClick={adjustUp}>
          {adjustUpLoader ? <MicroLoader /> : "Adjust Up"}
        </button>
        <button onClick={adjustDown}>
          {adjustDownLoader ? <MicroLoader /> : "Adjust Down"}
        </button>
        <h3>Running Low</h3>
        {inventoryLoader ? (
          <MicroLoader />
        ) : (
          <>
            <div className="runningLow">
              <table>
                <tr>
                  <th>Product Name</th>
                  <th>Product Id</th>
                  <th>Quantity</th>
                  <th>Product Price</th>
                </tr>
                {populateRunningLow()}
              </table>
            </div>
            <h3>Total Inventory</h3>
            <div className="totalInventory">
              <table>
                <tr>
                  <th>Product Name</th>
                  <th>Product Id</th>
                  <th>Quantity</th>
                  <th>Product Price</th>
                </tr>
                {populateProducts()}
              </table>
            </div>
          </>
        )}
      </section>
    );
  } else return <PageLoader />;
};

export default ProductInventory;
