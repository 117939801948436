export default class CheckinBridge {
  static async checkin(userId, serviceId) {
    try {
      let response = await fetch("/api/checkins/checkin", {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ serviceId: serviceId, userId: userId }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getcheckins() {
    try {
      let response = await fetch("/api/checkins/get", {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getHistory(data) {
    try {
      let response = await fetch("/api/checkins/gethistory", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
