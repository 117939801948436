import React, { useEffect, useState } from "react";
import OrderBridge from "../../bridges/OrderBridge";
import PageLoader from "../parts/PageLoader";
import SubscriptionBridge from "../../bridges/SubscriptionBridge";
import MicroLoader from "../parts/MicroLoader";

const AccountOrders = () => {
  const [loaded, setLoaded] = useState(false);
  const [subscriptionsLoader, setSubscriptionsLoader] = useState(false);
  const [activeSubscriptionCount, setActiveSubscriptionCount] = useState(0);
  const [shipmentsCount, setShipmentsCount] = useState(0);
  const [pastOrdersCount, setPastOrdersCount] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    const orders = await getOrders();
    console.log(orders);
    let thisShip = [];
    let thisPast = [];
    orders.forEach((order) => {
      switch (order.orderStatus) {
        case 1:
          thisShip.unshift(order);
          break;
        case 4:
          thisPast.unshift(order);
          break;
      }
    });
    await getSubs();
    setShipments(thisShip);
    setShipmentsCount(thisShip.length);
    setPastOrders(thisPast);
    setPastOrdersCount(thisPast.length);
    setLoaded(true);
  };

  const getOrders = async () => {
    const response = await OrderBridge.getCurrentOrders();
    const result = await response.json();
    return result.data;
  };

  const getSubs = async () => {
    setSubscriptionsLoader(true);
    const response = await SubscriptionBridge.getCurrentSubscriptions();
    const result = await response.json();
    setSubscriptions(result.data);
    setActiveSubscriptionCount(result.data.length);
    setSubscriptionsLoader(false);
  };

  const cancelSubscription = async (_id) => {
    setSubscriptionsLoader(true);
    const proceed = confirm(
      "Are you sure you want to cancel your subscription? If an order has already been created, it will still be shipped, but all future orders will be canceled."
    );
    if (proceed) {
      alert(
        "Please notify flex factory for the time being. An automatic cancelation feature is coming soon."
      );
      // const response = await SubscriptionBridge.cancelSubscription(_id);
      // const result = await response.json();
      // alert(result.msg);
    }
    await getSubs();
    setSubscriptionsLoader(false);
  };

  const renderSubscriptions = () => {
    let payload = [];
    subscriptions.forEach((subscription) => {
      let lineItemsPayload = [];
      subscription.lineItems.forEach((lineItem) => {
        lineItemsPayload.push(
          <p>
            Product: {lineItem.productName} Quantity: {lineItem.quantity}
          </p>
        );
      });
      const date = new Date();
      date.setTime(subscription.timestamp);
      const formattedDate = date.toLocaleDateString();
      const dayOfMonth = date.getDay();
      const formattedRenewalDate = `Renews on day ${dayOfMonth} of each month`;
      payload.push(
        <div className="shipment">
          <span>
            <strong>Subscription Creation Date:</strong>
            <p>{formattedDate}</p>
          </span>
          <span>
            <strong>Subscription ID:</strong>
            <p>{subscription._id}</p>
          </span>
          <span>
            <strong>Renewal Date:</strong>
            <p>{formattedRenewalDate}</p>
          </span>
          <span>
            <strong>Subscription Status:</strong>
            <p>
              {subscription.subscriptionStatus === 1 ? "active" : "canceled"}
            </p>
          </span>
          <span>
            <strong>Line Items</strong>
          </span>
          <div className="items">{lineItemsPayload}</div>
          <button
            id={`cancelSub${subscription._id}`}
            onClick={() => {
              cancelSubscription(subscription._id);
            }}
          >
            Cancel Subscription
          </button>
        </div>
      );
    });
    return payload;
  };

  const renderShipments = () => {
    let payload = [];
    shipments.forEach((shipment) => {
      let lineItemsPayload = [];
      shipment.lineItems.forEach((lineItem) => {
        lineItemsPayload.push(
          <p>
            Product: {lineItem.productName} Quantity: {lineItem.quantity}
          </p>
        );
      });
      const date = new Date();
      date.setTime(shipment.timestamp);
      const formattedDate = date.toLocaleDateString();
      const shipDate = new Date();
      const estimatedMs = shipDate.getTime() + 259200000; // 3 days in milliseconds
      shipDate.setTime(estimatedMs);
      const formattedShipDate = shipDate.toLocaleDateString();
      payload.push(
        <div className="shipment">
          <span>
            <strong>Date Placed:</strong>
            <p>{formattedDate}</p>
          </span>
          <span>
            <strong>Order ID:</strong>
            <p>{shipment._id}</p>
          </span>
          <span>
            <strong>Estimated Shipping Date:</strong>
            <p>{formattedShipDate}</p>
          </span>
          <span>
            <strong>Part of Recurring Order:</strong>
            <p>{shipment.recurring === true ? "true" : "false"}</p>
          </span>
          <span>
            <strong>Line Items</strong>
          </span>
          <div className="items">{lineItemsPayload}</div>
        </div>
      );
    });
    return payload;
  };

  const renderPastOrders = () => {
    let payload = [];
    pastOrders.forEach((shipment) => {
      let lineItemsPayload = [];
      shipment.lineItems.forEach((lineItem) => {
        lineItemsPayload.push(<p>Product: {lineItem.productName}</p>);
      });
      const date = new Date();
      date.setTime(shipment.timestamp);
      const formattedDate = date.toLocaleDateString();
      const shipDate = new Date();
      const estimatedMs = shipDate.getTime() + 604800000; // 7 days in milliseconds
      shipDate.setTime(estimatedMs);
      const formattedShipDate = shipDate.toLocaleDateString();
      console.log(shipment.timestamp);
      payload.push(
        <div className="shipment">
          <span>
            <strong>Date Placed:</strong>
            <p>{formattedDate}</p>
          </span>
          <span>
            <strong>Order ID:</strong>
            <p>{shipment._id}</p>
          </span>
          <span>
            <strong>Estimated Arrival:</strong>
            <p>{formattedShipDate}</p>
          </span>
          <span>
            <strong>Part of Recurring Order:</strong>
            <p>{shipment.recurring === true ? "true" : "false"}</p>
          </span>
          <span>
            <strong>Line Items</strong>
          </span>
          <div className="items">{lineItemsPayload}</div>
        </div>
      );
    });
    return payload;
  };

  if (loaded) {
    return (
      <section className="accountOrders">
        <h1>Order History</h1>
        <h3>Subscriptions {`(${activeSubscriptionCount})`}</h3>
        <div className="rowWrapper">
          {subscriptionsLoader ? <MicroLoader /> : renderSubscriptions()}
        </div>
        <h3>Upcoming Shipments {`(${shipmentsCount})`}</h3>
        <div className="rowWrapper">{renderShipments()}</div>
        <h3>Shipped {`(${pastOrdersCount})`}</h3>
        <div className="rowWrapper">{renderPastOrders()}</div>
      </section>
    );
  } else return <PageLoader />;
};

export default AccountOrders;
