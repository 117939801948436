export default class TerminalBridge {
  static async createPayment(scannedItems, discountCode, cardTotal) {
    try {
      let response = await fetch("/api/terminal/createpayment", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scannedItems: scannedItems,
          discountCode: discountCode,
          cardTotal: cardTotal
        }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async resetReader() {
    try {
      let response = await fetch("/api/terminal/reset", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async cancelPayment() {
    try {
      let response = await fetch("/api/terminal/cancelpayment", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
      });
      const result = await response.json();
      return result.data;
    } catch (error) {
      return error;
    }
  }
}
