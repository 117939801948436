import React from "react";
import { Outlet } from "react-router";

const CrmOrders = () => {
  return (
    <section className="crmOrders">
     <h1>CrmOrders</h1>
     <Outlet />
    </section>
  );
};

export default CrmOrders;
