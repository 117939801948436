import React, { useEffect, useState } from "react";
import PageLoader from "../../parts/PageLoader";
import MicroLoader from "../../parts/MicroLoader";
import ProductBridge from "../../../bridges/ProductBridge";
import { useNavigate } from "react-router";

const ProductsManage = () => {
  const [loaded, setLoaded] = useState(false);
  const [createProductLoader, setCreateProductLoader] = useState(false);
  const [updateProductLoader, setUpdateProductLoader] = useState(false);
  const [deleteProductLoader, setDeleteProductLoader] = useState(false);
  const [selectLoader, setSelectLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productList, setProductList] = useState([]);

  const nav = useNavigate();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    setEditLoader(true);
    setTimeout(() => {
      setEditLoader(false);
    }, 500);
  }, [selectedProduct]);

  const getProducts = async () => {
    const response = await ProductBridge.getProducts();
    const result = await response.json();
    if (result.error) {
      nav("/login");
    } else {
      const { data } = result;
      setProductList(data);
    }
    if (!loaded) {
      setLoaded(true);
    }
  };

  const createProduct = async () => {
    setCreateProductLoader(true);
    setSelectLoader(true);
    let formData = new FormData();
    formData.append("name", document.getElementById("name").value);
    formData.append(
      "description",
      document.getElementById("description").value
    );
    formData.append("price", document.getElementById("price").value);
    formData.append(
      "quantityInStock",
      document.getElementById("quantityInStock").value
    );
    formData.append("tags", document.getElementById("tags").value);
    formData.append("category", document.getElementById("category").value);
    formData.append("shippable", document.getElementById("shippable").value);
    formData.append("active", document.getElementById("active").value);
    const fileSelect = document.getElementById("photos");
    for (let n = 0; n < fileSelect.files.length; n++) {
      formData.append("photos", fileSelect.files[n]);
    }

    const response = await ProductBridge.createProduct(formData);
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    } else {
      alert("Product Created Successfully");
    }
    setCreateProductLoader(false);
    setSelectedProduct(null);
    await getProducts();
    setSelectLoader(false);
  };

  const populateProductSelect = () => {
    let payload = [];
    productList.forEach((product) => {
      payload.push(<option value={product._id}>{product.name}</option>);
    });
    return payload;
  };

  const setProduct = () => {
    const targetProduct = document.getElementById("selectedProduct").value;
    if (targetProduct === "") {
      setSelectedProduct(null);
    }
    productList.forEach((product) => {
      if (product._id === targetProduct) {
        setSelectedProduct(product);
      }
    });
  };

  const updateProduct = async () => {
    setSelectLoader(true);
    setUpdateProductLoader(true);
    let formData = new FormData();
    formData.append("name", document.getElementById("newname").value);
    console.log(document.getElementById("newname").value);
    formData.append(
      "description",
      document.getElementById("newdescription").value
    );
    formData.append("price", document.getElementById("newprice").value);
    formData.append(
      "quantityInStock",
      document.getElementById("newquantityInStock").value
    );
    formData.append("tags", document.getElementById("newtags").value);
    formData.append("category", document.getElementById("newcategory").value);
    formData.append("shippable", document.getElementById("newshippable").value);
    formData.append("active", document.getElementById("newactive").value);
    const fileSelect = document.getElementById("newphotos");
    for (let n = 0; n < fileSelect.files.length; n++) {
      formData.append("photos", fileSelect.files[n]);
    }
    formData.append("_id", selectedProduct._id);
    setEditLoader(true);
    console.log(formData);
    const response = await ProductBridge.updateProduct(formData);
    const result = await response.json();
    if (!!result.error) {
      alert("There has been an error. Please try again");
      console.log(result);
    } else {
      alert("Product has been updated");
      const oldSelectedId = selectedProduct._id;
      setSelectedProduct(null);
      await getProducts();
      productList.forEach((product) => {
        if (product._id === oldSelectedId) {
          setSelectedProduct(product);
        }
      });
      setSelectedProduct();
    }
    setSelectLoader(false);
    setUpdateProductLoader(false);
    setEditLoader(false);
  };

  const deleteProduct = async () => {
    setSelectLoader(true);
    setDeleteProductLoader(true);
    const confirmation = confirm(
      "Are you sure you wish to delete this product? This cannot be undone."
    );
    if (confirmation) {
      const response = await ProductBridge.deleteProduct(selectedProduct._id);
      const result = await response.json();
      if (!!result.error) {
        console.log("There has been an error");
      } else {
        alert("Product has been deleted");
        setSelectedProduct(null);
        await getProducts();
      }
    }
    setSelectLoader(false);
    setDeleteProductLoader(false);
  };

  const displayImages = () => {
    let payload = [];
    const photoList = selectedProduct.photos;
    if (Array.isArray(photoList)) {
      photoList.forEach((photo) => {
        const photoUrl = `/photos/${photo}`;
        payload.push(<img src={photoUrl} />);
      });
    }
    return payload;
  };

  if (loaded) {
    return (
      <section className="productsManage">
        <h1>Manage Products</h1>
        <p>Create, view, update, and delete products using this module.</p>
        <h3>Create New Product</h3>
        <label htmlFor="name">Name (required)</label>
        <input
          name="name"
          id="name"
          type="text"
          placeholder="enter product name"
        />
        <label htmlFor="description">Description (required)</label>
        <input
          name="description"
          id="description"
          type="text"
          placeholder="enter product description"
        />
        <label htmlFor="price">Price (required)</label>
        <input
          name="price"
          id="price"
          type="number"
          placeholder="enter price in cents... 20$ would be 2000 cents"
        />
        <label htmlFor="quantityInStock">Initial Stock (required)</label>
        <input
          name="quantityInStock"
          id="quantityInStock"
          type="number"
          placeholder="select a number"
        />
        <label htmlFor="tags">Product Tags (required)</label>
        <input
          name="tags"
          id="tags"
          type="text"
          placeholder="enter product tags separated by a comma"
        />
        <label htmlFor="category">Category (required)</label>
        <select id="category" name="category">
          <option value="category1">Category 1</option>
          <option value="category2">Category 2</option>
          <option value="category3">Category 3</option>
        </select>
        <label htmlFor="shippable">Shippable? (required)</label>
        <select id="shippable" name="shippable">
          <option value={true}>True</option>
          <option value={false}>False</option>
        </select>
        <label htmlFor="active">Active for sale? (required)</label>
        <select id="active" name="active">
          <option value={true}>True</option>
          <option value={false}>False</option>
        </select>
        <label htmlFor="photos">Photos (required, minimum 1 maximum 5)</label>
        <input name="photos" type="file" id="photos" multiple />
        <button onClick={createProduct}>
          {createProductLoader ? <MicroLoader /> : `Create Product`}
        </button>
        <h3>View & Edit Products</h3>
        {selectLoader ? (
          <MicroLoader />
        ) : (
          <select id="selectedProduct" onChange={setProduct}>
            <option value="">SELECT A PRODUCT TO EDIT</option>
            {populateProductSelect()}
          </select>
        )}

        {selectedProduct ? (
          <>
            {editLoader ? (
              <MicroLoader />
            ) : (
              <>
                <label htmlFor="newname">Name (required)</label>
                <input
                  name="newname"
                  id="newname"
                  type="text"
                  placeholder="enter product name"
                  defaultValue={selectedProduct.name}
                />
                <label htmlFor="newdescription">Description</label>
                <input
                  name="newdescription"
                  id="newdescription"
                  type="text"
                  placeholder="enter product description"
                  defaultValue={selectedProduct.description}
                />
                <label htmlFor="newprice">Price (required)</label>
                <input
                  name="newprice"
                  id="newprice"
                  type="number"
                  placeholder="enter price in cents... 20$ would be 2000 cents"
                  defaultValue={selectedProduct.price}
                />
                <label htmlFor="newquantityInStock">
                  Initial Stock (required)
                </label>
                <input
                  name="newquantityInStock"
                  id="newquantityInStock"
                  type="number"
                  placeholder="select a number"
                  defaultValue={selectedProduct.quantityInStock}
                />
                <label htmlFor="newtags">Product Tags</label>
                <input
                  name="newtags"
                  id="newtags"
                  type="text"
                  placeholder="enter product tags separated by a comma"
                  defaultValue={selectedProduct.tags}
                />
                <label htmlFor="newcategory">Category (required)</label>
                <select
                  id="newcategory"
                  name="newcategory"
                  defaultValue={selectedProduct.category}
                >
                  <option value="category1">Category 1</option>
                  <option value="category2">Category 2</option>
                  <option value="category3">Category 3</option>
                </select>
                <label htmlFor="newshippable">Shippable? (required)</label>
                <select
                  id="newshippable"
                  name="newshippable"
                  defaultValue={selectedProduct.shippable}
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
                <label htmlFor="newactive">Active for sale? (required)</label>
                <select
                  id="newactive"
                  name="newactive"
                  defaultValue={selectedProduct.active}
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
                <label htmlFor="newphotos">
                  Photos (required, minimum 1 maximum 5)
                </label>
                <input name="newphotos" type="file" id="newphotos" multiple />
                <strong>Existing Images:</strong>
                <div className="oldPhotos">{displayImages()}</div>
                <button onClick={updateProduct}>
                  {updateProductLoader ? <MicroLoader /> : `Update Product`}
                </button>
                <button onClick={deleteProduct}>
                  {deleteProductLoader ? <MicroLoader /> : `Delete Product`}
                </button>
              </>
            )}
          </>
        ) : null}
      </section>
    );
  } else return <PageLoader />;
};

export default ProductsManage;
