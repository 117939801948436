import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const StoreFooter = () => {
  const [year, setYear] = useState(0);

  useEffect(() => {
    const date = new Date().getFullYear();
    setYear(date);
  }, []);

  return (
    <section className="storeFooter">
      <div className="upper">
        <div className="text">
          <h2>Flex Factory Nutrition and Wellness, LLC</h2>
          <p>
            Fresno's best choice for personal training, supplements, active
            recovery equipment, and more! Stop by today to see how together we
            can take your personal fitness to the next level.
          </p>
          <h4>
            Subscribe to our newsletter for exclusive updates and discounts!
          </h4>
          <input
            id="emailCollector"
            name="emailCollector"
            type="email"
            placeholder="johnsmith@email.com"
          />
          <button>Subscribe</button>
        </div>
        <div className="links">
          <h3>Menu</h3>
          <Link to="/store">Shop Now</Link>
          <Link to="/v1/account/services">Schedule Services</Link>
          <Link to="/v1/account">My Account</Link>
        </div>
        <div className="links">
          <h3>Socials</h3>
          <Link to="/">Facebook</Link>
          <Link to="/">Instagram</Link>
        </div>
        <div className="links">
          <h3>Legal</h3>
          <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ade3be07-1f93-41a7-b925-e0fa37f7ff97">
            Privacy Policy
          </Link>
          <Link to="/">Cookie Consent</Link>
          <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ecb0617b-e57a-452f-812d-7a73abb763dd">
            Terms of Service
          </Link>
        </div>
      </div>
      <div className="lower">
        <p>
          Copyright &copy; {year} , Flex Factory Nutrition and Wellness , LLC
        </p>
      </div>
    </section>
  );
};

export default StoreFooter;
