export default class CartController {
    static addToCart(_id, quantity) {
        let cart = JSON.parse(localStorage.getItem('ffnawcart')) || [];
        let productIndex = cart.findIndex(product => product._id === _id);
    
        if(productIndex !== -1) {
          cart[productIndex].quantity += quantity;
        } else {
          cart.push({ _id, quantity });
        }
    
        localStorage.setItem('ffnawcart', JSON.stringify(cart));
      }
    
      static removeFromCart(_id) {
        let cart = JSON.parse(localStorage.getItem('ffnawcart')) || [];
        let updatedCart = cart.filter(product => product._id !== _id);
    
        localStorage.setItem('ffnawcart', JSON.stringify(updatedCart));
      }
    
      static emptyCart() {
        localStorage.setItem('ffnawcart', JSON.stringify([]));
      }
}