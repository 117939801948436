export default class ThermalBridge {
  static async generateBarcode(_id, price) {
    try {
      let response = await fetch("http://localhost:6060/barcode/generate", {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: _id, price: price }),
        referrerPolicy: "no-referrer",
      });
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url;
    } catch (error) {
      return error;
    }
  }

  static async printTestReceipt() {
    try {
      let response = await fetch("http://localhost:6060/receipt/testprint", {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async printCashReceipt(
    tendered,
    quantity,
    subtotal,
    discounts,
    tax,
    netTotal,
    change
  ) {
    try {
      console.log("printing");
      let response = await fetch("http://localhost:6060/receipt/printcash", {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tendered: tendered,
          quantity: quantity,
          subtotal: subtotal,
          discounts: discounts,
          tax: tax,
          netTotal: netTotal,
          change: change,
        }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async printSplitReceipt(
    tendered,
    cardTotal,
    quantity,
    subtotal,
    discounts,
    tax,
    netTotal,
    change
  ) {
    try {
      console.log("printing");
      let response = await fetch("http://localhost:6060/receipt/printsplit", {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tendered: tendered,
          cardTotal: cardTotal,
          quantity: quantity,
          subtotal: subtotal,
          discounts: discounts,
          tax: tax,
          netTotal: netTotal,
          change: change,
        }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async printCardReceipt(
    quantity,
    subtotal,
    discounts,
    tax,
    netTotal,
  ) {
    try {
      console.log("printing");
      let response = await fetch("http://localhost:6060/receipt/printcard", {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quantity: quantity,
          subtotal: subtotal,
          discounts: discounts,
          tax: tax,
          netTotal: netTotal,
        }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async reprintReceipt() {
    try {
      let response = await fetch("http://localhost:6060/receipt/reprint", {
        method: "POST",
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
