import React from "react";

const LegalTermsOfUse = () => {
  return (
    <section className="legalTermsOfUse">
     <h1>LegalTermsOfUse</h1>
    </section>
  );
};

export default LegalTermsOfUse;
