import React from "react";
import { Outlet, useNavigate } from "react-router";

const CrmPos = () => {
  const nav = useNavigate();
  return (
    <section className="crmPos">
      <h1>Point of Sale</h1>
      <div className="buttons">
        <button
          onClick={() => {
            nav("timeclock");
          }}
        >
          Time Clock
        </button>
        <button
          onClick={() => {
            nav("checkout");
          }}
        >
          Check Out
        </button>
        <button
          onClick={() => {
            nav("scheduling");
          }}
        >
          Service Scheduling
        </button>
        <button
          onClick={() => {
            nav("checkin");
          }}
        >
          Service Check In
        </button>
      </div>
    </section>
  );
};

export default CrmPos;
