import React, { useEffect, useState } from "react";
import UserBridge from "../../../bridges/UserBridge";
import { useNavigate } from "react-router";
import PageLoader from "../../parts/PageLoader";
import MicroLoader from "../../parts/MicroLoader";

const AccountsEmployees = () => {
  const [loaded, setLoaded] = useState(false);
  const [createClientLoader, setCreateClientLoader] = useState(false);
  const [resetClientLoader, setResetClientLoader] = useState(false);
  const [removeClientLoader, setRemoveClientLoader] = useState(false);
  const [updateClientLoader, setUpdateClientLoader] = useState(false);
  const [selectedClient, setSelectedClient] = useState(``);
  const [userList, setUserList] = useState(null);
  const [clientData, setClientData] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    (async () => {
      await dataRefresh();
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectedClient`).value = selectedClient;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const dataRefresh = async () => {
    const response = await UserBridge.readUserList();
    const result = await response.json();
    if (result.error === true) {
      nav("/login");
    } else {
      const { data } = result;
      setUserList(data);
    }
  };

  const readSelected = async (_id) => {
    const response = await UserBridge.readUser(_id);
    const result = await response.json();
    const { data } = result;
    setClientData(data);
  };

  const createClient = async () => {
    setCreateClientLoader(true);
    const response = await UserBridge.createUser(
      2,
      null,
      document.querySelector(`#fname`).value,
      document.querySelector(`#lname`).value,
      document.querySelector(`#email`).value,
      document.querySelector(`#phone`).value
    );
    const result = await response.json();
    if (result.error) {
      alert(`There was an error, please try again.`);
      setCreateClientLoader(false);
    } else {
      await dataRefresh();
      alert(`Success!`);
      setCreateClientLoader(false);
    }
  };

  const updateClient = async () => {
    setUpdateClientLoader(true);
    const updates = {
      fname: document.querySelector(`#newfname`).value,
      lname: document.querySelector(`#newlname`).value,
      email: document.querySelector(`#newemail`).value,
      phone: document.querySelector(`#newphone`).value,
    };
    console.log(updates);
    const response = await UserBridge.updateUser(selectedClient, updates);
    const result = await response.json();
    if (result.error) {
      setUpdateClientLoader(false);
      alert(`There was an error, please try again.`);
    } else {
      setUpdateClientLoader(false);
      alert(`Success!`);
      await dataRefresh();
      await readSelected(selectedClient);
    }
  };

  const removeClient = async () => {
    setRemoveClientLoader(true);
    const input = confirm(
      `Are you sure you want to delete this Employee? The account will be deleted along with all associated data and records.`
    );
    if (input === true) {
      const response = await UserBridge.deleteUser(selectedClient);
      const result = await response.json();
      if (result.error) {
        setRemoveClientLoader(false);
        alert(`There was an error, please try again.`);
      } else {
        await dataRefresh();
        setSelectedClient("");
        setClientData(null);
        setRemoveClientLoader(false);
        alert(`Success!`);
      }
    } else {
      setRemoveClientLoader(false);
      return;
    }
  };

  const resetPassword = async () => {
    setResetClientLoader(true);
    const response = await UserBridge.forceResetPassword(selectedClient);
    const result = await response.json();
    if (result.error) {
      setResetClientLoader(false);
      alert(`There was an error, please try again.`);
    } else {
      setResetClientLoader(false);
      alert(`Success!`);
    }
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  const populateClientSelectBox = () => {
    let payload = [];
    userList.forEach((client) => {
      if (client.authLevel == 2) {
        payload.push(genTag(client.fname, client.lname, client._id));
      }
    });
    return payload;
  };

  const fetchClient = async () => {
    setClientData(null);
    const client = document.querySelector(`#selectedClient`).value;
    await readSelected(client);
    setSelectedClient(client);
  };

  if (loaded) {
    return (
      <section className="accountsEmployees">
        <h1>Employee Account Manager</h1>
        <p>
          Create, view, update, and delete employee accounts using this module.
        </p>
        <h3>Create Employee</h3>
        <label htmlFor="fname">First Name</label>
        <input
          name="fname"
          id="fname"
          type="fname"
          placeholder="enter employee first name"
        />
        <label htmlFor="lname">Last Name</label>
        <input
          name="lname"
          id="lname"
          type="lname"
          placeholder="enter employee last name"
        />
        <label htmlFor="email">Email</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="enter employee email"
        />
        <label htmlFor="phone">Phone</label>
        <input
          name="phone"
          id="phone"
          type="phone"
          placeholder="enter employee phone"
        />
        <button onClick={createClient}>
          {createClientLoader ? <MicroLoader /> : `Create Employee`}
        </button>
        <h3>View & Edit Employee</h3>
        <select id="selectedClient" onChange={fetchClient}>
          <option value="">SELECT AN EMPLOYEE TO EDIT</option>
          {populateClientSelectBox()}
        </select>
        {clientData ? (
          <>
            <label htmlFor="newfname">First Name</label>
            <input
              name="newfname"
              id="newfname"
              type="fname"
              placeholder="enter employee first name"
              defaultValue={clientData.fname}
            />
            <label htmlFor="newlname">Last Name</label>
            <input
              name="newlname"
              id="newlname"
              type="lname"
              placeholder="enter employee last name"
              defaultValue={clientData.lname}
            />
            <label htmlFor="newemail">Email</label>
            <input
              name="newemail"
              id="newemail"
              type="email"
              placeholder="enter employee email"
              defaultValue={clientData.email}
            />
            <label htmlFor="newphone">Phone</label>
            <input
              name="phone"
              id="newphone"
              type="phone"
              placeholder="enter employee phone"
              defaultValue={clientData.phone}
            />
            <label htmlFor="newposPin">Point of Sale Pin (4 numbers)</label>
            <input
              type="string"
              id="newposPin"
              defaultValue={clientData.posPin}
              readOnly
            />
            <button onClick={updateClient}>
              {updateClientLoader ? <MicroLoader /> : `Update Employee`}
            </button>
            <button onClick={removeClient}>
              {removeClientLoader ? <MicroLoader /> : `Remove Employee`}
            </button>
            <button onClick={resetPassword}>
              {resetClientLoader ? (
                <MicroLoader />
              ) : (
                `Reset Password to "getfit"`
              )}
            </button>
          </>
        ) : null}
      </section>
    );
  } else return <PageLoader />;
};

export default AccountsEmployees;
