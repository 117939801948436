import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserBridge from "../../bridges/UserBridge";
import MicroLoader from "./MicroLoader";
import Loading from "./Loading";

const AccountHeader = () => {
  const [signOutLoader, setSignOutLoader] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const signOut = async () => {
    setSignOutLoader(true);
    const response = await UserBridge.logout();
    const result = await response.json();
    if (result.error) {
      setSignOutLoader(false);
      alert(`There was an error, please try again.`);
    } else {
      setSignOutLoader(false);
      window.location.href = "https://ffnaw.com";
    }
  };

  const toggleMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  return (
    <>
      <nav className="accountHeader">
        <div className="desktop">
          <div className="left">
            <a href="https://ffnaw.com">
              <img src="../../assets/watermark-alpha.png" />
            </a>
          </div>

          <div className="right">
            <button onClick={signOut}>
              {signOutLoader ? <MicroLoader /> : `Log Out`}
            </button>
          </div>
        </div>
        <div className="mobile">
          <div className="left">
            <a href="https://ffnaw.com">
              <img src="../../assets/watermark-alpha.png" />
            </a>
          </div>
          <div className="right">
            <button onClick={toggleMenu}>Menu</button>
          </div>
        </div>
      </nav>
      <div
        id="menu"
        className={
          menuOpen ? `mobileSlideOut menuOpen` : `mobileSlideOut menuClosed`
        }
        onClick={toggleMenu}
      >
        <label>Account</label>
        <Link to="/v1/account/">Home</Link>
        <Link to="/v1/account/orders">Orders</Link>
        <Link to="/v1/account/services">Services</Link>
        <Link to="/v1/account/info">Account Info</Link>
        <Link to="/store">Shop Now</Link>
        <button onClick={signOut}>Log Out</button>
      </div>
    </>
  );
};

export default AccountHeader;
