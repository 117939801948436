export default class CashDrawerBridge {
  static async startDrawer(total) {
    try {
      let response = await fetch("/api/cashdrawer/start", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ total: total }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async endDrawer(total) {
    try {
      let response = await fetch("/api/cashdrawer/end", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ total: total }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async query() {
    try {
      let response = await fetch("/api/cashdrawer/query", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async startCash(scannedItems, tendered, discountCode, cashBalance, paymentId) {
    try {
      let response = await fetch("/api/cashdrawer/startcash", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tendered: tendered,
          scannedItems: scannedItems,
          discountCode: discountCode,
          cashBalance: cashBalance,
          paymentId: paymentId
        }),
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
