import React, { useEffect, useState } from "react";
import ProductBridge from "../../bridges/ProductBridge";
import MicroLoader from "../parts/MicroLoader";
import CartController from "../../controllers/CartController";
import PageLoader from "../parts/PageLoader";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import OrderBridge from "../../bridges/OrderBridge";
import TransactionBridge from "../../bridges/TransactionBridge";

const StoreSuccess = () => {
  const [loaded, setLoaded] = useState(true);

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    completeCheckout();
  }, []);

  const completeCheckout = async () => {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get("o");
    const transactionId = params.get("t");
    if (orderId === null) {
      const response = await TransactionBridge.completeTransaction(
        transactionId
      );
      const result = await response.json();
    } else {
      const response = await OrderBridge.completeCheckout(orderId);
      const result = await response.json();
    }
  };

  if (loaded) {
    return (
      <section className="storeSuccess">
        <h1>Thank you for your purchase!</h1>
        <p>
          You may now view your order in{" "}
          <Link to="/v1/account">My Account</Link> /{" "}
          <Link to="/v1/account/orders">Order History</Link>. If you purchased a
          service ticket, you may schedule your service in{" "}
          <Link to="/v1/account">My Account</Link> /{" "}
          <Link to="/v1/account/services">Services</Link>.
        </p>
      </section>
    );
  } else return <PageLoader />;
};

export default StoreSuccess;
