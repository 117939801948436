import React, { useEffect, useState } from "react";
import ServiceBridge from "../../bridges/ServiceBridge";
import UserBridge from "../../bridges/UserBridge";
import PageLoader from "../parts/PageLoader";
import MicroLoader from "../parts/MicroLoader";
import TransactionBridge from "../../bridges/TransactionBridge";

const AccountServices = () => {
  const [loaded, setLoaded] = useState(false);
  const [ticketsLoader, setTicketsLoader] = useState(false);
  const [subscriptionsLoader, setSubscriptionsLoader] = useState(false);
  const [redTicketLoader, setRedTicketLoader] = useState(false);
  const [hyperTicketLoader, setHyperTicketLoader] = useState(false);
  const [purchasePackageLoader, setPurchasePackageLoader] = useState(false);
  const [purchaseBronzeLoader, setPurchaseBronzeLoader] = useState(false);
  const [purchaseGoldLoader, setPurchaseGoldLoader] = useState(false);
  const [purchasePlatinumLoader, setPurchasePlatinumLoader] = useState(false);
  const [purchaseBronzeHyperLoader, setPurchaseBronzeHyperLoader] =
    useState(false);
  const [purchaseBronzeRedLoader, setPurchaseBronzeRedLoader] = useState(false);
  const [purchaseUnlimitedLoader, setPurchaseUnlimitedLoader] = useState(false);
  const [purchaseUnlimitedHyperLoader, setPurchaseUnlimitedHyperLoader] =
    useState(false);
  const [purchaseUnlimitedRedLoader, setPurchaseUnlimitedRedLoader] =
    useState(false);
  const [alviraTicketLoader, setAlviraTicketLoader] = useState(false);
  const [alvira2TicketLoader, setAlvira2TicketLoader] = useState(false);
  const [hydrateMeLoader, setHydrateMeLoader] = useState(false);
  const [performanceTicketLoader, setPerformanceTicketLoader] = useState(false);
  const [quinchTicketLoader, setQuinchTicketLoader] = useState(false);
  const [myersTicketLoader, setMyersTicketLoader] = useState(false);
  const [hangoverTicketLoader, setHangoverTicketLoader] = useState(false);
  const [serviceTickets, setServiceTickets] = useState([]);
  const [services, setServices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getServices();
    await getServiceTickets();
    await getSubs();
    setLoaded(true);
  };

  const getServices = async () => {
    const response = await ServiceBridge.getServices();
    const result = await response.json();
    setServices(result.data);
  };

  const getServiceTickets = async () => {
    setTicketsLoader(true);
    const response = await UserBridge.readUserServices();
    const result = await response.json();
    console.log(result.data);
    setServiceTickets(result.data);
    setTicketsLoader(false);
  };

  const sendToBook = async (_id) => {
    let match;
    for (let i = 0; i < services.length; i++) {
      if (services[i]._id == _id) {
        match = i;
      }
    }
    const response = await ServiceBridge.getBookingLink(_id);
    const result = await response.json();
    const url = await result.data;
    const response2 = await UserBridge.removeServiceTickets(null, _id, 1);
    const result2 = await response2.json();
    getServiceTickets();
    window.open(url, "_blank");
  };

  const renderServiceTickets = () => {
    let payload = [];
    serviceTickets.forEach((ticket) => {
      payload.push(
        <tr>
          <td>{ticket.serviceName}</td>
          <td>{ticket.quantity}</td>
          <button
            onClick={() => {
              sendToBook(ticket.serviceId);
            }}
          >
            Book Now
          </button>
        </tr>
      );
    });
    return payload;
  };

  const purchaseBronze = async () => {
    setPurchaseBronzeLoader(true);
    const response = await TransactionBridge.purchaseBronze();
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseBronzeLoader(true);
  };

  const purchaseGold = async () => {
    setPurchaseGoldLoader(true);
    const response = await TransactionBridge.purchaseGold();
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseGoldLoader(true);
  };

  const purchasePlatinum = async () => {
    setPurchasePlatinumLoader(true);
    const response = await TransactionBridge.purchasePlatinum();
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchasePlatinumLoader(true);
  };

  const purchaseBronzeHyper = async (_id) => {
    setPurchaseBronzeHyperLoader(true);
    const response = await TransactionBridge.purchaseBronzeHyper(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseBronzeHyperLoader(true);
  };

  const purchaseBronzeRed = async (_id) => {
    setPurchaseBronzeRedLoader(true);
    const response = await TransactionBridge.purchaseBronzeRed(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseBronzeRedLoader(true);
  };

  const purchaseUnlimited = async (_id) => {
    setPurchaseUnlimitedLoader(true);
    const response = await TransactionBridge.purchaseUnlimited(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseUnlimitedLoader(true);
  };

  const purchaseUnlimitedHyper = async (_id) => {
    setPurchaseUnlimitedLoader(true);
    const response = await TransactionBridge.purchaseUnlimitedHyper(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseUnlimitedHyperLoader(true);
  };

  const purchaseUnlimitedRed = async (_id) => {
    setPurchaseUnlimitedRedLoader(true);
    const response = await TransactionBridge.purchaseUnlimitedRed(_id);
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchaseUnlimitedRedLoader(true);
  };

  const purchasePackage = async () => {
    setPurchasePackageLoader(true);
    const response = await TransactionBridge.purchasePackage(
      document.getElementById("packageQuantity").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPurchasePackageLoader(true);
  };

  const purchaseRedTickets = async () => {
    setRedTicketLoader(true);
    const response = await TransactionBridge.purchaseRedTickets(
      document.getElementById("quantityRed").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setRedTicketLoader(false);
  };

  const purchaseHyperTickets = async () => {
    setHyperTicketLoader(true);
    const response = await TransactionBridge.purchaseHyperTickets(
      document.getElementById("quantityHyper").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setHyperTicketLoader(false);
  };

  const purchaseAlviraTickets = async () => {
    setAlviraTicketLoader(true);
    const response = await TransactionBridge.purchaseAlvira(
      document.getElementById("quantityAlvira").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setAlviraTicketLoader(false);
  };

  const purchaseAlvira2Tickets = async () => {
    setAlvira2TicketLoader(true);
    const response = await TransactionBridge.purchaseAlvira2(
      document.getElementById("quantityAlvira2").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setAlvira2TicketLoader(false);
  };

  const purchaseHydrateMe = async () => {
    setHydrateMeLoader(true);
    const response = await TransactionBridge.purchaseHydrateMe(
      document.getElementById("quantityHydrateMe").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setHydrateMeLoader(false);
  };

  const purchasePerformance = async () => {
    setPerformanceTicketLoader(true);
    const response = await TransactionBridge.purchasePerformance(
      document.getElementById("quantityPerformance").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setPerformanceTicketLoader(false);
  };

  const purchaseQuinch = async () => {
    setQuinchTicketLoader(true);
    const response = await TransactionBridge.purchaseQuinch(
      document.getElementById("quantityQuinch").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setQuinchTicketLoader(false);
  };

  const purchaseMyers = async () => {
    setMyersTicketLoader(true);
    const response = await TransactionBridge.purchaseMyers(
      document.getElementById("quantityMyers").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setMyersTicketLoader(false);
  };

  const purchaseHangoverTickets = async () => {
    setHangoverTicketLoader(true);
    const response = await TransactionBridge.purchaseHangover(
      document.getElementById("quantityHangover").value
    );
    const result = await response.json();
    if (result.checkoutUrl != null) {
      window.location.href = result.checkoutUrl;
    } else {
      alert(result.msg);
    }
    setHangoverTicketLoader(false);
  };

  const getSubs = async () => {
    setSubscriptionsLoader(true);
    const response = await TransactionBridge.getCurrentSubscriptions();
    const result = await response.json();
    console.log(result.data);
    setSubscriptions(result.data);
    setSubscriptionsLoader(false);
  };

  // const cancelSubscription = async (_id) => {
  //   setSubscriptionsLoader(true);
  //   const proceed = confirm(
  //     "Are you sure you want to cancel your subscription? If an order has already been created, it will still be shipped, but all future orders will be canceled."
  //   );
  //   if (proceed) {
  //     const response = await TransactionBridge.cancelSubscription(_id);
  //     const result = await response.json();
  //     alert(result.msg);
  //   }
  //   await getSubs()
  //   setSubscriptionsLoader(false);
  // };

  if (loaded) {
    return (
      <section className="accountServices">
        <h1>Services</h1>
        <p>
          Here you may see your available service tickets, book appointments,
          and purchase more.
        </p>
        <p>
          A booking ticket is required to schedule services with Flex Factory.
          When you click book now, make sure to finish the booking process in
          one go.
        </p>
        {/* <h3>Your Active Service Subscriptions</h3>
        {subscriptionsLoader ? <MicroLoader /> : renderSubscriptions()} */}
        <h3>Your Service Tickets</h3>
        <table>
          <tr>
            <th>Service Name</th>
            <th>Available Tickets</th>
          </tr>
          {renderServiceTickets()}
        </table>

        <h3>Available Service Subscriptions</h3>
        <div className="subscriptions">
          <div className="subscription">
            <h3>Bronze</h3>
            <p>3 Month Commitment</p>
            <p>One Hyperbaric Session Monthly</p>
            <p>One Red Light Vibro Therapy Table Session Monthly</p>
            <strong>
              <span className="price">$99.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseBronze(selectedUser);
              }}
            >
              {purchaseBronzeLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Bronze Hyperbaric</h3>
            <p>3 Month Commitment</p>
            <p>Two Hyperbaric Chamber Sessions Monthly</p>
            <strong>
              <span className="price">$99.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseBronzeHyper(selectedUser);
              }}
            >
              {purchaseBronzeHyperLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Bronze Red Light</h3>
            <p>3 Month Commitment</p>
            <p>Two Red Light Chamber Sessions Monthly</p>
            <strong>
              <span className="price">$99.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseBronzeRed(selectedUser);
              }}
            >
              {purchaseBronzeRedLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Platinum</h3>
            <p>3 Month Commitment</p>
            <p>Four Hyperbaric Sessions Monthly</p>
            <p>Four Red Light Vibro Therapy Table Sessions Monthly</p>
            <p>10% off Supplements</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$379.99</span>
            </strong>
            <button
              onClick={() => {
                purchasePlatinum(selectedUser);
              }}
            >
              {purchasePlatinumLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Gold</h3>
            <p>3 Month Commitment</p>
            <p>Two Hyperbaric Sessions Monthly</p>
            <p>Two Red Light Vibro Therapy Table Sessions Monthly</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$199.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseGold(selectedUser);
              }}
            >
              {purchaseGoldLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Unlimited</h3>
            <p>3 Month Commitment</p>
            <p>Unlimited Hyperbaric Chamber and Red Light Therapy</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$999.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseUnlimited(selectedUser);
              }}
            >
              {purchaseUnlimitedLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Unlimited Red Light Therapy</h3>
            <p>3 Month Commitment</p>
            <p>Unlimited Red Light Therapy</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$499.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseUnlimitedRed(selectedUser);
              }}
            >
              {purchaseUnlimitedRedLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Unlimited Hyperbaric Chamber</h3>
            <p>3 Month Commitment</p>
            <p>Unlimited Hyperbaric Chamber</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$499.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseUnlimitedHyper(selectedUser);
              }}
            >
              {purchaseUnlimitedHyperLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>

          <div className="subscription">
            <h3>Unlimited Hyperbaric Chamber</h3>
            <p>3 Month Commitment</p>
            <p>Unlimited Hyperbaric Chamber</p>
            <p>Free Body Fat Testing</p>
            <strong>
              <span className="price">$499.99</span>
            </strong>
            <button
              onClick={() => {
                purchaseUnlimitedHyper(selectedUser);
              }}
            >
              {purchaseUnlimitedHyperLoader ? <MicroLoader /> : "Subscribe"}
            </button>
          </div>
        </div>

        <h3>Available Service Packages</h3>
        <div className="availablePackages">
          <div className="package">
            <h3>Hyperbaric Chamber and Red Light Vibro Therapy Table</h3>
            <p>
              Save money by purchasing our package deals. The more you buy, the
              more you save! This package includes both the red light vibro
              therapy table and the hyperbaric chamber.
            </p>
            <strong>
              1 ticket each
              <span className="price">$104.99</span>
            </strong>
            <strong>
              3 tickets each
              <span className="price">$299.99</span>
            </strong>
            <strong>
              5 tickets each
              <span className="price">$479.99</span>
            </strong>
            <strong>
              12 tickets each
              <span className="price">$999.99</span>
            </strong>
            <label htmlFor="packageQuantity">Quantity</label>
            <select
              id="packageQuantity"
              name="packageQuantity"
              placeHolder="How many tickets?"
            >
              <option value={1}>1</option>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={12}>12</option>
            </select>
            <button onClick={purchasePackage}>
              {purchasePackageLoader ? <MicroLoader /> : "Purchase Package"}
            </button>
          </div>
        </div>

        <h3>Services A La Carte</h3>
        <div className="availableServices">
          <div className="service">
            <strong>Elevated Aesthetics Med Spa</strong>
            <p>Session Length: Variable</p>
            <p>
              Elevated Aesthetics MedSpa (Located inside Flex Factory Nutrition
              & Wellness in Fresno, CA.), where timeless beauty meets modern
              rejuvenation. Our MedSpa is a haven of sophistication and
              tranquility, dedicated to enhancing your natural radiance and
              restoring a youthful glow. With a commitment to excellence and
              personalized care, we are experienced medical professionals here
              to guide you on your journey to elevated aesthetics.
            </p>
            <span className="price">PRICE VARIES</span>
            <label htmlFor="quantityRed">Quantity</label>
            <a
              href="https://www.elevatedaestheticsmedspa.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <button>Book Now with Kelly</button>
            </a>
          </div>
          <div className="service">
            <strong>Red Light Vibro Therapy Table</strong>
            <p>Session Length: 30 minutes</p>
            <p>
              Red light therapy is a service utilized in active recovery and
              sports medicine that involves the use of low-level red wavelengths
              of light to treat various health conditions. This therapy is
              designed to promote healing, reduce inflammation, and alleviate
              pain by penetrating the skin and stimulating cellular function. It
              is commonly used by athletes to accelerate muscle recovery and
              enhance overall performance.
            </p>

            <span className="price">$59.99</span>
            <label htmlFor="quantityRed">Quantity</label>
            <input
              type="number"
              id="quantityRed"
              name="quantityRed"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseRedTickets();
              }}
            >
              {redTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Hyperbaric Chamber</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              Hyperbaric chambers are used in fitness and active recovery to
              enhance the body's natural healing processes. By providing 100%
              oxygen at higher-than-atmospheric pressures, these chambers
              increase oxygen delivery to tissues, promoting faster muscle
              recovery, reducing inflammation, and accelerating the healing of
              injuries. Athletes often use hyperbaric therapy to improve
              performance and expedite recovery times.
            </p>
            <span className="price">$59.99</span>
            <label htmlFor="quantityHyper">Quantity</label>
            <input
              type="number"
              id="quantityHyper"
              name="quantityHyper"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseHyperTickets();
              }}
            >
              {hyperTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>

          <div className="service">
            <strong>Tamu - Hydrate Me! IV Injection</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              One liter of Lactated Ringers. Offers sodium, potassium, chloride,
              and lactate often lost in high intensity activity. Or, Normal
              Saline- One liter of normal saline. Offers plain and simple
              hydration.
            </p>
            <span className="price">$185</span>
            <label htmlFor="quantityHydrateMe">Quantity</label>
            <input
              type="number"
              id="quantityHydrateMe"
              name="quantityHydrateMe"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseHydrateMe();
              }}
            >
              {hydrateMeLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Recovery and Performance IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              Most popular IV! Helps decrease recovery time, enhances your
              athletic performance, replenishes essential nutrients, and reduces
              inflammation. Offers Vitamin C, Vita Complex with B-complex, Amino
              Blend (decreases muscle loss, burns fat more quickly, and
              increases metabolism), and mineral blend (removes toxins,
              replenishes vitamins, and help reverse effects of dehydration).
              All in a liter of Lactated Ringers.
            </p>
            <span className="price">$230</span>
            <label htmlFor="quantityPerformance">Quantity</label>
            <input
              type="number"
              id="quantityPerformance"
              name="quantityPerformance"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchasePerformance();
              }}
            >
              {performanceTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Quinch Me IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              Rehydrates your body and combats fatigue caused by dehydration.
              Detoxifies, reduces inflammation, rehydrates, improves
              circulation, and fights fatigue. Offers Vitamin C (an antioxidant
              to protect cells, boost immunity, and create collagen), Vita
              Complex with B-complex (helps keep cells and skin healthy and
              convert nutrients to energy), Mineral blend (helps reverse effects
              of dehydration, remove toxins, and replenish vitamins). All in a
              liter of Lactated Ringer.
            </p>
            <span className="price">$215</span>
            <label htmlFor="quantityQuinch">Quantity</label>
            <input
              type="number"
              id="quantityQuinch"
              name="quantityQuinch"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseQuinch();
              }}
            >
              {quinchTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Myers Cocktail IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              The famous Myer’s Cocktail designed by Dr. John Myer who was at
              the forefront of IV vitamin infusions. This combination of
              vitamins and minerals helps alleviate stress, improves immunity,
              restores balance, provides hydration, helps reduce migraines, and
              reduces chronic pain. Offers Magnesium Chloride (reduces fatigue,
              improves immunity, decreases risk of developing migraines,
              improves relaxation, and more), B-complex ( may help keep skin and
              blood cells healthy, converts nutrients to energy), Hydroxo B12
              (Promotes healthy brain function, blood cells and nerves, Calcium
              Gluconate (promotes healthy bones, and normal function of muscles,
              nerves, and cells), Vitamin C (may protect cells from damage,
              absorb iron, create collagen, boost immunity). All in one liter of
              Lactated Ringer
            </p>
            <span className="price">$265</span>
            <label htmlFor="quantityMyers">Quantity</label>
            <input
              type="number"
              id="quantityMyers"
              name="quantityMyers"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseMyers();
              }}
            >
              {myersTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Tamu - Hangover Fix IV</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              How was your weekend? Barely remember it, nauseated, headache,
              tired, and feel miserable? We are here to your rescue!!! With a
              hangover IV. We will hydrate you with a liter of Lactated Ringer
              filled with Vitamin C, Vita Complex with B-complex, and Mineral
              blend, and Ondansetron (antiemetic) as needed for nausea, and
              Ketorolac (NSAID pain reliever) as needed for headaches and body
              aches.
            </p>
            <span className="price">$230</span>
            <label htmlFor="quantityHangover">Quantity</label>
            <input
              type="number"
              id="quantityHangover"
              name="quantityHangover"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseHangoverTickets();
              }}
            >
              {hangoverTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Elvira - 60 Minute Custom Massage</strong>
            <p>Session Length: 60 minutes</p>
            <p>
              A 60 minute customized massage provided by our massage specialist
              Elvira
            </p>
            <span className="price">$135</span>
            <label htmlFor="quantityAlvira">Quantity</label>
            <input
              type="number"
              id="quantityAlvira"
              name="quantityAlvira"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseAlviraTickets();
              }}
            >
              {alviraTicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
          <div className="service">
            <strong>Elvira - 90 Minute Custom Massage</strong>
            <p>Session Length: 90 minutes</p>
            <p>
              A 90 minute customized massage provided by our massage specialist
              Elvira
            </p>
            <span className="price">$195</span>
            <label htmlFor="quantityAlvira2">Quantity</label>
            <input
              type="number"
              id="quantityAlvira2"
              name="quantityAlvira2"
              placeholder="How Many Tickets?"
            />
            <button
              onClick={() => {
                purchaseAlvira2Tickets();
              }}
            >
              {alvira2TicketLoader ? <MicroLoader /> : "Purchase Tickets"}
            </button>
          </div>
        </div>
      </section>
    );
  } else return <PageLoader />;
};

export default AccountServices;
