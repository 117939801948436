import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MicroLoader from "../../parts/MicroLoader";
import TimesheetBridge from "../../../bridges/TimesheetBridge";
import PageLoader from "../../parts/PageLoader";

const PosTimeClock = () => {
  const [loaded, setLoaded] = useState(false);
  const [clockInLoader, setClockInLoader] = useState(false);
  const [clockOutLoader, setClockOutLoader] = useState(false);
  const [breakInLoader, setBreakInLoader] = useState(false);
  const [breakOutLoader, setBreakOutLoader] = useState(false);
  const [timesheetLoader, setTimesheetLoader] = useState(true);
  const [timesheet, setTimesheet] = useState([]);
  const [systemTime, setSystemTime] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
    const timer = setInterval(() => {
      setSystemTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const pageLoad = async () => {
    await getTimesheet();
    setLoaded(true);
  };

  const getTimesheet = async () => {
    setTimesheetLoader(true);
    const response = await TimesheetBridge.getday();
    const result = await response.json();
    setTimesheet(result.data);
    setTimesheetLoader(false);
  };

  const clockIn = async () => {
    setClockInLoader(true);
    const response = await TimesheetBridge.clockIn(
      document.querySelector("#employeePin").value
    );
    const result = await response.json();
    alert(result.msg);
    getTimesheet();
    setClockInLoader(false);
  };

  const clockOut = async () => {
    setClockOutLoader(true);
    const response = await TimesheetBridge.clockOut(
      document.querySelector("#employeePin").value
    );
    const result = await response.json();
    alert(result.msg);
    getTimesheet();
    setClockOutLoader(false);
  };

  const breakOut = async () => {
    setBreakOutLoader(true);
    const response = await TimesheetBridge.breakOut(
      document.querySelector("#employeePin").value
    );
    const result = await response.json();
    alert(result.msg);
    getTimesheet();
    setBreakOutLoader(false);
  };

  const breakIn = async () => {
    setBreakInLoader(true);
    const response = await TimesheetBridge.breakIn(
      document.querySelector("#employeePin").value
    );
    const result = await response.json();
    alert(result.msg);
    getTimesheet();
    setBreakInLoader(false);
  };

  const renderTimesheet = () => {
    const payload = [];
    payload.push();
    timesheet.forEach((sheet) => {
      payload.push(
        <tr>
          <td>{sheet.fname}</td>
          <td>{sheet.lname}</td>
          <td>{sheet.timeIn}</td>
          <td>{sheet.breakOut}</td>
          <td>{sheet.breakIn}</td>
          <td>{sheet.timeOut}</td>
        </tr>
      );
    });
    return payload;
  };
  if (loaded) {
    return (
      <div className="posTimeClock">
        <h1>Time Clock</h1>
        <strong
          className="breadcrumb"
          onClick={() => {
            nav("/v1/crm/pos");
          }}
        >
          &larr; Return to POS
        </strong>
        <div className="systemTime">
          <strong>System Time: {systemTime}</strong>
        </div>
        <div className="input">
          <label for="employeePin">Enter your Employee Pin:</label>
          <input
            type="text"
            id="employeePin"
            name="employeePin"
            autoComplete="off"
          />
        </div>
        <div className="buttons">
          <button onClick={clockIn}>
            {clockInLoader ? <MicroLoader /> : "Clock In"}
          </button>
          <button onClick={clockOut}>
            {clockOutLoader ? <MicroLoader /> : "Clock Out"}
          </button>
          <button onClick={breakOut}>
            {breakOutLoader ? <MicroLoader /> : "Break Out"}
          </button>
          <button onClick={breakIn}>
            {breakInLoader ? <MicroLoader /> : "Break In"}
          </button>
        </div>
        <p>Note: You do not need to break out and break in for paid breaks, only unpaid lunches.</p>
        <h2>Current Users</h2>
        {timesheetLoader ? (
          <MicroLoader />
        ) : (
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Time In</th>
                <th>Break Out</th>
                <th>Break In</th>
                <th>Time Out</th>
              </tr>
            </thead>
            <tbody>{renderTimesheet()}</tbody>
          </table>
        )}
      </div>
    );
  } else return <PageLoader />;
};

export default PosTimeClock;
