import React from "react";
import { Outlet } from "react-router";

const LegalView = () => {
  return (
    <section className="legalView">
     <h1>LegalView</h1>
     <Outlet />
    </section>
  );
};

export default LegalView;
